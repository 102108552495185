export const tsigkeyDataSchema = [
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "scope",
        is_id_field: false,
        field_type: "string", 
        label: "scopeFormFieldLabel"
    },
    {
        field_key: "resource_id",
        is_id_field: false,
        field_type: "string", 
        label: "resourceFormFieldLabel"
    },
    {
        field_key: "algorithm",
        is_id_field: false,
        field_type: "string", 
        label: "algorithmFormFieldLabel"
    },
    {
        field_key: "secret",
        is_id_field: false,
        field_type: "string", 
        label: "secretFormFieldLabel"
    },
    {
        field_key: "created_at",
        is_id_field: false,
        field_type: "datetime", 
        label: "createTimeHeaderLabel"
    },
    {
        field_key: "updated_at",
        is_id_field: false,
        field_type: "datetime", 
        label: "updatedAtFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const tsigkeyDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "algorithm",
        field_type: "select", 
        required: true, 
        label: "algorithmFormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: true,
        empty: false,
        items: [
            {
                keyword: "HMAC-MD5",
                value: "hmac-md5",
                default: true
            },
            {
                keyword: "HMAC-SHA1",
                value: "hmac-sha1",
                default: false
            },
            {
                keyword: "HMAC-SHA224",
                value: "hmac-sha224",
                default: false
            },
            {
                keyword: "HMAC-SHA256",
                value: "hmac-sha256",
                default: false
            },
            {
                keyword: "HMAC-SHA384",
                value: "hmac-sha384",
                default: false
            },
            {
                keyword: "HMAC-SHA512",
                value: "hmac-sha512",
                default: false
            }
        ]
    },
    {
        field_key: "scope",
        field_type: "select", 
        required: true, 
        label: "scopeFormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: true,
        empty: false,
        items: [
            {
                keyword: "Zone",
                value: "ZONE",
                default: false
            },
            {
                keyword: "Pool",
                value: "POOL",
                default: false
            }
        ]
    },
    {
        field_key: "resource_id",
        field_type: "select", 
        required: true, 
        label: "resourceormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: true,
        empty: false,
        items: []
    },
    {
        field_key: "secret",
        field_type: "string", 
        required: true, 
        label: "secretFormFieldLabel", 
        error_label: "requiredFormFieldError"
    }
]

export const tsigkeysFilterMenu = [
    {
        keyword: "nameFormFieldLabel", 
        value: "name", 
        type: "text"
    },
    {
        keyword: "algorithmFormFieldLabel", 
        value: "algorithm", 
        type: "select",
        self_item_titles: true,
        items: [
            {
                keyword: "HMAC-MD5",
                value: "hmac-md5",
                default: true
            },
            {
                keyword: "HMAC-SHA1",
                value: "hmac-sha1",
                default: false
            },
            {
                keyword: "HMAC-SHA224",
                value: "hmac-sha224",
                default: false
            },
            {
                keyword: "HMAC-SHA256",
                value: "hmac-sha256",
                default: false
            },
            {
                keyword: "HMAC-SHA384",
                value: "hmac-sha384",
                default: false
            },
            {
                keyword: "HMAC-SHA512",
                value: "hmac-sha512",
                default: false
            }
        ]
    },
    {
        keyword: "scopeFormFieldLabel", 
        value: "scope", 
        type: "select",
        self_item_titles: true,
        items: [
            {
                keyword: "Zone",
                value: "ZONE",
                default: false
            },
            {
                keyword: "Pool",
                value: "POOL",
                default: false
            }
        ]
    }
]

export const tsigkeysSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    }
]