import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import PlusButton from '../../../../_common/PlusButton';
import MinusButton from '../../../../_common/MinusButton';
import { Stack } from '@mui/material';
import NoDataNote from '../../../../_common/NoDataNote';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import ZoneSpecsV2 from './zoneSpecsV2';
import ZoneAttributesV2 from './zoneAttributesV2';
import ZoneNameserversV2 from './zoneNameserversV2';
import ZoneRecordsetV2 from './zoneRecordsetV2';
import ZoneExportsV2 from './zoneExportsV2';
import useWindowDimensions from 
'../../../../_common/WindowDimensions';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import Constants from '../../../../../config/constants';
import { Grid }  from '@mui/material';
import { 
    dnsDesignateRequest, 
    getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { zonesUrl as zoneUrlResponses } 
from '../../../../../_api_responses/openstack/dns/zones/v2';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { 
    dnsDesignateConstants
} from '../../../../../config/openStackConstants';
import ServiceContentHeader from 
'../../../../_common/ServiceContentHeader';
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { 
    zoneUpdateForm,
    zoneRecordsetForm,
    zoneRecordForm
} from '../../../../../_data/openstack/dns/zones/v2';
import CustomText from '../../../../_common/CustomText';

const SERVICE_NAME = openStackServices.dnsService
const FOOTER_HEIGHT = Constants.actions_bar_height + 20

const ZoneDetailV2 = (props) => {
    const [isCardLoading, setIsCardLoading] = useState(true)
    const [error, setError] = useState();
    const { selectedRow, handleDataFetch, handleZoneExport } = props
    const { widthWeight } = props
    const { handleDelete } = props
    const { projects } = props
    const { width } = useWindowDimensions();
    const FOOTER_WIDTH =  width - (width * widthWeight)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [fetchDataRequired, setFetchDataRequired] = useState(true);
    const [fetchExportDataRequired, setFetchExportDataRequired] = useState(true);
    
    const [zoneNameservers, setZoneNameservers] = useState([]);
    const [zoneRecordset, setZoneRecordset] = useState([]);

    const [zoneActions, setZoneActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");

    const [zoneUpdateData, setZoneUpdateData] = useState({})
    const [updateZoneDialogOpen, setUpdateZoneDialogOpen] = useState(false)
    const [zoneUpdateDataForm, setZoneUpdateDataForm] = useState([...zoneUpdateForm])
    const [createRecordsetDialogOpen, setCreateRecordsetDialogOpen] = useState(false)
    const [zoneRecordsetData, setZoneRecordsetData] = useState({})
    const [selectedRecordsList, setSelectedRecordsList] = useState([{record: ""}])

    const [zoneRecordsetDataOptions, setZoneRecordsetDataOptions] = useState({})
    const [zoneExports, setZoneExports] = useState([])

    const [zoneSubMenu, setZoneSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/zone-details", is_active: true},
        {keyword: "submenuZoneAttributes", navigation: "/zone-attributes", is_active: false},
        {keyword: "submenuZoneNameservers", navigation: "/zone-nameservers", is_active: false},
        {keyword: "submenuZoneRecordset", navigation: "/zone-recordset", is_active: false},
        {keyword: "submenuZoneExports", navigation: "/zone-exports", is_active: false}
    ])

    const [currentTab, setCurrentTab] = useState("/zone-details")
    
    const dnsServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const dnsServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const zonesUrl = useSelector(
        state => state.dnsDesignate.dnsDesignateApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === dnsDesignateConstants.zonesUrl)[0].url)
   

    const common_url = `${dnsServiceDomain}/${dnsServiceVersion}/${zonesUrl}/${selectedRow.id}`

    const handleConfirmDeleteDialogOpen = () => {
        handleDelete([selectedRow.id])
    }

    const handleRecordsetFetch = () => {
        setFetchDataRequired(true)
    }

    const handleExportsFetch = () => {
        setFetchExportDataRequired(true)
    }

    const getFormattedZoneData = useCallback((data) => {
        const project = projects.filter(p => p.id === data.project_id)[0]
    
        let formatted_data = {...data}
        formatted_data.project_id = project ? 
        project.name : 
        data.project_id
        return formatted_data
    },[projects])


    const handleZoneDetailTabChange = useCallback((navigation) => {
        let newZoneSubmenuData = zoneSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setZoneSubMenu(newZoneSubmenuData)
        setCurrentTab(navigation)
    },[
        zoneSubMenu
    ])

    const handleZoneRecordEdit = (event, field_key, index) => {
        let new_form_data = [...selectedRecordsList]
        new_form_data[index][field_key] = event.target.value.trim()
        setSelectedRecordsList(new_form_data)
    }

    const handleZoneRecordAdd = () => {
        let new_form_data = {}
        new_form_data["record"] = ""
        const updated_data = [...selectedRecordsList]
        updated_data.push(new_form_data)
        setSelectedRecordsList(updated_data)
    }

    const handleZoneRecordRemove = () => {
        let new_data = [...selectedRecordsList]
        new_data.pop()
        setSelectedRecordsList(new_data)
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const getDataForm = (form,form_options,data,onDataChange) => {
        return (
            <FormGroup>
                {form.map(field => {
                    let new_field = {...field}
                    delete new_field.label
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {
                                ...form_options[field.field_key],
                                ...new_field,
                                item_titles: defaultTexts
                            }
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleZoneUpdateDataChange = (event,field_key) => {
        let new_form_data = {...zoneUpdateData}
        if (zoneUpdateDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else {
            new_form_data[field_key] = event.target.value
        }
        setZoneUpdateData(new_form_data)
    }

    const handleUpdateZoneDialogOpen = () => {
        setUpdateZoneDialogOpen(true)
    }

    const handleUpdateZoneDialogClose = () => {
        setZoneUpdateData({})
        setUpdateZoneDialogOpen(false)
    }

    const onZoneUpdate = async () => {
        let updated_data = {...zoneUpdateData}
        if (selectedRow.type === "SECONDARY") {
            delete updated_data.email
            delete updated_data.ttl
        }
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "PATCH"
            const zone_response = await dnsDesignateRequest({
                url: common_url, 
                method: method, 
                data: updated_data,
                token: project_token
            })
            if (zone_response.status_code === zoneUrlResponses.patch.success_response.status_code) {
                setCurrentAction("")
                handleUpdateZoneDialogClose()
                handleDataFetch()
            } else {
                setError(zone_response.error)
            }
        }
    }

    const handleZoneRecordsetUpdateDataChange = (event,field_key) => {
        let new_options = {...zoneRecordsetDataOptions}
        delete new_options[field_key]
        setZoneRecordsetDataOptions(new_options)
        let new_form_data = {...zoneRecordsetData}
        if (zoneRecordsetForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (zoneRecordsetForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setZoneRecordsetData(new_form_data)
    }

    const handleCreateRecordsetDialogOpen = () => {
        setCreateRecordsetDialogOpen(true)
    }

    const handleCreateRecordsetDialogClose = () => {
        setZoneRecordsetData({})
        setSelectedRecordsList([{record: ""}])
        setCreateRecordsetDialogOpen(false)
    }

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...zoneRecordsetDataOptions}
        for (let n in zoneRecordsetForm) {
            if (zoneRecordsetForm[n].required && !zoneRecordsetData[zoneRecordsetForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[zoneRecordsetForm[n].field_key] = {}
                updatedDataFormOptions[zoneRecordsetForm[n].field_key]["error"] = true
                updatedDataFormOptions[zoneRecordsetForm[n].field_key]["errorText"] = defaultTexts[zoneRecordsetForm[n].error_label]
            }
        }
        setZoneRecordsetDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const onZoneRecordsetCreate = async () => {
        const is_valid = handleFormDataValidation()
        if (is_valid) {
            let updated_data = {...zoneRecordsetData}
            updated_data["records"] = selectedRecordsList.filter(item => item.record.trim().length > 0).map(item => item.record)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                handleCreateRecordsetDialogClose()
                const method = "POST"
                const zone_response = await dnsDesignateRequest({
                    url: `${common_url}/recordsets`, 
                    method: method, 
                    data: updated_data,
                    token: project_token
                })
                if (zone_response.status_code === zoneUrlResponses.post.success_response.status_code) {
                    handleDataFetch()
                    setFetchDataRequired(true)
                } else {
                    setError(zone_response.error)
                }
            }
        }
    }

    useEffect(() => {
        if (selectedRow.type === "SECONDARY") {
            let new_form = zoneUpdateForm.filter(
                item => item.field_key !== "email" && item.field_key !== "ttl")
            setZoneUpdateDataForm(new_form)
        }
    },[selectedRow])

    useEffect(() => {
        let zone_actions = []
        let new_action = {}
        new_action["value"] = "update_zone"
        new_action["action"] = handleUpdateZoneDialogOpen
        new_action["keyword"] = "zoneUpdateActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        zone_actions.push({...new_action})

        new_action = {}
        new_action["value"] = "create_recordset"
        new_action["action"] = handleCreateRecordsetDialogOpen
        new_action["keyword"] = "zoneRecordsetCreateActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        zone_actions.push({...new_action})

        new_action = {}
        new_action["value"] = "zone_export"
        new_action["action"] = () => handleZoneExport([selectedRow.id])
        new_action["keyword"] = "zoneExportActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        zone_actions.push({...new_action})
        
        setZoneActions(zone_actions)
    },[
        selectedRow,
        handleZoneExport
    ])

    useEffect(() => {
        if (Object.keys(zoneUpdateData).length === 0) {
            let new_form_data = {}
            for (const n in zoneUpdateDataForm) {
                new_form_data[zoneUpdateDataForm[n].field_key] = selectedRow[zoneUpdateDataForm[n].field_key]
            }
            setZoneUpdateData(new_form_data)
        }
    },[zoneUpdateData, selectedRow, zoneUpdateDataForm]);

    useEffect(() => {
        if (Object.keys(zoneRecordsetData).length === 0) {
            let new_form_data = {}
            for (const n in zoneRecordsetForm) {
                if (
                    zoneRecordsetForm[n].field_type === "string" || 
                    zoneRecordsetForm[n].field_type === "select"
                    ) {
                        new_form_data[zoneRecordsetForm[n].field_key] = zoneRecordsetForm[n].default_value ? 
                        zoneRecordsetForm[n].default_value : 
                        ""
                } else if (zoneRecordsetForm[n].field_type === "integer") {
                    new_form_data[zoneRecordsetForm[n].field_key] = zoneRecordsetForm[n].default_value ? 
                    zoneRecordsetForm[n].default_value : 
                    0
                }
            }
            setZoneRecordsetData(new_form_data)
        }
    },[zoneRecordsetData]);

    useEffect(() => {
        setTimeout(() => setIsCardLoading(false), 600)
    },[])

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${common_url}/nameservers`
                const method = "GET"
                const response = await dnsDesignateRequest({url:url, method:method, token: project_token})
                if (response.status_code === zoneUrlResponses.get.success_response.status_code) {
                    setZoneNameservers(response.data.nameservers)
                } 
            }
        })()
    },[
        common_url,
        defaultAdminProject
    ])

    useEffect(() => {
        if (fetchDataRequired) {
            (async () => {
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    const url = `${common_url}/recordsets`
                    const method = "GET"
                    const response = await dnsDesignateRequest({url:url, method:method, token: project_token})
                    if (response.status_code === zoneUrlResponses.get.success_response.status_code) {
                        setZoneRecordset(response.data.recordsets)
                    } 
                }
            })()
            setFetchDataRequired(false)
        }
    },[
        common_url,
        defaultAdminProject,
        fetchDataRequired
    ])

    useEffect(() => {
        if (fetchExportDataRequired) {
            (async () => {
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    const url = `${dnsServiceDomain}/${dnsServiceVersion}/${zonesUrl}/tasks/exports?zone_id=${selectedRow.id}`
                    const method = "GET"
                    const response = await dnsDesignateRequest({url:url, method:method, token: project_token})
                    if (response.status_code === zoneUrlResponses.get.success_response.status_code) {
                        setZoneExports(response.data.exports)
                    } 
                }
            })()
            setFetchExportDataRequired(false)
        }
    },[
        dnsServiceDomain,
        dnsServiceVersion,
        zonesUrl,
        defaultAdminProject,
        fetchExportDataRequired,
        selectedRow
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <React.Fragment>
        {selectedRow !== null && 
        <WrapperBox>
            <ServiceContentHeader 
                service_menu={zoneSubMenu}
                service_menu_titles={defaultTexts}
                onClick={handleZoneDetailTabChange}
            />
        </WrapperBox>}
        {currentTab === "/zone-details" &&
            <ZoneSpecsV2 
                zoneData={getFormattedZoneData(selectedRow)}
                selectedRow={selectedRow}
            />
        }
        {currentTab === "/zone-attributes" &&
            <ZoneAttributesV2  
                zoneData={selectedRow}
                handleDataFetch={handleDataFetch}
            />
        }
        {currentTab === "/zone-nameservers" && 
            <div>
                {zoneNameservers.length > 0 ?
                    <ZoneNameserversV2
                        zoneNameservers={zoneNameservers}
                        handleDataFetch={handleDataFetch}
                    />
                    :
                    <NoDataNote text={defaultTexts.noNameserversNoteText} />}
            </div>
        }
        
        {currentTab === "/zone-recordset" && 
            <div>
                {zoneRecordset.length > 0 ?
                    <ZoneRecordsetV2
                        zoneRecordset={zoneRecordset}
                        selectedRow={selectedRow}
                        handleDataFetch={handleDataFetch}
                        handleRecordsetFetch={handleRecordsetFetch}
                    />
                :
                    <NoDataNote text={defaultTexts.noRecordsetNoteText} />}
            </div>
        }
        {currentTab === "/zone-exports" && 
            <div>
                {zoneExports.length > 0 ?
                    <ZoneExportsV2
                        zoneExports={zoneExports}
                        handleExportsFetch={handleExportsFetch}
                    />
                :
                    <NoDataNote text={defaultTexts.noExportsNoteText} />}
            </div>
        }
        {!isCardLoading && <Paper sx={{ 
            position: 'fixed', 
            bottom: 0, 
            height: FOOTER_HEIGHT,
            left: FOOTER_WIDTH, 
            right: 12,
            zIndex: 3000
            }} 
            elevation={24}
            square={true}
        >
            <Grid 
                container 
                alignItems="center"  
                justifyContent="space-between"
            >
                <Grid item>
                    <CustomSelectField 
                        items={zoneActions} 
                        currentValue={currentAction}
                        setCurrentValue={setCurrentAction}
                        item_titles={defaultTexts}
                        label={defaultTexts.actionsDropdownLabelText}
                        empty={true}
                        size="small"
                        sx={{m: 1}}
                    />
                    {currentAction.length > 0 && 
                        <Button 
                                variant="contained"
                                color="secondary"
                                sx={{m: 1, height: '70%'}}
                                onClick={zoneActions.filter(
                                    action => action.value === currentAction)[0].action
                                }
                            >
                            {defaultTexts[zoneActions.filter(
                                action => action.value === currentAction)[0].button_text]}
                        </Button>
                    }
                </Grid>
                <Grid item>
                    {selectedRow !== null && 
                        <IconButton onClick={handleConfirmDeleteDialogOpen}>
                            <DeleteIcon 
                                color="primary"
                            />
                        </IconButton>}
                </Grid>
            </Grid>
        </Paper>} 
        <CustomDialog
            open={updateZoneDialogOpen}
            onClose={handleUpdateZoneDialogClose}
            dialogTitle={{
                title: defaultTexts.updateZoneActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onZoneUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm(
                zoneUpdateDataForm,
                {},
                zoneUpdateData,
                handleZoneUpdateDataChange
            )}
        </CustomDialog>
        <CustomDialog
            open={createRecordsetDialogOpen}
            onClose={handleCreateRecordsetDialogClose}
            dialogTitle={{
                title: defaultTexts.createZoneRecordsetActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: defaultTexts.addZoneRecDialogDescriptionText, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onZoneRecordsetCreate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm(
                zoneRecordsetForm,
                zoneRecordsetDataOptions,
                zoneRecordsetData,
                handleZoneRecordsetUpdateDataChange
            )}
            {selectedRecordsList.map((rec,index) => {
                return (
                    <div key={index}>
                    <CustomText>{defaultTexts.recordFormFieldLabel} {index + 1}</CustomText>
                        {zoneRecordForm.map(item => {
                            return (
                                getFormFieldComponent(
                                    item,
                                    rec,
                                    handleZoneRecordEdit,
                                    defaultTexts[item.label],
                                    {index: index}
                                )
                            )
                        })}
                    </div>
                )
            })}
            {selectedRecordsList.length > 1 && <MinusButton 
                style={{ transform: 'scale(0.7)' }}
                sx={{height: 5}}
                onClick={handleZoneRecordRemove}
            />}
            {<Stack 
                direction="row" 
                spacing={2} 
                alignItems="center"
                onClick={handleZoneRecordAdd}
                sx={{cursor: "pointer"}}
            >
                <PlusButton 
                    style={{ transform: 'scale(0.7)' }}
                    sx={{height: 5}}
                    onClick={handleZoneRecordAdd}
                />
                <CustomText>
                    {defaultTexts.addZoneRecordFormFieldLabel}
                </CustomText>
            </Stack>}
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts.failedActionErrorDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
        </React.Fragment>
    )
};

export default ZoneDetailV2;