import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import { openstackRequest, dnsDesignateRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { zonesUrl as zonesUrlResponses } from 
'../../../../../_api_responses/openstack/dns/zones/v2';
import { projectsUrl as projectsUrlResponses} from
'../../../../../_api_responses/openstack/identity/projects/v3';
import { zonesFilterMenu } from 
'../../../../../_data/openstack/dns/zones/v2';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { dnsDesignateConstants, identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import ZonesSubheaderV2 from './zonesSubheaderV2';
import ZonesTableV2 from './zonesTableV2';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import ZoneDetailV2 from './zoneDetailV2'
import Box from '@mui/material/Box';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';

const SERVICE_NAME = openStackServices.dnsService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.6
    } else {
        return 0.8
    }
}

const ZonesWrapperV2 = (props) => {
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState()
    const [success, setSuccess] = useState()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)

    const filterMenu = zonesFilterMenu
    const [zonesData, setZonesData] = useState([]);
    const [zones, setZones] = useState([]);
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = getWidthWeight(width)
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedZone, setSelectedZone] = useState(null);
    const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [filterQueryParams, setFilterQueryParams] = useState("")
    const [sortQueryParams, setSortQueryParams] = useState("");
    const [currentAction, setCurrentAction] = useState("");
    const [selectedZones, setSelectedZones] = useState([])
    const [zoneDeleteConfirmDialogOpen, setZoneDeleteConfirmDialogOpen] = useState(false);
    const [projectsList, setProjectsList] = useState([])
    const [projects, setProjects] = useState([])

    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [fetchDataRequired, setFetchDataRequired] = useState(true);

    const dnsServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const dnsServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const zonesUrl = useSelector(
        state => state.dnsDesignate.dnsDesignateApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === dnsDesignateConstants.zonesUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)

    const handleZoneDataFormatting = useCallback((data) => {

        const formatted_data = data.map(item => {
            let new_item = {...item}
            if (projectsList.length > 0) {
                new_item.project_id = projectsList.filter(p => p.id === new_item.project_id)[0].name
            }
            return new_item
        })

        setZonesData(formatted_data)
    },[projectsList])

    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    const handleFilteredSearch = () => {
        if (selectedFilter && selectedFilterValue) {
            setFilterQueryParams(`${selectedFilter}=${selectedFilterValue}`)
        } else {
            setFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleZoneSorting = (field, direction) => {
        let new_query = `&sort_key=${field}&sort_dir=${direction}`
        setSortQueryParams(new_query)
        handleDataFetch()
    }

    const handleFilterReset = () => {
        setSelectedFilter(filterMenu[0].value)
        setSelectedFilterValue("")
        setFilterQueryParams("")
        handleDataFetch()
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccess(null);
        setSuccessDialogOpen(false);
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedZone(zonesData[index].id)
        setSelectedRow(zonesData[index])
        setTimeout(() => setDetailCardOpen(true), 100)   
    },[zonesData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false), 100)   
        setSelectedZone(null)
        setSelectedRow(null)
    };

    const onZoneDeleteConfirm = (n_list) => {
        const selected_n_list = zonesData.filter(n => 
            n_list.includes(n.id))
        setSelectedZones([...selected_n_list])
        setZoneDeleteConfirmDialogOpen(true)
    }

    const handleZoneDeleteConfirmDialogClose = () => {
        setZoneDeleteConfirmDialogOpen(false)
    }

    const handleDeleteZoneAction = async (zone_id,token) => {
        const url = `${dnsServiceDomain}/${dnsServiceVersion}/${zonesUrl}/${zone_id}`
        const method = "DELETE"
        const response = await dnsDesignateRequest({url:url, method:method, token: token})
        if (response.status_code === zonesUrlResponses.delete.success_response.status_code) {
            return null
        } else {
            return response.error
        }
    }

    const onZoneDelete = async (zone_list) => {
        handleZoneDeleteConfirmDialogClose()
        let err = []
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let n in zone_list) {
                const resp = await handleDeleteZoneAction(zone_list[n].id,project_token)
                if (resp !== null) {
                    err = [...err, resp]
                }
            }
        }
        handleDetailCardClose()
        setCurrentAction("")
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleExportZoneAction = async (zone_id,token) => {
        const url = `${dnsServiceDomain}/${dnsServiceVersion}/${zonesUrl}/${zone_id}/tasks/export`
        const method = "POST"
        const response = await dnsDesignateRequest({url:url, method:method, token: token})
        if (response.status_code === zonesUrlResponses.post.success_response.status_code) {
            return null
        } else {
            return response.error
        }
    }

    const handleZoneExport = async (z_list) => {
        handleLoading(true)
        let err = []
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let z in z_list) {
                const resp = await handleExportZoneAction(z_list[z],project_token)
                if (resp !== null) {
                    err = [...err, resp]
                }
            }
        }
        handleLoading(true)
        setCurrentAction("")
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        } else {
            let success_object = {}
            success_object["success_title"] = "successZoneExportTitle"
            success_object["success_message"] = "successZoneExportMessage"
            setSuccess(success_object)
            setSuccessDialogOpen(true)
        }
    }

    const getZonesActionsList = () => {
        let zone_actions = []
        let new_action = {}

        new_action["value"] = "zone_delete"
        new_action["action"] = onZoneDeleteConfirm
        new_action["keyword"] = "zoneDeleteActionLabel"
        new_action["button_text"] = "applyButtonTitleText"
        zone_actions.push({...new_action})

        new_action = {}

        new_action["value"] = "zone_export"
        new_action["action"] = handleZoneExport
        new_action["keyword"] = "zoneExportActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        zone_actions.push({...new_action})
        
        return zone_actions
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    useEffect(() => {
        setFetchDataRequired(true)
    },[filterQueryParams,sortQueryParams]);


    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"
            const projects_response = await openstackRequest({url:url, method:method})
            
            if ( projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                setProjectsList(projects_response.data.projects)
                const project_select_list = projects_response.data.projects.map(p => {
                    return {keyword: p.name, value: p.id, default: false}
                })
                setProjects(project_select_list)
            }
        })()
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (fetchDataRequired) {
        (async () => {
            handleLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${dnsServiceDomain}/${dnsServiceVersion}/${zonesUrl}/?${filterQueryParams}${sortQueryParams}`
                const method = "GET"
                const response = await dnsDesignateRequest({url:url, method:method, token: project_token})
                if (response.status_code === zonesUrlResponses.get.success_response.status_code) {
                    //handleZoneDataFormatting(response.data.zones)
                    setZones(response.data.zones)
                    if (selectedZone) {
                        const selected_zone = response.data.zones.filter(item => item.id === selectedZone)
                        if (selected_zone.length > 0) {
                            setSelectedRow(selected_zone[0])
                        }
                    }
                } 
            }
        })();
        }
        setFetchDataRequired(false)
        setTimeout(()=>{handleLoading(false)},1000)
    },[
        dnsServiceDomain, 
        dnsServiceVersion, 
        zonesUrl, 
        selectedZone,
        filterQueryParams,
        fetchDataRequired,
        sortQueryParams,
        defaultAdminProject,
        handleZoneDataFormatting,
        selectedRow
    ]);

    useEffect(() => {
        handleZoneDataFormatting(zones)
    },[
        projectsList,
        zones,
        handleZoneDataFormatting
    ])

    return (
        <Box>
            <ZonesSubheaderV2 
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                selectedFilterValue={selectedFilterValue}
                setSelectedFilterValue={setSelectedFilterValue}
                filterMenu={filterMenu}
                handleFilteredSearch={handleFilteredSearch}
                handleFilterReset={handleFilterReset}
                handleFetchData={handleDataFetch}
                defaultTexts={defaultTexts}
                projects={projects}
            />
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading && <ZonesTableV2 
                zonesData={zonesData}
                setZonesData={setZonesData}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getZonesActionsList()}
                selfSorting={true}
                sortHandler={handleZoneSorting}
            />}
            {selectedRow !== null && 
                <CustomSideDrawer 
                    open={detailCardOpen}
                    widthWeight={WIDTH_WEIGHT}
                    handleDrawerOpen={handleDetailCardOpen}
                    handleDrawerClose={handleDetailCardClose}
                > 
                    <ZoneDetailV2
                        selectedRow={selectedRow}
                        widthWeight={WIDTH_WEIGHT}
                        handleDataFetch={handleDataFetch}
                        handleDelete={onZoneDeleteConfirm}
                        projects={projects}
                        handleZoneExport={handleZoneExport}
                    />
                </CustomSideDrawer>
            }
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {success && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[success.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[success.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            <CustomDialog
                open={zoneDeleteConfirmDialogOpen}
                onClose={handleZoneDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.zoneDeleteConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.zoneDeleteConfirmText}: [${selectedZones.map(v => v.name).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: () => onZoneDelete(selectedZones), 
                    sx: {color: 'primary.main'}}]}
            />
        </Box>
    )
}

export default ZonesWrapperV2;