import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dnsDesignateRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { zonesUrl as zonesUrlResponses } from 
'../../../../../_api_responses/openstack/dns/zones/v2';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { dnsDesignateConstants } from 
'../../../../../config/openStackConstants';
import PTRRecordsTableV2 from './ptrRecordsTableV2';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import Box from '@mui/material/Box';

const SERVICE_NAME = openStackServices.dnsService

const PTRRecordsWrapperV2 = (props) => {
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState()
    const [success, setSuccess] = useState()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)

    const [ptrRecords, setPTRRecords] = useState([]);
    const [currentAction, setCurrentAction] = useState("");
    const [selectedPTRRecords, setSelectedPTRRecords] = useState([])
    const [ptrDeleteConfirmDialogOpen, setPTRDeleteConfirmDialogOpen] = useState(false);

    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [fetchDataRequired, setFetchDataRequired] = useState(true);

    const dnsServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const dnsServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const reverseFloatingIPsUrl = useSelector(
        state => state.dnsDesignate.dnsDesignateApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === dnsDesignateConstants.reverseFloatingIPsUrl)[0].url)

    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccess(null);
        setSuccessDialogOpen(false);
    }

    const onPTRDeleteConfirm = (p_list) => {
        const selected_p_list = ptrRecords.filter(p => 
            p_list.includes(p.id))
            setSelectedPTRRecords([...selected_p_list])
        setPTRDeleteConfirmDialogOpen(true)
    }

    const handlePTRDeleteConfirmDialogClose = () => {
        setPTRDeleteConfirmDialogOpen(false)
    }

    const handleDeletePTRAction = async (fip_id,token) => {
        const url = `${dnsServiceDomain}/${dnsServiceVersion}/${reverseFloatingIPsUrl}/${fip_id}`
        const method = "PATCH"
        const data = {ptrdname: null}
        const response = await dnsDesignateRequest({url:url, method:method, data: data, token: token})
        if (response.status_code === zonesUrlResponses.patch.success_response.status_code) {
            return null
        } else {
            return response.error
        }
    }

    const onPTRDelete = async (ptr_list) => {
        handlePTRDeleteConfirmDialogClose()
        let err = []
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let p in ptr_list) {
                const resp = await handleDeletePTRAction(ptr_list[p].id,project_token)
                if (resp !== null) {
                    err = [...err, resp]
                }
            }
        }
        setCurrentAction("")
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const getPTRsActionsList = () => {
        let ptr_actions = []
        let new_action = {}

        new_action["value"] = "ptr_unset"
        new_action["action"] = onPTRDeleteConfirm
        new_action["keyword"] = "ptrUnsetActionLabel"
        new_action["button_text"] = "applyButtonTitleText"
        ptr_actions.push({...new_action})
        
        return ptr_actions
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    useEffect(() => {
        if (fetchDataRequired) {
        (async () => {
            handleLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${dnsServiceDomain}/${dnsServiceVersion}/${reverseFloatingIPsUrl}`
                const method = "GET"
                const response = await dnsDesignateRequest({url:url, method:method, token: project_token})
                if (response.status_code === zonesUrlResponses.get.success_response.status_code) {
                    setPTRRecords(response.data.floatingips)
                } 
            }
        })();
        }
        setFetchDataRequired(false)
        setTimeout(()=>{handleLoading(false)},600)
    },[
        dnsServiceDomain, 
        dnsServiceVersion, 
        reverseFloatingIPsUrl,
        fetchDataRequired,
        defaultAdminProject
    ]);

    return (
        <Box>
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading && <PTRRecordsTableV2 
                ptrRecords={ptrRecords}
                setPTRRecords={setPTRRecords}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getPTRsActionsList()}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {success && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[success.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[success.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            <CustomDialog
                open={ptrDeleteConfirmDialogOpen}
                onClose={handlePTRDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.ptrUnsetConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.ptrUnsetConfirmText}: [${selectedPTRRecords.map(v => v.name).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: () => onPTRDelete(selectedPTRRecords), 
                    sx: {color: 'primary.main'}}]}
            />
        </Box>
    )
}

export default PTRRecordsWrapperV2;