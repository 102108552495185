import React from 'react';
import Button from '@mui/material/Button';

const BigButton = (props) => {
    const { btnTitleText } = props
    const { onClickHandler } = props
    const { size } = props
    const { mainColor, hoverColor, sx } = props

    const submitButtonHandler = () => {
        onClickHandler();
    }

    return (
        <Button 
            onClick={submitButtonHandler}
            variant="outlined"
            size={size ? size : "large"}
            sx={{
                alignSelf: 'center',
                mt: 2,
                py: 3,
                borderRadius: 1, 
                borderColor: mainColor ? mainColor : 'primary.main',
                width: '17rem',
                height: '3rem', 
                color: 'white', 
                backgroundColor: mainColor ? mainColor : 'primary.main',
                '&:hover': {
                    borderColor: mainColor ? mainColor : 'primary.main',
                    backgroundColor: hoverColor ? hoverColor : 'primary.light',
                    opacity: [0.7, 0.6, 0.5],
                },
                ...sx
            }}
    >
            {btnTitleText}
        </Button>
    )
};

export default BigButton;