import React from 'react';
import { useSelector } from 'react-redux';
import CustomText from '../../_common/CustomText';
import WrapperBox from '../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';

const detailTabCardConfig = {
    border: 0.3,
    borderRadius: 2,
    borderStyle: "dashed",
    borderColor: "text.secondary",
    minHeight: 450
}

const PolicySpecs = (props) => {
    const { policyData } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);

    const weekDays = [
        defaultTexts.sundayFormFieldLabel,
        defaultTexts.mondayFormFieldLabel,
        defaultTexts.tuesdayFormFieldLabel,
        defaultTexts.wednesdayFormFieldLabel,
        defaultTexts.thursdayFormFieldLabel,
        defaultTexts.fridayFormFieldLabel,
        defaultTexts.saturdayFormFieldLabel
    ]

    const monthTexts = [
        defaultTexts.janMonthFormFieldLabel,
        defaultTexts.febMonthFormFieldLabel,
        defaultTexts.marMonthFormFieldLabel,
        defaultTexts.AprMonthFormFieldLabel,
        defaultTexts.mayMonthFormFieldLabel,
        defaultTexts.junMonthFormFieldLabel,
        defaultTexts.julMonthFormFieldLabel,
        defaultTexts.augMonthFormFieldLabel,
        defaultTexts.sepMonthFormFieldLabel,
        defaultTexts.octMonthFormFieldLabel,
        defaultTexts.novMonthFormFieldLabel,
        defaultTexts.decMonthFormFieldLabel
    ]

    return (
        <React.Fragment>
            <Grid container spacing={2} sx={{mt: 1, mb: 4}}>
                <Grid item xs={12} lg={6}>
                    <WrapperBox
                        sx={detailTabCardConfig}
                    >
                        <Stack direction="column" spacing={1} sx={{width: "90%"}}>
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.idFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {policyData ? policyData.id : ""}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.nameFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {policyData ? policyData.name : ""}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.resourceFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {policyData ? 
                                        (policyData.resource.type === "volume" ? 
                                            defaultTexts.volumeFormFieldLabel : 
                                            defaultTexts.serverFormFieldLabel
                                        ) : ""}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.retentionTypeFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {policyData ? policyData.retention.type.toUpperCase() : ""}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                            <CustomText 
                                size="p" 
                                sx={{color: "text.secondary"}}
                            >
                                {defaultTexts.keepFormFieldLabel}
                            </CustomText>
                            <CustomText size="p">
                                {policyData ? 
                                    (policyData.retention.type === "age" ?
                                        `${policyData.retention.count} ${policyData.retention.time}` :
                                        policyData.retention.count
                                    ) : ""}
                            </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.createTimeHeaderLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {policyData && new Date(policyData.created_at).toLocaleString()}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.enabledFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {policyData &&  policyData.enabled ? 
                                        defaultTexts.formValueYes : 
                                        defaultTexts.formValueNo
                                    }
                                </CustomText>
                            </Stack>
                            <Divider />
                        </Stack>
                    </WrapperBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <WrapperBox
                        sx={detailTabCardConfig}
                    >
                        <Stack 
                            direction="column" 
                            spacing={1} 
                            sx={{width: "90%"}}
                        >
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.customCronStringFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {policyData &&  policyData.type === "custom" ? 
                                        defaultTexts.formValueYes : 
                                        defaultTexts.formValueNo
                                    }
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.cronStringFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {policyData ? policyData.cron : ""}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.nextRunFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {policyData && new Date(policyData.next_run).toLocaleString()}
                                </CustomText>
                            </Stack>
                            <Divider />
                            {policyData.type === "standard" && <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.frequencyFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {policyData ? policyData.params.frequency.toUpperCase() : "-"}
                                </CustomText>
                            </Stack>}
                            {policyData.type === "standard" && <Divider />}
                            {(policyData.type === "standard" && policyData.params.frequency === "daily" && policyData.params.every) && <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.everyFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {policyData ? `${policyData.params.every} Hour(s)` : "-"}
                                </CustomText>
                            </Stack>}
                            {(policyData.type === "standard" && policyData.params.frequency === "daily" && policyData.params.every) && <Divider />}
                            {(policyData.type === "standard" && policyData.params.frequency === "weekly") && <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.weekdaysFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {policyData ? policyData.params.weekdays.map(d => `${weekDays[d]},`) : ""}
                                </CustomText>
                            </Stack>}
                            {(policyData.type === "standard" && policyData.params.frequency === "weekly") && <Divider />}
                            {(policyData.type === "standard" && policyData.params.frequency === "monthly") && <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.onDateFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {policyData ? (policyData.params.every !== "32" ? policyData.params.every : defaultTexts.lastDayFormFieldLabel) : ""}
                                </CustomText>
                            </Stack>}
                            {(policyData.type === "standard" && policyData.params.frequency === "monthly") && <Divider />}
                            {(policyData.type === "standard" && policyData.params.frequency === "yearly") && <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.onDateFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {policyData ? `${policyData.params.monthday} day of ${monthTexts[policyData.params.every - 1]}` : ""}
                                </CustomText>
                            </Stack>}
                            {(policyData.type === "standard" && policyData.params.frequency === "yearly") && <Divider />}
                            {policyData.type === "standard" &&  <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.startingAtFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {policyData ? 
                                        (parseInt(policyData.params.starting_at) < 10 ? 
                                        `UTC 0${policyData.params.starting_at}:00` : 
                                        `UTC ${policyData.params.starting_at}:00`) : ""}
                                </CustomText>
                            </Stack>}
                            {policyData.type === "standard" && <Divider />}
                        </Stack>
                    </WrapperBox>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

export default PolicySpecs;