import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dnsDesignateRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { tldsUrl as tldsUrlResponses } from 
'../../../../../_api_responses/openstack/dns/tlds/v2';
import { tldsFilterMenu, tldUpdateForm } from 
'../../../../../_data/openstack/dns/tlds/v2';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { dnsDesignateConstants } from 
'../../../../../config/openStackConstants';
import TLDsSubheaderV2 from './tldsSubheaderV2';
import TLDsTableV2 from './tldsTableV2';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';

const SERVICE_NAME = openStackServices.dnsService

const TLDsWrapperV2 = (props) => {
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState()
    const [success, setSuccess] = useState()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)

    const filterMenu = tldsFilterMenu
    const [tldsData, setTLDsData] = useState([]);
    const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [filterQueryParams, setFilterQueryParams] = useState("")
    const [sortQueryParams, setSortQueryParams] = useState("");
    const [currentAction, setCurrentAction] = useState("");
    const [selectedTLDs, setSelectedTLDs] = useState([])
    const [tldDeleteConfirmDialogOpen, setTLDDeleteConfirmDialogOpen] = useState(false);

    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [fetchDataRequired, setFetchDataRequired] = useState(true);
    const [updateTLDDialogOpen, setUpdateTLDDialogOpen] = useState(false)
    const [tldUpdateData, setTLDUpdateData] = useState({})

    const dnsServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const dnsServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const tldsUrl = useSelector(
        state => state.dnsDesignate.dnsDesignateApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === dnsDesignateConstants.tldsUrl)[0].url)


    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    const handleFilteredSearch = () => {
        if (selectedFilter && selectedFilterValue) {
            setFilterQueryParams(`${selectedFilter}=${selectedFilterValue}`)
        } else {
            setFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleTLDSorting = (field, direction) => {
        let new_query = `&sort_key=${field}&sort_dir=${direction}`
        setSortQueryParams(new_query)
        handleDataFetch()
    }

    const handleFilterReset = () => {
        setSelectedFilter(filterMenu[0].value)
        setSelectedFilterValue("")
        setFilterQueryParams("")
        handleDataFetch()
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccess(null);
        setSuccessDialogOpen(false);
    }

    const onTLDDeleteConfirm = (n_list) => {
        const selected_n_list = tldsData.filter(n => 
            n_list.includes(n.id))
        setSelectedTLDs([...selected_n_list])
        setTLDDeleteConfirmDialogOpen(true)
    }

    const handleTLDDeleteConfirmDialogClose = () => {
        setTLDDeleteConfirmDialogOpen(false)
    }

    const handleTLDUpdateDataChange = (event,field_key) => {
        let new_form_data = {...tldUpdateData}
        if (tldUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else {
            new_form_data[field_key] = event.target.value
        }
        setTLDUpdateData(new_form_data)
    }

    const handleUpdateTLDDialogOpen = (n_list) => {
        const selected_n_list = tldsData.filter(n => 
            n_list.includes(n.id))
        setSelectedTLDs([...selected_n_list])
        setUpdateTLDDialogOpen(true)
    }

    const handleUpdateTLDDialogClose = () => {
        setUpdateTLDDialogOpen(false)
    }

    const getDataForm = (form,form_options,data,onDataChange) => {
        return (
            <FormGroup>
                {form.map(field => {
                    let new_field = {...field}
                    delete new_field.label
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {
                                ...form_options[field.field_key],
                                ...new_field,
                                item_titles: defaultTexts
                            }
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleUpdateTLDAction = async (tld_id,token) => {
        const url = `${dnsServiceDomain}/${dnsServiceVersion}/${tldsUrl}/${tld_id}`
        const method = "PATCH"
        const response = await dnsDesignateRequest({url:url, method:method, data: tldUpdateData, token: token})
        if (response.status_code === tldsUrlResponses.patch.success_response.status_code) {
            return null
        } else {
            return response.error
        }
    }

    const onTLDUpdate = async (tld_list) => {
        handleUpdateTLDDialogClose()
        let err = []
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let n in tld_list) {
                const resp = await handleUpdateTLDAction(tld_list[n].id,project_token)
                if (resp !== null) {
                    err = [...err, resp]
                }
            }
        }
        setCurrentAction("")
        setTLDUpdateData({})
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleDeleteTLDAction = async (tld_id,token) => {
        const url = `${dnsServiceDomain}/${dnsServiceVersion}/${tldsUrl}/${tld_id}`
        const method = "DELETE"
        const response = await dnsDesignateRequest({url:url, method:method, token: token})
        if (response.status_code === tldsUrlResponses.delete.success_response.status_code) {
            return null
        } else {
            return response.error
        }
    }

    const onTLDDelete = async (tld_list) => {
        handleTLDDeleteConfirmDialogClose()
        let err = []
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let n in tld_list) {
                const resp = await handleDeleteTLDAction(tld_list[n].id,project_token)
                if (resp !== null) {
                    err = [...err, resp]
                }
            }
        }
        setCurrentAction("")
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const getTLDsActionsList = () => {
        let tld_actions = []
        let new_action = {}

        new_action["value"] = "tld_delete"
        new_action["action"] = onTLDDeleteConfirm
        new_action["keyword"] = "tldDeleteActionLabel"
        new_action["button_text"] = "applyButtonTitleText"
        tld_actions.push({...new_action})

        new_action = {}

        new_action["value"] = "tld_update"
        new_action["action"] = handleUpdateTLDDialogOpen
        new_action["keyword"] = "tldUpdateActionLabel"
        new_action["button_text"] = "applyButtonTitleText"
        tld_actions.push({...new_action})
        
        return tld_actions
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    useEffect(() => {
        setFetchDataRequired(true)
    },[filterQueryParams,sortQueryParams]);

    useEffect(() => {
        if (fetchDataRequired) {
        (async () => {
            handleLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${dnsServiceDomain}/${dnsServiceVersion}/${tldsUrl}/?${filterQueryParams}${sortQueryParams}`
                const method = "GET"
                const response = await dnsDesignateRequest({url:url, method:method, token: project_token})
                if (response.status_code === tldsUrlResponses.get.success_response.status_code) {
                    setTLDsData(response.data.tlds)
                } 
            }
        })();
        }
        setFetchDataRequired(false)
        setTimeout(()=>{handleLoading(false)},1000)
    },[
        dnsServiceDomain, 
        dnsServiceVersion, 
        tldsUrl,
        filterQueryParams,
        fetchDataRequired,
        sortQueryParams,
        defaultAdminProject
    ]);

    return (
        <Box>
            <TLDsSubheaderV2 
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                selectedFilterValue={selectedFilterValue}
                setSelectedFilterValue={setSelectedFilterValue}
                filterMenu={filterMenu}
                handleFilteredSearch={handleFilteredSearch}
                handleFilterReset={handleFilterReset}
                handleFetchData={handleDataFetch}
                defaultTexts={defaultTexts}
            />
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading && <TLDsTableV2 
                tldsData={tldsData}
                setTLDsData={setTLDsData}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getTLDsActionsList()}
                selfSorting={true}
                sortHandler={handleTLDSorting}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {success && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[success.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[success.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            <CustomDialog
                open={tldDeleteConfirmDialogOpen}
                onClose={handleTLDDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.tldDeleteConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.tldDeleteConfirmText}: [${selectedTLDs.map(v => v.name).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: () => onTLDDelete(selectedTLDs), 
                    sx: {color: 'primary.main'}}]}
            />
            <CustomDialog
                open={updateTLDDialogOpen}
                onClose={handleUpdateTLDDialogClose}
                dialogTitle={{
                    title: defaultTexts.updateTLDActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: () => onTLDUpdate(selectedTLDs),
                    sx: {color: 'primary.main'}}]}
            >
                {getDataForm(
                    tldUpdateForm,
                    {},
                    tldUpdateData,
                    handleTLDUpdateDataChange
                )}
            </CustomDialog>
        </Box>
    )
}

export default TLDsWrapperV2;