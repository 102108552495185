export const success_create_response = {
    status_code: 200, 
    response_title: "successResponseTitle", 
    response_message: "successCreateResponseMessage"
}

export const success_create2_response = {
    status_code: 201, 
    response_title: "successResponseTitle", 
    response_message: "successCreateResponseMessage"
}

export const success_accepted_response = {
    status_code: 202, 
    response_title: "successAcceptedResponseTitle", 
    response_message: "successAcceptedResponseMessage"
}

export const success_update_response = {
    status_code: 200, 
    response_title: "successUpdateTitle", 
    response_message: "successUpdateResponseMessage"
}

export const success_delete_response = {
    status_code: 204, 
    response_title: "successDeleteTitle", 
    response_message: "successDeleteResponseMessage"
}

export const bad_request_response = {
    status_code: 400,
    response_title: "badRequestResponseTitle", 
    response_message: "badRequestResponseMessage"
}

export const forbidden_response = {
    status_code: 403,
    response_title: "forbiddenResponseTitle", 
    response_message: "forbiddenResponseMessage"
}

export const not_found_response = {
    status_code: 404,
    response_title: "notFoundResponseTitle", 
    response_message: "notFoundResponseMessage"
}

export const conflict_response = {
    status_code: 409,
    response_title: "conflictResponseTitle", 
    response_message: "conflictResponseMessage"
}

export const service_unavailable = {
        status_code: 503,
        response_title: "serviceUnavailableResponseTitle", 
        response_message: "serviceUnavailableResponseMessage"
}

export const unknown_response = {
    status_code: "unknown",
    response_title: "unknownResponseTitle", 
    response_message: "unknownResponseMessage"
}