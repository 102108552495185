import Constants from '../config/constants';
import { store } from '../store/configStore';
import { getAccessToken } from './auth';
import { setAccessToken } from '../store/reducers/profileSlice';
import { getXAuthTokenProjectScope } from "./openstack_request";

const backupRequest = async ({url, method, headers, data, form_data, timeout}) => {
    const set_timeout = timeout ? timeout : 10000
    const state = store.getState() 
    let access_token = state.profile.access_token 
    const defaultAdminProject = state.profile.defaultAdminProject.id
    const clientAccountID = state.profile.clientAccountID
    const regionName = state.settings.regionName

    if (!access_token) {
        const new_access_token = await getAccessToken()
        if (new_access_token) {
            access_token = new_access_token
            store.dispatch(setAccessToken({access_token: new_access_token}))
        }
    }
    
    const x_auth_token = await getXAuthTokenProjectScope(defaultAdminProject)
    
    let options = {
        method,
        headers: {
        ...headers
        }
    }

    options.headers["Authorization"] = `Bearer ${access_token}`
    options.headers["X-Auth-Token"] = x_auth_token
    options.headers[Constants.backup_service_account_id_header] = clientAccountID
    options.headers[Constants.backup_service_project_id_header] = defaultAdminProject
    options.headers[Constants.backup_service_region_name_header] = regionName
    
    if (data) {
        options.headers["Content-Type"] = "application/json";
        options.body = JSON.stringify(data)
    }

    if (form_data) {
        options.body = form_data
    }

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), set_timeout);

    let response = await fetch(url, {
        ...options,
        signal: controller.signal
    });
    
    clearTimeout(id);

    if (response.status === Constants.unAuthorizedStatusCode) {
        const new_access_token = await getAccessToken()
        if (new_access_token) {
            store.dispatch(setAccessToken({access_token: new_access_token}))
            options.headers["Authorization"] = `Bearer ${new_access_token}`
            response = await fetch(url, options)
        }
    }
    if (response.ok) {
        const status_code = response.status
        if (status_code !== 204 || method !== "DELETE") {
            const res_data = await response.json()
            return {status_code: status_code, data: res_data}
        } else {
            return {status_code: status_code, data: null}
        }
    } 

    let res_data = await response.json()
    return { 
        status_code: response.status, 
        data: null, 
        error: JSON.stringify(res_data)
    }
}

export default backupRequest;