export const tldDataSchema = [
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "created_at",
        is_id_field: false,
        field_type: "datetime", 
        label: "createTimeHeaderLabel"
    },
    {
        field_key: "updated_at",
        is_id_field: false,
        field_type: "datetime", 
        label: "updatedAtFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const tldDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false, 
        label: "descriptionFormFieldLabel"
    }
]

export const tldUpdateForm = [
    {
        field_key: "description",
        field_type: "string", 
        required: false, 
        label: "descriptionFormFieldLabel"
    }
]

export const tldsFilterMenu = [
    {
        keyword: "nameFormFieldLabel", 
        value: "name", 
        type: "text"
    }
]

export const tldsSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    }
]