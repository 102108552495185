import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup'
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';
import { projectsUrl as projectsUrlResponses} from
'../../../../../_api_responses/openstack/identity/projects/v3';
import { openstackRequest, dnsDesignateRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { dnsDesignateConstants, identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import { 
    limitsDataSchema,
    limitsUpdateDataForm
} from '.././../../../../_data/openstack/dns/limits/v2';
import CustomTable from '../../../../_common/CustomTable';
import { getFormFieldComponent } from 
'../../../../../components/_common/_form_fields/form_helpers';
import CustomDialog from '../../../../_common/CustomDialog';
import { blacklistsUrl as limitsUrlResponses } 
from '../../../../../_api_responses/openstack/dns/blacklists/v2';


const SERVICE_NAME = openStackServices.dnsService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const DesignateLimitsWrapperV2 = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [projects, setProjects] = useState([])
    const [selectedProject, setSelectedProject] = useState([defaultAdminProject])
    const [limitsData, setLimitsData] = useState({})
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [updateLimitsDialogOpen, setUpdateLimitsDialogOpen] = useState(false)
    const [limitsUpdateData, setLimitsUpdateData] = useState({})
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);

    const dnsServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const dnsServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const quotasUrl = useSelector(
        state => state.dnsDesignate.dnsDesignateApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === dnsDesignateConstants.quotasUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)

    const handleProjectChange = (project_id) => {
        setSelectedProject(project_id)
        handleDataFetch()
    }

    const handleLoading = () => {
        setIsLoading(true)
    }

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleUpdateLimitsDialogOpen = () => {
        setUpdateLimitsDialogOpen(true)
    }

    const handleUpdateLimitsDialogClose = () => {
        setUpdateLimitsDialogOpen(false)
    }

    const handleUpdateLimitsDataChange = (event, field_key) => {
        let updated_data = {...limitsUpdateData}
        updated_data[field_key] = event.target.value
        
        setLimitsUpdateData(updated_data)
    }

    const getLimitsDataForm = () => {
        let form = [...limitsUpdateDataForm]
        return (
            <FormGroup sx={{my: 3}}>
                {form.map(field => {
                    let form_field_options = {...field}
                    delete form_field_options.label
                    return (
                        getFormFieldComponent(
                            field,
                            limitsUpdateData,
                            handleUpdateLimitsDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleLimitsDataFormatting = (data) => {
            const keys = limitsUpdateDataForm.map(field => field.field_key)
            const formatted_data = keys.map((item) => {
                let new_item = {}
                new_item["resource_name"] = item.toUpperCase()
                new_item["limit"] = data[item]
                return new_item
            })
            return formatted_data
        }

    const onLimitsUpdate = async () => {
        let data = {...limitsUpdateData}
        
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "PATCH"
            const limits_response = await openstackRequest({
                url: `${dnsServiceDomain}/${dnsServiceVersion}/${quotasUrl}/${selectedProject}`, 
                method: method, 
                data: data,
                token: project_token
            })
            if (limits_response.status_code === limitsUrlResponses.patch.success_response.status_code) {
                handleUpdateLimitsDialogClose()
                handleDataFetch()
            } else {
                const error_response = limitsUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === limits_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: limits_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = limitsUrlResponses.patch.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: limits_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"
            const projects_response = await openstackRequest({url:url, method:method})
            
            if ( projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                const projects_list = projects_response.data.projects.map(project => {
                    return {keyword: project.name, value: project.id, default: false}
                })
                setProjects(projects_list)
            }
        })()
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading()
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    const url = `${dnsServiceDomain}/${dnsServiceVersion}/${quotasUrl}/${selectedProject}`
                    const method = "GET"
                    const limits_response = await dnsDesignateRequest({url:url, method:method, token: project_token})
                    if ( limits_response.status_code === limitsUrlResponses.get.success_response.status_code) {
                        setLimitsData(limits_response.data)
                    }
                }
            })()
        }
        setDataFetchingRequired(false)
        setIsLoading(false)
    },[
        dnsServiceDomain,
        dnsServiceVersion,
        quotasUrl,
        defaultAdminProject,
        selectedProject,
        dataFetchingRequired,
    ])

    useEffect(() => {
        if (Object.keys(limitsData).length > 0) {
            let new_data = {}
            for (let i in limitsUpdateDataForm) {
                new_data[limitsUpdateDataForm[i].field_key] = limitsData[limitsUpdateDataForm[i].field_key].limit
            }
            setLimitsUpdateData(new_data)
        }
    },[
        limitsData,
        selectedProject
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);
    
    return (
        <React.Fragment>
            {isLoading && <CustomBackdrop open={isLoading} />}
            <Grid container alignItems="center" justifyContent="space-between" sx={{m: 1}}>
                <Grid item sx={{flexGrow: 1}}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{height: 40, width: {xs: 250, sm: 180}, m: 1}}
                        onClick={handleUpdateLimitsDialogOpen}
                    >
                        {defaultTexts.updateLimitsButtonText}
                    </Button>
                </Grid>
                <Grid item>
                    <CustomSelectField 
                        items={projects}
                        self_item_titles={true}
                        label={defaultTexts.projectFormFieldLabel}
                        size="small"
                        currentValue={selectedProject}
                        setCurrentValue={handleProjectChange}
                        sx={{m: 1, my: 3, width: 250}}
                    />
                </Grid>
            </Grid>
            {!isLoading && Object.keys(limitsData).length > 0 &&
                <CustomTable
                    withRowCount={true}
                    withoutColumnsHide={true}
                    defaultDataTexts={defaultTexts} 
                    tableHeaders={limitsDataSchema}
                    columns={limitsDataSchema}
                    handleRowSelection={()=>{}}
                    dataRows={handleLimitsDataFormatting(limitsData)}
                />
            }
            <CustomDialog
                open={updateLimitsDialogOpen}
                onClose={handleUpdateLimitsDialogClose}
                dialogTitle={{
                    title: defaultTexts.updateLimitsActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.updateDNSLimitsActionText, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onLimitsUpdate, 
                    variant: "contained"
                    }]}
            >
                {getLimitsDataForm()}
            </CustomDialog>
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts.failedActionErrorDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </React.Fragment>
    )
};

export default DesignateLimitsWrapperV2;