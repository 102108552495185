import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dnsDesignateRequest, getXAuthTokenProjectScope, openstackRequest } from 
'../../../../../_network/openstack_request';
import { zonesUrl as zonesUrlResponses } from 
'../../../../../_api_responses/openstack/dns/zones/v2';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { 
    dnsDesignateConstants, 
    networkNeutronConstants,
    identityKeystonConstants,
 } from '../../../../../config/openStackConstants';
import FloatingIPsTableV2 from './floatingIPsTableV2';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import { floatingIPsUrl as floatingIPUrlResponses } 
from '../../../../../_api_responses/openstack/neutron/floating_ips/v2.0';
import { ptrAssignDataForm } 
from '../../../../../_data/openstack/dns/reverse_dns/v2';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import CustomText from '../../../../_common/CustomText';

const SERVICE_NAME = openStackServices.dnsService
const NETWORK_SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const FloatingIPsWrapperV2 = (props) => {
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState()
    const [success, setSuccess] = useState()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const currentRegion = useSelector(state => state.settings.regionName)

    const [floatingIPsData, setFloatingIPsData] = useState([]);
    const [floatingIPs, setFloatingIPs] = useState([]);
    const [ptrRecords, setPtrRecords] = useState([]);
    const [currentAction, setCurrentAction] = useState("");
    const [selectedFloatingIPs, setSelectedFloatingIPs] = useState([])
    const [projectsList, setProjectsList] = useState([])
    const [assignPTRDialogOpen, setAssignPTRDialogOpen] = useState(false);
    const [selectedIPsData, setSelectedIPsData] = useState([])

    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [fetchDataRequired, setFetchDataRequired] = useState(true);

    const dnsServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const dnsServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const reverseFloatingIPsUrl = useSelector(
        state => state.dnsDesignate.dnsDesignateApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === dnsDesignateConstants.reverseFloatingIPsUrl)[0].url)
    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === NETWORK_SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === NETWORK_SERVICE_NAME)[0].config_params.api_version)
    const floatingIPsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.floatingIPsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)

    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccess(null);
        setSuccessDialogOpen(false);
    }

    const handleSetPTRDialogOpen = (ips_list) => {
        setSelectedFloatingIPs(ips_list)
        const ips_data = ips_list.map(id => {
            let new_item = {}
            new_item["id"] = id
            new_item["data"] = {ptrdname: "", description: "", ttl: 3600}
            return new_item
        })
        setSelectedIPsData(ips_data)
        setAssignPTRDialogOpen(true)
    }

    const handleSetPTRDialogClose = () => {
        setSelectedFloatingIPs([])
        setSelectedIPsData([])
        setAssignPTRDialogOpen(false)
    }

    const handlePTRAssignDataChange = (event, field_key, index) => {
        let new_data = [...selectedIPsData]
        new_data[index]["data"][field_key] = event.target.value
        setSelectedIPsData(new_data)
    }

    const getDataForm = (form,form_options,data,onDataChange) => {
        return (
            <div>
                {data.map((data_item,index) => {
                    const ip_address = floatingIPs.filter(item => item.id === data_item.id)[0].floating_ip_address
                    return (
                        <FormGroup key={data_item.id}>
                            <CustomText sx={{color: "secondary.main", mt: 2}}>{defaultTexts.ipAddressFormFieldLabel}: {ip_address}</CustomText>
                            {form.map(field => {
                                let new_field = {...field}
                                delete new_field.label
                                return (
                                    getFormFieldComponent(
                                        field,
                                        data_item.data,
                                        onDataChange,
                                        defaultTexts[field.label],
                                        {
                                            ...form_options[field.field_key],
                                            ...new_field,
                                            item_titles: defaultTexts,
                                            index: index
                                        }
                                    )
                                )
                            })}
                        </FormGroup>
                    )
                })}
            </div>
        )
    }

    const handleAssignPTRAction = async (ip_id, token) => {
        const data = selectedIPsData.filter(item => item.id === ip_id)[0].data
        const url = `${dnsServiceDomain}/${dnsServiceVersion}/${reverseFloatingIPsUrl}/${currentRegion}:${ip_id}`
        const method = "PATCH"
        const response = await dnsDesignateRequest({
            url:url, 
            method:method, 
            data: data,
            token: token
        })
        if (response.status_code === zonesUrlResponses.patch.success_response.status_code) {
            return null
        } else {
            return response.error
        }
    }

    const onPTRAssign = async () => {
        handleSetPTRDialogClose()
        let err = []
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let ip in selectedFloatingIPs) {
                const resp = await handleAssignPTRAction(selectedFloatingIPs[ip],project_token)
                if (resp !== null) {
                    err = [...err, resp]
                }
            }
        }
        setCurrentAction("")
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const getFloatingIPsActionsList = () => {
        let ptr_actions = []
        let new_action = {}

        new_action["value"] = "set_ptr"
        new_action["action"] = handleSetPTRDialogOpen
        new_action["keyword"] = "ptrSetActionLabel"
        new_action["button_text"] = "applyButtonTitleText"
        ptr_actions.push({...new_action})
        
        return ptr_actions
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    useEffect(() => {
        if (fetchDataRequired) {
        (async () => {
            handleLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${dnsServiceDomain}/${dnsServiceVersion}/${reverseFloatingIPsUrl}`
                const method = "GET"
                const response = await dnsDesignateRequest({url:url, method:method, token: project_token})
                if (response.status_code === zonesUrlResponses.get.success_response.status_code) {
                    setPtrRecords(response.data.floatingips)
                } 
            }
        })();
        setTimeout(()=>{handleLoading(false)},600)
        }
        setFetchDataRequired(false)
    },[
        dnsServiceDomain, 
        dnsServiceVersion, 
        reverseFloatingIPsUrl,
        fetchDataRequired,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${floatingIPsUrl}`
                const method = "GET"
                const fip_response = await openstackRequest({url:url, method:method, token: project_token})
                if (fip_response.status_code === floatingIPUrlResponses.get.success_response.status_code) {
                    const ptr_records = ptrRecords.map(item => item.address)
                    const data = fip_response.data.floatingips.filter(item => !ptr_records.includes(item.floating_ip_address))
                    setFloatingIPsData(data)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        floatingIPsUrl,
        ptrRecords,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"
            const projects_response = await openstackRequest({url:url, method:method})
            
            if ( projects_response.status_code === floatingIPUrlResponses.get.success_response.status_code) {
                setProjectsList(projects_response.data.projects)
            }
        })()
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        const new_data = floatingIPsData.map(item => {
            let new_item = {...item}
            const project = projectsList.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            }
            return new_item
        })
        setFloatingIPs(new_data)
    },[
        floatingIPsData,
        projectsList
    ]);

    return (
        <Box>
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading && <FloatingIPsTableV2 
                floatingIPs={floatingIPs}
                setFloatingIPs={setFloatingIPs}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getFloatingIPsActionsList()}
            />}
            <CustomDialog
                open={assignPTRDialogOpen}
                onClose={handleSetPTRDialogClose}
                dialogTitle={{
                    title: defaultTexts.ptrSetActionLabel, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onPTRAssign, 
                    sx: {color: 'primary.main'}}]}
            >
                {getDataForm(
                    ptrAssignDataForm,
                    {},
                    selectedIPsData,
                    handlePTRAssignDataChange
                )}
            </CustomDialog>
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {success && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[success.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[success.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
        </Box>
    )
}

export default FloatingIPsWrapperV2;