import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Constants from '../../config/constants';
import NotPurchasedService from '../_common/NotPurchasedService';
import CustomDrawer from '../_common/CustomDrawer';
import BackupAutomationContent from './backupAutomationContent';

const BackupAutomationWrapper = (props) => {
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const defaultProject = useSelector(state => state.profile.defaultAdminProject)
    const navigate = useNavigate();

    if (!isAuthenticated) {
        navigate('/');
    }
    const purchasedServices = useSelector(state => 
        state.openstack.purchasedServicesAll);
    
    const purchasedService = purchasedServices.filter(
        item => item.service === Constants.config_service_backup_name)
    if (purchasedService.length === 0) {
        return (
            <CustomDrawer>
                <NotPurchasedService service={defaultTexts.backupServiceNavLink} />
            </CustomDrawer>
        )
    }

    if (purchasedService[0].config_params.visible_to_service.includes(
        Constants.config_service_custom_admin_ui)) {
            return (
                <CustomDrawer>
                    <BackupAutomationContent 
                        serviceTitle={`${defaultTexts.backupServiceNavLink} (${defaultTexts.projectFormFieldLabel}: ${defaultProject.name.toUpperCase()})`} 
                        serviceName={Constants.config_service_backup_name} 
                        navigate={navigate}
                    />
                </CustomDrawer>
            )
        }
};

export default BackupAutomationWrapper;