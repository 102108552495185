import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dnsDesignateRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { tsigkeysUrl as tsigkeysUrlResponses } from 
'../../../../../_api_responses/openstack/dns/tsigkeys/v2';
import {tsigkeysFilterMenu, tsigkeyDataForm } from 
'../../../../../_data/openstack/dns/tsigkeys/v2';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { dnsDesignateConstants } from 
'../../../../../config/openStackConstants';
import TsigkeysSubheaderV2 from './tsigkeysSubheaderV2';
import TsigkeysTableV2 from './tsigkeysTableV2';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';

const SERVICE_NAME = openStackServices.dnsService

const TsigkeysWrapperV2 = (props) => {
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState()
    const [success, setSuccess] = useState()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)

    const filterMenu = tsigkeysFilterMenu
    const [tsigkeysData, setTsigkeysData] = useState([]);
    const [tsigkeys, setTsigkeys] = useState([]);
    const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [filterQueryParams, setFilterQueryParams] = useState("")
    const [sortQueryParams, setSortQueryParams] = useState("");
    const [currentAction, setCurrentAction] = useState("");
    const [selectedTsigkeys, setSelectedTsigkeys] = useState([])
    const [tsigkeyDeleteConfirmDialogOpen, setTsigkeyDeleteConfirmDialogOpen] = useState(false);

    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [fetchDataRequired, setFetchDataRequired] = useState(true);
    const [updateTsigkeyDialogOpen, setUpdateTsigkeyDialogOpen] = useState(false)
    const [tsigkeyUpdateData, setTsigkeyUpdateData] = useState({})

    const [zones, setZones] = useState([]);
    const [pools, setPools] = useState([]);

    const dnsServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const dnsServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const tsigkeysUrl = useSelector(
        state => state.dnsDesignate.dnsDesignateApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === dnsDesignateConstants.tsigkeysUrl)[0].url)
    const zonesUrl = useSelector(
        state => state.dnsDesignate.dnsDesignateApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === dnsDesignateConstants.zonesUrl)[0].url)
    const poolsUrl = useSelector(
        state => state.dnsDesignate.dnsDesignateApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === dnsDesignateConstants.poolsUrl)[0].url)


    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    const handleFilteredSearch = () => {
        if (selectedFilter && selectedFilterValue) {
            setFilterQueryParams(`${selectedFilter}=${selectedFilterValue}`)
        } else {
            setFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleTsigkeySorting = (field, direction) => {
        let new_query = `&sort_key=${field}&sort_dir=${direction}`
        setSortQueryParams(new_query)
        handleDataFetch()
    }

    const handleFilterReset = () => {
        setSelectedFilter(filterMenu[0].value)
        setSelectedFilterValue("")
        setFilterQueryParams("")
        handleDataFetch()
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccess(null);
        setSuccessDialogOpen(false);
    }

    const onTsigkeyDeleteConfirm = (n_list) => {
        const selected_n_list = tsigkeysData.filter(n => 
            n_list.includes(n.id))
        setSelectedTsigkeys([...selected_n_list])
        setTsigkeyDeleteConfirmDialogOpen(true)
    }

    const handleTsigkeyDeleteConfirmDialogClose = () => {
        setTsigkeyDeleteConfirmDialogOpen(false)
    }

    const handleTsigkeyUpdateDataChange = (event,field_key) => {
        let new_form_data = {...tsigkeyUpdateData}
        if (tsigkeyDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (tsigkeyDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setTsigkeyUpdateData(new_form_data)
    }

    const handleUpdateTsigkeyDialogOpen = (n_list) => {
        const selected_n_list = tsigkeysData.filter(n => 
            n_list.includes(n.id))
        setSelectedTsigkeys([...selected_n_list])
        setUpdateTsigkeyDialogOpen(true)
    }

    const handleUpdateTsigkeyDialogClose = () => {
        setUpdateTsigkeyDialogOpen(false)
    }

    const getDataForm = (form,form_options,data,onDataChange) => {
        let data_form = [...form]
        if (data.scope === "POOL") {
            data_form = data_form.map(f => {
                if (f.field_key === "resource_id") {
                    const pools_data = pools.map(item => {
                        return {keyword: item.name, value: item.id, default: false}
                    })
                    f.items = pools_data
                }
                return f
            })
        } else if (data.scope === "ZONE") {
            data_form = data_form.map(f => {
                if (f.field_key === "resource_id") {
                    const pools_data = zones.map(item => {
                        return {keyword: item.name, value: item.id, default: false}
                    })
                    f.items = pools_data
                }
                return f
            })
        } else {
            data_form = data_form.map(f => {
                if (f.field_key === "resource_id") {
                    f.items = []
                }
                return f
            })
        }
        return (
            <FormGroup>
                {data_form.map(field => {
                    let new_field = {...field}
                    delete new_field.label
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {
                                ...form_options[field.field_key],
                                ...new_field,
                                item_titles: defaultTexts
                            }
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleUpdateTsigkeyAction = async (tsigkey_id,token) => {
        const url = `${dnsServiceDomain}/${dnsServiceVersion}/${tsigkeysUrl}/${tsigkey_id}`
        const method = "PATCH"
        const response = await dnsDesignateRequest({url:url, method:method, data: tsigkeyUpdateData, token: token})
        if (response.status_code === tsigkeysUrlResponses.patch.success_response.status_code) {
            return null
        } else {
            return response.error
        }
    }

    const onTsigkeyUpdate = async (tsigkey_list) => {
        handleUpdateTsigkeyDialogClose()
        let err = []
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let n in tsigkey_list) {
                const resp = await handleUpdateTsigkeyAction(tsigkey_list[n].id,project_token)
                if (resp !== null) {
                    err = [...err, resp]
                }
            }
        }
        setCurrentAction("")
        setTsigkeyUpdateData({})
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleDeleteTsigkeyAction = async (tsigkey_id,token) => {
        const url = `${dnsServiceDomain}/${dnsServiceVersion}/${tsigkeysUrl}/${tsigkey_id}`
        const method = "DELETE"
        const response = await dnsDesignateRequest({url:url, method:method, token: token})
        if (response.status_code === tsigkeysUrlResponses.delete.success_response.status_code) {
            return null
        } else {
            return response.error
        }
    }

    const onTsigkeyDelete = async (tsigkey_list) => {
        handleTsigkeyDeleteConfirmDialogClose()
        let err = []
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let n in tsigkey_list) {
                const resp = await handleDeleteTsigkeyAction(tsigkey_list[n].id,project_token)
                if (resp !== null) {
                    err = [...err, resp]
                }
            }
        }
        setCurrentAction("")
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const getTsigkeysActionsList = () => {
        let tsigkey_actions = []
        let new_action = {}

        new_action["value"] = "tsigkey_delete"
        new_action["action"] = onTsigkeyDeleteConfirm
        new_action["keyword"] = "tsigkeyDeleteActionLabel"
        new_action["button_text"] = "applyButtonTitleText"
        tsigkey_actions.push({...new_action})

        new_action = {}

        new_action["value"] = "tsigkey_update"
        new_action["action"] = handleUpdateTsigkeyDialogOpen
        new_action["keyword"] = "tsigkeyUpdateActionLabel"
        new_action["button_text"] = "applyButtonTitleText"
        tsigkey_actions.push({...new_action})
        
        return tsigkey_actions
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    useEffect(() => {
        setFetchDataRequired(true)
    },[filterQueryParams,sortQueryParams]);

    useEffect(() => {
        if (fetchDataRequired) {
        (async () => {
            handleLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${dnsServiceDomain}/${dnsServiceVersion}/${tsigkeysUrl}/?${filterQueryParams}${sortQueryParams}`
                const method = "GET"
                const response = await dnsDesignateRequest({url:url, method:method, token: project_token})
                if (response.status_code === tsigkeysUrlResponses.get.success_response.status_code) {
                    setTsigkeysData(response.data.tsigkeys)
                } 
            }
        })();
        }
        setFetchDataRequired(false)
        setTimeout(()=>{handleLoading(false)},1000)
    },[
        dnsServiceDomain, 
        dnsServiceVersion, 
        tsigkeysUrl,
        filterQueryParams,
        fetchDataRequired,
        sortQueryParams,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${dnsServiceDomain}/${dnsServiceVersion}/${zonesUrl}`
                const method = "GET"
                const response = await dnsDesignateRequest({url:url, method:method, token: project_token})
                if (response.status_code === tsigkeysUrlResponses.get.success_response.status_code) {
                    setZones(response.data.zones)
                } 
            }
        })();
    },[
        dnsServiceDomain, 
        dnsServiceVersion, 
        zonesUrl,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${dnsServiceDomain}/${dnsServiceVersion}/${poolsUrl}`
                const method = "GET"
                const response = await dnsDesignateRequest({url:url, method:method, token: project_token})
                if (response.status_code === tsigkeysUrlResponses.get.success_response.status_code) {
                    setPools(response.data.pools)
                } 
            }
        })();
    },[
        dnsServiceDomain, 
        dnsServiceVersion, 
        poolsUrl,
        defaultAdminProject
    ]);

    useEffect(() => {
        let formatted_data = tsigkeysData.map(item => {
            let new_item = {...item}
            if (item.scope === "POOL") {
                new_item.resource_id = pools.filter(p => p.id === item.resource_id).length > 0 ? pools.filter(p => p.id === item.resource_id)[0].name : item.resource_id
            } else if (item.scope === "ZONE") {
                new_item.resource_id = zones.filter(p => p.id === item.resource_id).length > 0 ? zones.filter(p => p.id === item.resource_id)[0].name : item.resource_id
            }
            return new_item
        })
        setTsigkeys(formatted_data)
    },[
        tsigkeysData,
        pools,
        zones
    ])

    return (
        <Box>
            <TsigkeysSubheaderV2 
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                selectedFilterValue={selectedFilterValue}
                setSelectedFilterValue={setSelectedFilterValue}
                filterMenu={filterMenu}
                handleFilteredSearch={handleFilteredSearch}
                handleFilterReset={handleFilterReset}
                handleFetchData={handleDataFetch}
                defaultTexts={defaultTexts}
                zones={zones}
                pools={pools}
            />
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading && <TsigkeysTableV2 
                tsigkeysData={tsigkeys}
                setTsigkeysData={setTsigkeys}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getTsigkeysActionsList()}
                selfSorting={true}
                sortHandler={handleTsigkeySorting}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {success && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[success.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[success.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            <CustomDialog
                open={tsigkeyDeleteConfirmDialogOpen}
                onClose={handleTsigkeyDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.tsigkeyDeleteConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.tsigkeyDeleteConfirmText}: [${selectedTsigkeys.map(v => v.name).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: () => onTsigkeyDelete(selectedTsigkeys), 
                    sx: {color: 'primary.main'}}]}
            />
            <CustomDialog
                open={updateTsigkeyDialogOpen}
                onClose={handleUpdateTsigkeyDialogClose}
                dialogTitle={{
                    title: defaultTexts.updateTsigkeyActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: () => onTsigkeyUpdate(selectedTsigkeys),
                    sx: {color: 'primary.main'}}]}
            >
                {getDataForm(
                    tsigkeyDataForm,
                    {},
                    tsigkeyUpdateData,
                    handleTsigkeyUpdateDataChange
                )}
            </CustomDialog>
        </Box>
    )
}

export default TsigkeysWrapperV2;