export const backupsDRTexts = {
    submenuBackups: "Backups",
    submenuBackupClients: "Backup Clients",
    submenuBackupJobs: "Backup Jobs",
    submenuActions: "Actions",
    submenuSessions: "Sessions",
    submenuBackupPolicies: "Backup Policies",
    backupServiceNavLink: "Backup Automation",
    backupServiceEnableDescriptionText: "It seems that your backup automation service is not enabled yet. In order to enable the service, we need to generate your application credentials, which will be used by backup automation service to fetch data from your cloud provider, and start the backup process automatically according to your backup policies. This application credentials will be limited only to those resources, which are required to get servers/volumes information and create or delete backups in Block Storage service. No other data or actions will be allowed to this application credentials. You may regenerate application credentials at any time in the future.",
    backupServiceEnableButtonText: "Enable Service",
    addBackupPolicyDialogTitle: "Create New Backup Policy",
    addBackupPolicyDialogDescriptionText: "You can create as many backup policies as required. All backup policies will be tracked and executed according to policy scheduling. Backup Automation service will create new backup item in OpenStack Block Storage and if required by policy will remove all unnecessary old backup items.",
    policyNameFormFieldLabel: "Policy Name",
    enabledFormFieldLabel: "Enabled",
    customCronStringFormFieldLabel: "Custom Cron String",
    cronStringFormFieldLabel: "Cron String",
    frequencyFormFieldLabel: "Frequency",
    dailyFormChoiceLabel: "Daily",
    weeklyFormChoiceLabel: "Weekly",
    monthlyFormChoiceLabel: "Monthly",
    yearlyFormChoiceLabel: "Yearly",
    everyFormFieldLabel: "Every",
    onDateFormFieldLabel: "On",
    onMonthFormFieldLabel: "On",
    atTheFormFieldLabel: "At the",
    startingAtFormFieldLabel: "Starting at",
    retentionTypeFormFieldLabel: "Retention Type",
    countFormFieldLabel: "Count",
    firstDayFormChoiceLabel: "First Day",
    lastDayFormChoiceLabel: "Last Day",
    keepFormFieldLabel: "Keep",
    retentionTimeFormFieldLabel: "Retention Time",
    targetTypeFormFieldLabel: "Target Object",
    policyOperatorFormFieldLabel: "Rules Applying Operator",
    dayFormFieldLabel: "Day(s)",
    monthFormFieldLabel: "Month(s)",
    yearFormFieldLabel: "Year(s)",
    targetMatchingPolicyFormFieldLabel: "Target Matching Rule",
    targetMatchingRulesFormFieldLabel: "Target Matching Rules",
    addTargetMatchingPolicyFormFieldLabel: "Add Target Matching Rule",
    targetFieldFormFieldLabel: "Target Parameter",
    matchingRuleFormFieldLabel: "Matching Rule",
    equalsFormFieldLabel: "Equals",
    containsFormFieldLabel: "Contains",
    startsWithFormFieldLabel: "Starts with",
    endsWithFormFieldLabel: "Ends with",
    regexFormFieldLabel: "Regex",
    mondayFormFieldLabel: "Mon",
    tuesdayFormFieldLabel: "Tue",
    wednesdayFormFieldLabel: "Wed",
    thursdayFormFieldLabel: "Thu",
    fridayFormFieldLabel: "Fri",
    saturdayFormFieldLabel: "Sat",
    sundayFormFieldLabel: "Sun",
    retentionFormTitle: "Retention",
    policyFieldKeyHelpText: "Note, that only 'Value' field will be checked according to selected matching rule. 'Key' field must be equal to actual metadata key.",
    invalidCronStringErrorText: "Invalid cron string!",
    invalidRetentionCountErrorText: "Retention count must be greater than 0.",
    emptyWeekdaysListErrorText: "At least, one weekday must be selected!",
    nextRunFormFieldLabel: "Next Run (UTC)",
    retentionCountFormFieldLabel: "Retention Count",
    backupPolicyAddSuccessTitle: "Backup Policy Added Successfully",
    backupPolicyAddSuccessMessage: "Backup Policy was successfully created. You can see details in backup policies table.",
    backupPolicyAddErrorTitle: "Backup Policy Create Error",
    backupPolicyAddErrorMessage: "Failed to create backup policy. See details below.",
    backupPolicyDeleteActionTitle: "Delete Backup Policies",
    backupPolicyDeleteConfirmTitle: "Confirm Backup Policy Delete",
    backupPolicyDeleteConfirmText: "Are you sure, you want to delete selected backup policies?",
    submenuMatchingRules: "Matching Rules",
    fieldFormFieldLabel: "Field",
    backupPolicyMatchingRulesTitleText: "Resource Matching Rules",
    weekdaysFormFieldLabel: "Weekdays",
    janMonthFormFieldLabel: "Jan",
    febMonthFormFieldLabel: "Feb",
    marMonthFormFieldLabel: "Mar",
    AprMonthFormFieldLabel: "Apr",
    mayMonthFormFieldLabel: "May",
    junMonthFormFieldLabel: "Jun",
    julMonthFormFieldLabel: "Jul",
    augMonthFormFieldLabel: "Aug",
    sepMonthFormFieldLabel: "Sep",
    octMonthFormFieldLabel: "Oct",
    novMonthFormFieldLabel: "Nov",
    decMonthFormFieldLabel: "Dec",
    backupPolicyUpdateActionTitle: "Update policy",
    backupPolicyRetentionUpdateActionTitle: "Update policy retention",
    backupPolicySchedulerUpdateActionTitle: "Update policy scheduling",
    backupPolicyRulesUpdateActionTitle: "Update matching rules",
    backupPolicyUpdateSuccessTitle: "Backup Policy Updated Successfully",
    backupPolicyUpdateSuccessMessage: "Backup Policy was successfully updated. You can see details in backup policy card.",
    backupPolicyUpdateErrorTitle: "Backup Policy Update Failed",
    backupPolicyUpdateErrorMessage: "Failed to update backup policy. See details below.",
    updateBackupPolicyActionTitle: "Update Backup Policy",
    updateBackupPolicyRetentionActionTitle: "Update Policy Retention Settings",
    updateBackupPolicySchedulerActionTitle: "Update Policy Scheduling Settings",
    updateBackupPolicyRulesActionTitle: "Update Policy Matching Rules",
    lastDayFormFieldLabel: "Last Day",
    updateNotificationSettingsActionTitle: "Update Notification Settings",
    backupAutomationNotificationSettingsTitle: "Notification Settings",
    updateNotificationSettingsActionMessage: "Please, provide comma separated email addresses, which should receive email notifications about failed backups and/or other notifications from Backup Automation System. Also, you may enable/disable email notifications by checking corresponding checkbox below.",
    emailsFormFieldLabel: "Email List"
}