import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const BackupAutomationLoading = (props) => {
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    
    const navigate = useNavigate();

    if (!isAuthenticated) {
        navigate('/');
    }

    return (
        <Stack sx={{mt: 5}}>
            <Skeleton width="100%" height={40}>
                <Typography>.</Typography>
            </Skeleton>
            <Skeleton width="100%" height={40}>
                <Typography>.</Typography>
            </Skeleton>
            <Skeleton width="100%" height={40}>
                <Typography>.</Typography>
            </Skeleton>
            <Skeleton width="100%" height={40}>
                <Typography>.</Typography>
            </Skeleton>
            <Skeleton width="100%" height={40}>
                <Typography>.</Typography>
            </Skeleton>
            <Skeleton width="100%" height={40}>
                <Typography>.</Typography>
            </Skeleton>
            <Skeleton width="100%" height={40}>
                <Typography>.</Typography>
            </Skeleton>
            <Skeleton width="100%" height={40}>
                <Typography>.</Typography>
            </Skeleton>
        </Stack>
    )
};

export default BackupAutomationLoading;