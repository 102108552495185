export const blacklistDataSchema = [
    {
        field_key: "pattern",
        is_id_field: false,
        field_type: "string", 
        label: "patternFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "created_at",
        is_id_field: false,
        field_type: "datetime", 
        label: "createTimeHeaderLabel"
    },
    {
        field_key: "updated_at",
        is_id_field: false,
        field_type: "datetime", 
        label: "updatedAtFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const blacklistDataForm = [
    {
        field_key: "pattern",
        field_type: "string", 
        required: true, 
        label: "patternFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false, 
        label: "descriptionFormFieldLabel"
    }
]

export const blacklistsFilterMenu = [
    {
        keyword: "patternFormFieldLabel", 
        value: "pattern", 
        type: "text"
    }
]