export const servicesSuspendPolicy = {
    compute_nova: [
        {keyword: "billingActionKeepChoiceValue", value: "keep", default: true},
        {keyword: "billingActionSuspendChoiceValue", value: "suspend", default: false},
    ],
    volumes_cinder: [
        {keyword: "billingActionKeepChoiceValue", value: "keep", default: true},
    ],
    images_glance: [
        {keyword: "billingActionKeepChoiceValue", value: "keep", default: true},
    ],
    networks_neutron: [
        {keyword: "billingActionKeepChoiceValue", value: "keep", default: true},
        {keyword: "billingActionDisableChoiceValue", value: "delete", default: false},
    ],
    identity_keystone: [
        {keyword: "billingActionKeepChoiceValue", value: "keep", default: true},
    ],
    dns_designate: [
        {keyword: "billingActionKeepChoiceValue", value: "keep", default: true},
        {keyword: "billingActionDeleteChoiceValue", value: "delete", default: false},
    ]
}

export const servicesDeletePolicy = {
    compute_nova: [
        {keyword: "billingActionKeepChoiceValue", value: "keep", default: true},
        {keyword: "billingActionShelveChoiceValue", value: "shelve", default: false},
        {keyword: "billingActionDeleteChoiceValue", value: "delete", default: false}
    ],
    volumes_cinder: [
        {keyword: "billingActionKeepChoiceValue", value: "keep", default: true},
        {keyword: "billingActionDeleteChoiceValue", value: "delete", default: false},
    ],
    images_glance: [
        {keyword: "billingActionKeepChoiceValue", value: "keep", default: true},
        {keyword: "billingActionDeleteChoiceValue", value: "delete", default: false},
    ],
    networks_neutron: [
        {keyword: "billingActionKeepChoiceValue", value: "keep", default: true},
        {keyword: "billingActionDisableChoiceValue", value: "delete", default: false},
    ],
    identity_keystone: [
        {keyword: "billingActionKeepChoiceValue", value: "keep", default: true},
    ],
    dns_designate: [
        {keyword: "billingActionKeepChoiceValue", value: "keep", default: true},
        {keyword: "billingActionDeleteChoiceValue", value: "delete", default: false},
    ]
}