import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { RxReset } from "react-icons/rx";
import { IoSearch } from "react-icons/io5";
import Stack from "@mui/material/Stack";
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CustomDrawer from '../../_common/CustomDrawer';
import Constants from '../../../config/constants'
import CustomCard from '../../_common/CustomCard';
import billingRequest from '../../../_network/billing_request';
import { billingServiceURLs } from '../../../_network/apiUrls';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CustomText from '../../_common/CustomText';
import CustomSelectField from 
'../../_common/_form_fields/CustomSelectField';
import { regionsUrl as regionsUrlResponses } from 
'../../../_api_responses/openstack/identity/regions/v3';
import { 
    openStackServices,
    openStackResourceLabels
 } from '../../../config/openStackConstants';
import { identityKeystonConstants } from 
'../../../config/openStackConstants';
import { openstackRequest } from 
'../../../_network/openstack_request';
import BillingPricesTable from './billingPricesTable';
import BillingNewResources from './billingNewResources';
import BillingDeletedResources from './billingDeletedResources';
import CustomTableSkeleton from '../../_common/CustomTableSkeleton';

const IDENTITY_SERVICE_NAME = openStackServices.identityService
const drawerWidth = Constants.drawerWidth;

const innerCardStyle = {
    borderBottom: 0.5,
    borderBottomColor: "text.secondary",
    p: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}

const textStyle = {
    fontSize: {xs: 18, lg: 20},
    fontWeight: "500",
    color: "secondary.main"
}

const BillingPricesWrapper = (props) => {
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const drawerOpen = useSelector(state => state.drawer.drawerOpened);
    const navigate = useNavigate();

    if (!isAuthenticated) {
        navigate('/');
    }
    const [isLoading, setIsLoading] = useState(true)
    const [billingPrices, setBillingPrices] = useState([]);
    const [billingPricesList, setBillingPricesList] = useState([]);
    const [regions, setRegions] = useState([]);
    const [regionFilter, setRegionFilter] = useState("")
    const [services, setServices] = useState([]);
    const [serviceFilter, setServiceFilter] = useState("")
    const [resources, setResources] = useState([]);
    const [resourceFilter, setResourceFilter] = useState("")
    const [activeTab, setActiveTab] = useState("configured_prices")
    const [newResources, setNewResources] = useState([])
    const [deletedResources, setDeletedResources] = useState([])
    const [billingConfigs, setBillingConfigs] = useState({})
    const [dataFetchRequired, setDataFetchRequired] = useState(true)
    const [filterQueryParams, setFilterQueryParams] = useState("")
   
    const theme = useTheme();

    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const regionsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.regionsUrl)[0].url)

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleActiveTabChange = (event, newValue) => {
        setActiveTab(newValue);
    }

    const handleDataFetch = () => {
        setDataFetchRequired(true)
    }

    const handleFilterQueryParamsChange = () => {
        let new_filter_query_params = ""
        if (serviceFilter.length > 0 && serviceFilter !== "all") {
            new_filter_query_params = new_filter_query_params + `service_name=${serviceFilter}&`
        }
        if (regionFilter.length > 0 && regionFilter !== "all") {
            new_filter_query_params = new_filter_query_params + `region_name=${regionFilter}&`
        }
        if (resourceFilter.length > 0 && resourceFilter !== "all") {
            new_filter_query_params = new_filter_query_params + `resource_name=${resourceFilter}&`
        }
        setFilterQueryParams(new_filter_query_params)
        setDataFetchRequired(true)
    }

    const handleFilterQueryParamsReset = () => {
        setFilterQueryParams("")
        setRegionFilter("")
        setServiceFilter("")
        setResourceFilter("")
        setDataFetchRequired(true)
    }

    useEffect(() => {
        if (dataFetchRequired) {
            handleLoading(true)
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingPricesEP}?${filterQueryParams}`
        let request_data = {
            url: url,
            method: "GET"
        }
        billingRequest(request_data).then((response) => {
            if (response.status_code === 200) {
                let data = []
                const deleted_resources = deletedResources.map(res => res.id)
                const filtered_data = response.data.filter(item => !deleted_resources.includes(item.id))
                const service_list = Object.groupBy(filtered_data, ({service_name}) => service_name)
                const service_names = Object.keys(service_list)
                for (let s in service_names) {
                    let new_item = {}
                    new_item["service_name"] = service_names[s]
                    new_item["regions"] = []
                    const region_list = Object.groupBy(service_list[service_names[s]], ({region_name}) => region_name)
                    const region_names = Object.keys(region_list)
                    for (let r in region_names) {
                        let new_reg_item = {}
                        new_reg_item["region_name"] = region_names[r]
                        new_reg_item["resources"] = []
                        const resource_list = Object.groupBy(region_list[region_names[r]], ({resource_name}) => resource_name)
                        const resource_names = Object.keys(resource_list)
                        for (let rs in resource_names) {
                            let new_res_item = {}
                            new_res_item["resource_name"] = resource_names[rs]
                            new_res_item["resource_items"] = []
                            if (resource_list[resource_names[rs]].length > 1 ||
                                resource_list[resource_names[rs]][0].resource_id !== null
                            ) {
                                const sortFunc = (a,b) => {
                                    return a.price - b.price
                                }
                                new_res_item["resource_items"] = resource_list[resource_names[rs]].sort(sortFunc)
                                new_res_item["currency"] = Object.keys(billingConfigs).includes("currency") ? billingConfigs.currency.name : ""
                            } else {
                                const currency = Object.keys(billingConfigs).includes("currency") ? billingConfigs.currency.name : ""
                                new_res_item = {...new_res_item, ...resource_list[resource_names[rs]][0], currency: currency}

                            }
                            new_reg_item["resources"].push(new_res_item)
                        }
                        new_item["regions"].push(new_reg_item)
                    }
                    data.push(new_item)
                }
                setBillingPrices(data)
                setBillingPricesList(filtered_data)
            }
            setDataFetchRequired(false)
            setTimeout(() => handleLoading(false), 300)
        }).catch((err) => {
            setTimeout(() => handleLoading(false), 300)
            setDataFetchRequired(false)
            console.log(err)
    })
    }
    },[
        dataFetchRequired, 
        billingConfigs,
        filterQueryParams,
        deletedResources
    ]);

    useEffect(() => {
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingConfigsEP}`
        let request_data = {
            url: url,
            method: "GET"
        }
        billingRequest(request_data).then((response) => {
            if (response.status_code === 200) {
                setBillingConfigs(response.data)
            }
        }).catch((err) => {
            console.log(err)
    })
        
    },[]);


    useEffect(() => {
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingPricesEP}-diff`
        let request_data = {
            url: url,
            method: "GET"
        }
        billingRequest(request_data).then((response) => {
            if (response.status_code === 200) {
                const new_flavors = response.data.new_flavors.map(item => {
                    return {...item, resource_name: "instance", service_name: openStackServices.computeService}
                })
                const new_volume_types = response.data.new_volume_types.map(item => {
                    return {...item, resource_name: "volume", service_name: openStackServices.volumeService}
                })
                const new_resources = [...new_flavors, ...new_volume_types]
                setNewResources(new_resources)
                const deleted_resources = [...response.data.deleted_flavors, ...response.data.deleted_volume_types]
                setDeletedResources(deleted_resources)
                if (deleted_resources.length === 0 && activeTab === "deleted_resources") {
                    setActiveTab("configured_prices")
                }
            }
        }).catch((err) => {
            console.log(err)
    })
    },[
        billingPricesList,
        activeTab
    ]);

    useEffect(() => {
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingSupportedServicesEP}`
            let request_data = {
                url: url,
                method: "GET"
            }
            billingRequest(request_data).then((response) => {
                if (response.status_code === 200) {
                    const supported_services = response.data.map(service => {
                        return {keyword: service.display_name, value: service.service_name, default: false}
                    })
                    setServices(supported_services)
                }
            }).catch((err) => {
                console.log(err)
            })
    },[defaultTexts]);

    useEffect(() => {
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingSupportedResourcesEP}`
            let request_data = {
                url: url,
                method: "GET"
            }
            billingRequest(request_data).then((response) => {
                if (response.status_code === 200) {
                    const supported_resources = response.data.map(resource => {
                        return {keyword: openStackResourceLabels[resource.resource_name], value: resource.resource_name, default: false}
                    })
                    setResources(supported_resources)
                }
            }).catch((err) => {
                console.log(err)
            })
    },[defaultTexts]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${regionsUrl}`
            const method = "GET"

            const regions_response = await openstackRequest({url:url, method:method})
            if (regions_response.status_code === regionsUrlResponses.get.success_response.status_code) {
                const regions_list = regions_response.data.regions.map(region => {
                    return {keyword: region.id, value: region.id, default: false}
                })
                setRegions(regions_list)
            }
            }
        )();
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        regionsUrl,
        defaultTexts
    ]);

    
    return (
        <CustomDrawer>
            <Tabs
                value={activeTab}
                onChange={handleActiveTabChange}
                textColor="primary"
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                sx={{mt: 2, width: {xs: "75%", sm: "100%"}}}
            >
                <Tab 
                    value="configured_prices" 
                    label={defaultTexts.configuredPricesTabTitle}
                />
                <Tab 
                    value="new_resources"  
                    label={`${defaultTexts.newResourcesTabTitle} (${newResources.length})`} 
                />
                <Tab 
                    value="deleted_resources" 
                    disabled={deletedResources.length === 0} 
                    label={`${defaultTexts.deletedResourcesTabTitle} (${deletedResources.length})`} 
                />
            </Tabs>
            {activeTab === "configured_prices" && <Box>
            <Grid container spacing={2} alignItems="center" 
                sx={{
                    maxWidth: drawerOpen ? 
                    `calc(100vw - ${drawerWidth}px)` : 
                    `calc(100vw - ${110}px)`,
                    mt: 1
                }}>
                <Grid item xs={12} md={4}>
                    <CustomCard 
                        cardWidth='100%'
                        cardHeight={170} 
                        card_sx={{
                            border: theme.palette.mode === "dark" ? 0 : 1, 
                            boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                            backgroundColor: 
                                theme.palette.mode === "dark" ? 
                                undefined :
                                "vLightGray"
                        }}
                        box_sx={{pr: 2}}
                    >
                        <Box sx={{...innerCardStyle}}>
                            <CustomText 
                                sx={{...textStyle}}>
                                {defaultTexts.filterByRegionNoteText}
                            </CustomText>
                        </Box>
                        <CustomSelectField 
                            currentValue={regionFilter}
                            setCurrentValue={setRegionFilter}
                            label={defaultTexts.regionFormFieldLabel}
                            required={false}
                            items={regions}
                            self_item_titles={true}
                            size="small"
                            sx={{my: 3, width: '100%'}}
                        />
                    </CustomCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomCard 
                        cardWidth='100%'
                        cardHeight={170} 
                        card_sx={{
                            border: theme.palette.mode === "dark" ? 0 : 1, 
                            boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                            backgroundColor: 
                                theme.palette.mode === "dark" ? 
                                undefined :
                                "vLightGray"
                        }}
                        box_sx={{pr: 2}}
                    >
                        <Box sx={{...innerCardStyle}}>
                            <CustomText sx={{...textStyle}}>
                                {defaultTexts.filterByServiceNoteText}
                            </CustomText>
                        </Box>
                        <CustomSelectField 
                            currentValue={serviceFilter}
                            setCurrentValue={setServiceFilter}
                            label={defaultTexts.serviceFormFieldLabel}
                            required={false}
                            items={services}
                            self_item_titles={true}
                            size="small"
                            sx={{my: 3, width: '100%'}}
                        />
                    </CustomCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomCard 
                        cardWidth='100%'
                        cardHeight={170} 
                        card_sx={{
                            border: theme.palette.mode === "dark" ? 0 : 1, 
                            boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                            backgroundColor: 
                                theme.palette.mode === "dark" ? 
                                undefined :
                                "vLightGray"
                        }}
                        box_sx={{pr: 2}}
                    >
                        <Box sx={{...innerCardStyle}}>
                            <CustomText sx={{...textStyle}}>
                                {defaultTexts.filterByResourceNoteText}
                            </CustomText>
                        </Box>
                        <CustomSelectField 
                            currentValue={resourceFilter}
                            setCurrentValue={setResourceFilter}
                            label={defaultTexts.resourceFormFieldLabel}
                            required={false}
                            items={resources}
                            self_item_titles={true}
                            size="small"
                            sx={{my: 3, width: '100%'}}
                        />
                    </CustomCard>
                </Grid>
            </Grid>
            <Stack direction="row" spacing={2} sx={{mt: 2, ml: 2}}>
                <Button 
                    variant="contained" 
                    color="secondary"
                    sx={{width: 120}}
                    onClick={() => handleFilterQueryParamsChange()}
                    startIcon={<IoSearch />}
                >
                    {defaultTexts.searchButtonText}
                </Button>
                {filterQueryParams.length > 0 && 
                    <Tooltip title={defaultTexts.resetFiltersTooltipText}>
                        <IconButton onClick={handleFilterQueryParamsReset}>
                            <RxReset 
                                color={theme.palette.text.primary}
                                fontSize={'large'}
                            />
                        </IconButton>
                    </Tooltip>}
            </Stack>
            {isLoading && <CustomTableSkeleton />}
            {!isLoading && 
                <React.Fragment>
                    {billingPrices.length > 0 ?
                        <BillingPricesTable
                            dataRows={billingPrices}
                            data={billingPricesList}
                            handleDataFetch={handleDataFetch}
                            billingConfigs={billingConfigs}
                        /> :
                        <Paper
                            sx={{
                                border: 1,
                                borderColor: "customBlue",
                                borderRadius: 2,
                                maxWidth: drawerOpen ? 
                                `calc(100vw - ${drawerWidth}px)` : 
                                `calc(100vw - ${110}px)`,
                                height: "60%",
                                alignItems: 'center',
                                justifyContent: 'center',
                                p: 3,
                                mt: 3,
                                display: "flex"
                            }}
                        >
                            <CustomText 
                                size="h5" 
                                sx={{color: "customBlue"}}
                            >
                                {defaultTexts.noDataToDisplayText}
                            </CustomText>
                        </Paper>
                    }
                </React.Fragment>
            }
            </Box>}
            {activeTab === "new_resources" && 
                <Box>
                    <BillingNewResources 
                        newResources={newResources}
                        handleDataFetch={handleDataFetch}
                    />
                </Box>
            }
            {activeTab === "deleted_resources" && 
                <Box>
                    <BillingDeletedResources 
                        deletedResources={deletedResources}
                        billingConfigs={billingConfigs}
                    />
                </Box>
            }
        </CustomDrawer>
    )
}

export default BillingPricesWrapper;