import Constants from '../../../../config/constants';
import { coreServiceURLs } from '../../../../_network/apiUrls';
export const commonTexts = {
    purchaseServiceTitle: `
    <h3 style="color: #d60c64;">Oops! This service is not purchased.</h3>
    <br>
    `,
    purchaseServiceDescription: `
    <p>If you'd like to access admin user interface for given OpenStack service,</p> 
    <p>you must purchase one of our subscriptions covering this service.</p>
    <br>
    <p>Please, visit our website and choose subscription package, which most fits your needs.</p>   
    <br>
    <br>
    <a href="${Constants.core_frontend_domain}/${coreServiceURLs.getSubscriptionPage}" target="_blank">https://flexostack.io</a> 
    `,
    notSupportedServiceVersionNote: "Configured API Version is not supported!",
    selectedRowsLabel: "Selected Rows:",
    rowsPerPageLabel: "Rows per page:",
    totalRowsLabel: "Total Rows",
    deselectAllButtonTooltipText: "Deselect All",
    sortButtonTooltipText: "Sort By",
    addRemoveColumnsButtonTooltipText: "Add/Remove table columns",
    actionsButtonText: "Actions",
    noDataToDisplayText: "No data to display",
    resetAllFiltersTooltipText: "Reset all filters",
    filterOptionsButtonText: "Filter options",
    searchButtonText: "Search",
    filterCustomOptionsDialogTitle: "Customize Filter Options",
    submitButtonText: "Submit",
    successResponseTitle: "Created!",
    successCreateResponseMessage: "Resource was successfully created upon your request.",
    successUpdateTitle: "Updated!",
    successUpdateResponseMessage: "Resource was successfully updated upon your request.",
    successDeleteTitle: "Deleted!",
    successDeleteResponseMessage: "Resource was successfully deleted upon your request.",
    badRequestResponseTitle: "Bad Request!",
    badRequestResponseMessage: "Unfortunately, we could not process your request. It might be due to invalid data provided.",
    forbiddenResponseTitle: "Forbidden!",
    forbiddenResponseMessage: "Unfortunately, you are not allowed to access this data.",
    notFoundResponseTitle: "Not Found!",
    notFoundResponseMessage: "Unfortunately, we could not process your request. It might be due to invalid request URL.",
    conflictResponseTitle: "Conflict!",
    conflictResponseMessage: "Unfortunately, we could not process your request. It might be due to conflict with other service or data.",
    serviceUnavailableResponseTitle: "Service Unavailable!",
    serviceUnavailableResponseMessage: "Unfortunately, we could not process your request. It seems the service is unavailable.",
    unknownResponseTitle: "Unknown Error!",
    unknownResponseMessage: "Unfortunately, we could not process your request.",
    idFormFieldLabel: "ID",
    nameFormFieldLabel: "Name",
    typeFormFieldLabel: "Type",
    urlFormFieldLabel: "URL",
    interfaceFormFieldLabel: "Interface",
    regionFormFieldLabel: "Region",
    usernameFormFieldLabel: "Username",
    descriptionFormFieldLabel: "Description",
    enabledFormFieldLabel: "Enabled",
    secretFormFieldLabel: "Secret",
    unrestrictedFormFieldLabel: "Unrestricted",
    pathFormFieldLabel: "Path",
    serviceFormFieldLabel: "Service",
    methodFormFieldLabel: "Method",
    formValueYes: "Yes",
    formValueNo: "No",
    keyFormFieldLabel: "Key",
    valueFormFieldLabel: "Value",
    optionsFormFieldLabel: "Options",
    tagsFormFieldLabel: "Tags",
    requiredFormFieldError: "This field is required!",
    listFormFieldHelpText: "You can provide more than one tag, separated by comma.",
    domainFormFieldLabel: "Domain",
    parentProjectFormFieldLabel: "Parent",
    projectFormFieldLabel: "Project",
    isDomainFormFieldLabel: "Is Domain",
    defaultProjectFormFieldLabel: "Default Project",
    passwordFormFieldLabel: "Password",
    originalPasswordFormFieldLabel: "Original Password",
    confirmPasswordFormFieldLabel: "Confirm Password",
    passwordMismatchFormFieldError: "Password and confirm password mismatch!",
    doNotAskForPasswordChangeOnFirstUseFormFieldLabel: "Password change on first use",
    passwordNotExpiresFormFieldLabel: "Password never expires",
    doNotLockOutOnFailureAttemptsFormFieldLabel: "Lockout user on failed attemts",
    lockPasswordFromChangeFormFieldLabel: "Lock password from change",
    emailFormFieldLabel: "Email",
    invalidEmailFormFieldError: "Invalid email provided!",
    passwordExpiresAtFormFieldLabel: "Password expires at",
    extraFormFieldLabel: "Extra Fields",
    createTimeFormFieldLabel: "Include create time",
    createTimeHeaderLabel: "Created At",
    parentProjectFormFieldHelpText: "If parent is specified, a new project will be placed in corresponding hierarchy under parent project within the same domain, to which parent belongs.",
    userFormFieldLabel: "User",
    groupFormFieldLabel: "Group",
    roleFormFieldLabel: "Role",
    expiresAtFormFieldLable: "Expires at",
    ruleFormFieldLabel: "Rule",
    expiresAtFormFieldLabel: "Expires At",
    neverFormValue: "Never",
    vcpusFormFieldLabel: "vCPUs",
    ramFormFieldLabel: "RAM (MiB)",
    diskFormFieldLabel: "Disk (GiB)",
    ephemeralDiskFormFieldLabel: "Ephemeral Disk (GiB)",
    disabledFormFieldLabel: "Disabled",
    swapFormFieldLabel: "SWAP (MiB)",
    rxtxFactorFormFieldLabel: "RX/TX Factor",
    isPublicFormFieldLabel: "Is Public",
    accessTypeFormFieldLabel: "Access Type",
    formValuePublic: "Public",
    formValuePrivate: "Private",
    minRamFormFieldLabel: "Min RAM (MB)",
    minDiskFormFieldLabel: "Min Disk (GB)",
    disabledReasonFormFieldLabel: "Disabled Reason",
    hostFormFieldLabel: "Host",
    stateFormFieldLabel: "State",
    statusFormFieldLabel: "Status",
    updatedAtFormFieldLabel: "Updated At",
    forcedDownFormFieldLabel: "Forced Down",
    zoneFormFieldLabel: "Zone",
    progressFormFieldLabel: "Progress",
    flavorFormFieldLabel: "Flavor",
    accessIPv4FormFieldLabel: "Access IPv4",
    accessIPv6FormFieldLabel: "Access IPv6",
    addressesFormFieldLabel: "Addresses",
    imageFormFieldLabel: "Image",
    hostnameFormFieldLabel: "Hostname",
    availabilityZoneFormFieldLabel: "Availability Zone",
    sshKeyFormFieldLabel: "SSH Key",
    ipv4AddressFormFieldLabel: "IPv4 Address",
    ipv6AddressFormFieldLabel: "IPv6 Address",
    changesSinceFormFieldLabel: "Changes Since",
    filterName: "Name",
    filterEnabled: "Enabled",
    filterChoiceYes: "Yes",
    filterChoiceNo: "No",
    filterID: "ID",
    filterType: "Type",
    filterParentRegionID: "Parent Region ID",
    filterDomain: "Domain",
    filterParentProject: "Parent",
    filterIsDomain: "Is Domain",
    filterTags: "Tags",
    filterAllTags: "All Tags",
    allTagsFilterHelpText: "You may specify more than one tag, separated by comma. Records that contain all of the specified tags will be returned.",
    filterAnyTags: "Any Tags",
    anyTagsFilterHelpText: "You may specify more than one tag, separated by comma. Records that contain any of the specified tags will be returned.",
    filterNotTags: "Not All Tags",
    notTagsFilterHelpText: "You may specify more than one tag, separated by comma. Records that do not contain exactly all of the specified tags will be returned.",
    filterNotAnyTags: "Not Any Tags",
    notAnyTagsFilterHelpText: "You may specify more than one tag, separated by comma. Records that do not contain any one of the specified tags will be returned.",
    multifieldFilterHelpText: "You may combine one or more filters to build more complex filter query.",
    filterPasswordExpiresAt: "Password expires at",
    filterOperator: "Operator",
    filterChoiceLessThan: "Less than",
    filterChoiceGreaterThan: "Greater than",
    filterChoiceLessThanOrEquals: "Less than or equals",
    filterChoiceGreaterThanOrEquals: "Greater than or equals",
    filterChoiceEquals: "Equals",
    filterChoiceNotEquals: "Not equals",
    selectButtonTitleText: "Select",
    actionsDropdownLabelText: "Actions",
    applyButtonTitleText: "Apply",
    adminProjectSelectorTooltipText: "Select default Admin project",
    ramTableHeaderLabel: "RAM",
    diskTableHeaderLabel: "Disk",
    ephemeralTableHeaderLabel: "Ephemeral Disk",
    swapTableHeaderLabel: "SWAP",
    fingerprintFormFieldLabel: "Fingerprint",
    publicKeyFormFieldLabel: "Public Key",
    saveButtonText: "Save",
    editButtonText: "Edit",
    loadingHeader: "Loading",
    customFieldsTitleText: "Custom Fields",
    duplicateFieldsAreNotAllowedErrorText: "Duplicate fields are not allowed",
    catalogHeaderTitle: "Catalog",
    existingFieldsHeaderTitle: "Existing Fields",
    instructionBoxNoteText: "Click each item to get its description here.",
    noDescriptionText: "No Description",
    warningDialogTitle: "Warning",
    addToListButtonTitle: "Add To List",
    noProjectsSelectedNoteText: "No Project Selected",
    regionSelectorTooltipText: "Select Openstack Region",
    customersSupportNavLink: "Customers Support",
    customersNotificationsHeaderTitle: "Customers Notifications",
    issueReportAlertHeaderTitle: "Issue Report Notifications",
    projectRequestAlertHeaderTitle: "Project Request Notifications",
    limitsUpdateAlertHeaderTitle: "Limits Update Notification"
}