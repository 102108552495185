import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { 
    zoneDataSchema,
    zonesSortFields
 } from '../../../../../_data/openstack/dns/zones/v2';
import { setDNSZonesTableConfig } from 
'../../../../../store/reducers/dnsDesignateSlice';


const ZonesTableV2 = (props) => {
    const { zonesData, setZonesData } = props
    const { handleRowSelection, handleCellClick } = props
    const { currentAction } = props
    const { setCurrentAction }  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const { selfSorting, sortHandler } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const zonesTableConfig = useSelector(
        state => state.dnsDesignate.dnsZonesTableConfig)
    const rowsPerPage = useSelector(
        state => state.dnsDesignate.dnsZonesTableConfig.filter(
        versions => versions.api_version === "v2")[0].rows_per_page)
    const zonesTableColumns = useSelector(
        state => state.dnsDesignate.dnsZonesTableConfig.filter(
            versions => versions.api_version === "v2")[0].columns)
    
    const identityField = zonesTableColumns.filter(
        item => item.is_id_field)[0].field_key

    const dispatch = useDispatch();

    const handleZoneTableConfig = (value_list) => {
        let newTableConfig = zoneDataSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = zonesTableConfig.map(version => {
            if (version.api_version === "v2") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setDNSZonesTableConfig({
            dnsZonesTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = zonesTableConfig.map(version => {
            if (version.api_version === "v2") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setDNSZonesTableConfig({
            dnsZonesTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                withCheckbox={true}
                withActions={true}
                withSorting={true}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={zonesTableColumns}
                tableHeadersConfigHandler={handleZoneTableConfig}
                identityField={identityField}
                columns={zoneDataSchema}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={zonesData}
                setDataRows={setZonesData}
                handleRowSelection={handleRowSelection}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
                sortFields={zonesSortFields}
                selfSorting={selfSorting}
                sortHandler={sortHandler}
                handleCellClick={handleCellClick}
            />
        </React.Fragment>
    )
};

export default ZonesTableV2;