import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { 
    ptrRecordDataSchema
 } from '../../../../../_data/openstack/dns/reverse_dns/v2';
import { setDNSPTRsTableConfig } from 
'../../../../../store/reducers/dnsDesignateSlice';


const PTRRecordsTableV2 = (props) => {
    const { ptrRecords, setPTRRecords } = props
    const { handleRowSelection } = props
    const { currentAction } = props
    const { setCurrentAction }  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const ptrTableConfig = useSelector(
        state => state.dnsDesignate.dnsPTRsTableConfig)
    const rowsPerPage = useSelector(
        state => state.dnsDesignate.dnsPTRsTableConfig.filter(
        versions => versions.api_version === "v2")[0].rows_per_page)
    const ptrsTableColumns = useSelector(
        state => state.dnsDesignate.dnsPTRsTableConfig.filter(
            versions => versions.api_version === "v2")[0].columns)
    
    const identityField = ptrsTableColumns.filter(
        item => item.is_id_field)[0].field_key

    const dispatch = useDispatch();

    const handleReverseDNSTableConfig = (value_list) => {
        let newTableConfig = ptrRecordDataSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = ptrTableConfig.map(version => {
            if (version.api_version === "v2") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setDNSPTRsTableConfig({
            dnsPTRsTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = ptrTableConfig.map(version => {
            if (version.api_version === "v2") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setDNSPTRsTableConfig({
            dnsPTRsTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                withCheckbox={true}
                withActions={true}
                withSorting={false}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={ptrsTableColumns}
                tableHeadersConfigHandler={handleReverseDNSTableConfig}
                identityField={identityField}
                columns={ptrRecordDataSchema}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={ptrRecords}
                setDataRows={setPTRRecords}
                handleRowSelection={handleRowSelection}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
            />
        </React.Fragment>
    )
};

export default PTRRecordsTableV2;