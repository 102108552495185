import { 
    success_create2_response,
    success_delete_response,
    success_update_response,
    bad_request_response,
    forbidden_response,
    not_found_response,
    conflict_response,
    service_unavailable,
    unknown_response
 } from '../common_v2';


export const blacklistsUrl = {
    post: {
        success_response: {
            ...success_create2_response, 
            response_message: "blacklistCreateSuccessResponseMessage"
        },
        error_response: [
            bad_request_response,
            forbidden_response,
            not_found_response,
            conflict_response,
            service_unavailable,
            unknown_response
        ]
    },
    get: {
        success_response: {
            status_code: 200
        },
        error_response: [
            bad_request_response,
            forbidden_response,
            not_found_response,
            service_unavailable,
            unknown_response
        ]
    },
    patch: {
        success_response: {
            ...success_update_response,
            response_message: "blacklistUpdateSuccessResponseMessage"
        },
        error_response: [
            bad_request_response,
            forbidden_response,
            not_found_response,
            service_unavailable,
            unknown_response
        ]
    },
    delete: {
        success_response: {
            ...success_delete_response,
            response_message: "blacklistDeleteSuccessResponseMessage"
        },
        error_response: [
            bad_request_response,
            forbidden_response,
            not_found_response,
            service_unavailable,
            unknown_response
        ]
    }
}