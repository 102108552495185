import React, { useState, useEffect, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import Constants from '../../../config/constants';
import Stack from '@mui/material/Stack';
import AddButton from '../../_common/AddButton';
import FilterButton from '../../_common/FilterButton';
import WrapperBox from '../../_common/WrapperBox';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FormGroup } from '@mui/material';
import { 
    backupPolicyDataForm,
    backupPolicyCustomCronForm,
    backupPolicySchedulerForm,
    backupPolicyRetentionForm, 
    backupPolicyOperatorForm,
    backupPolicyTargetForm } from 
'../../../_data/backup_automation/backup_data';
import { getFormFieldComponent } from 
'../../_common/_form_fields/form_helpers';
import backupRequest from '../../../_network/backup_request';
import CustomDialog from '../../_common/CustomDialog';
import { backupServiceURLs } from '../../../_network/apiUrls';
import PlusButton from '../../_common/PlusButton';
import MinusButton from '../../_common/MinusButton';
import CustomText from '../../_common/CustomText';
import CustomCheckboxField from '../../_common/_form_fields/CustomCheckboxField';
import { isValidCron } from 'cron-validator';

const BackupPoliciesSubheader = forwardRef((props, ref) => {
    const [error, setError] = useState()
    const [successBackupPolicyAdd, setSuccessBackupPolicyAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleDataFetch } = props
    const { filterMenu } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});
    const [targetMatchingPolicies, setTargetMatchingPolicies] = useState([]);
    const [customCronForm, setCustomCronForm] = useState({});
    const [customCronFormOptions, setCustomCronFormOptions] = useState({});
    const [schedulerForm, setSchedulerForm] = useState({});
    const [schedulerFormOptions, setSchedulerFormOptions] = useState({});
    const [weekdaysForm, setWeekdaysForm] = useState([])
    const [retentionForm, setRetentionForm] = useState({})
    const [retentionFormOptions, setRetentionFormOptions] = useState({})
    const [selectedOperator, setSelectedOperator] = useState("or")
    const [emptyWeekDaysError, setEmptyWeekDaysError] = useState(false)

    const weekDays = [
        defaultTexts.sundayFormFieldLabel,
        defaultTexts.mondayFormFieldLabel,
        defaultTexts.tuesdayFormFieldLabel,
        defaultTexts.wednesdayFormFieldLabel,
        defaultTexts.thursdayFormFieldLabel,
        defaultTexts.fridayFormFieldLabel,
        defaultTexts.saturdayFormFieldLabel
    ]

    const validateCronExpression = (expression) => {
        return isValidCron(expression, { alias: true, seconds: false }); // Configure options as needed
    };

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        let updatedCustomCronFormOptions = {...customCronFormOptions}
        let updatedSchedulerFormOptions = {...schedulerFormOptions}
        let updatedRetentionFormOptions = {...retentionFormOptions}
        for (let n in backupPolicyDataForm) {
            if (backupPolicyDataForm[n].required && !formData[backupPolicyDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[backupPolicyDataForm[n].field_key] = {}
                updatedDataFormOptions[backupPolicyDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[backupPolicyDataForm[n].field_key]["errorText"] = defaultTexts[backupPolicyDataForm[n].error_label]
            }
        }
        if (formData.custom_cron) {
            for (let n in backupPolicyCustomCronForm) {
                if (backupPolicyCustomCronForm[n].required && !customCronForm[backupPolicyCustomCronForm[n].field_key]) {
                    validation_faild = false
                    updatedCustomCronFormOptions[backupPolicyCustomCronForm[n].field_key] = {}
                    updatedCustomCronFormOptions[backupPolicyCustomCronForm[n].field_key]["error"] = true
                    updatedCustomCronFormOptions[backupPolicyCustomCronForm[n].field_key]["errorText"] = defaultTexts[backupPolicyCustomCronForm[n].error_label]
                }
            }
            if (customCronForm.cron.length > 0) {
                const valid_cron = validateCronExpression(customCronForm.cron)
                if (!valid_cron) {
                    validation_faild = false
                    updatedCustomCronFormOptions.cron = {}
                    updatedCustomCronFormOptions.cron["error"] = true
                    updatedCustomCronFormOptions.cron["errorText"] = defaultTexts.invalidCronStringErrorText
                }
            }
        }
        if (!formData.custom_cron) {
            if (schedulerForm.frequency === "monthly" && !schedulerForm.dates) {
                validation_faild = false
                updatedSchedulerFormOptions.dates = {}
                updatedSchedulerFormOptions.dates["error"] = true
                updatedSchedulerFormOptions.dates["errorText"] = defaultTexts.requiredFormFieldError
            }
            if (schedulerForm.frequency === "yearly" && !schedulerForm.months) {
                validation_faild = false
                updatedSchedulerFormOptions.months = {}
                updatedSchedulerFormOptions.months["error"] = true
                updatedSchedulerFormOptions.months["errorText"] = defaultTexts.requiredFormFieldError
            }
            if (schedulerForm.frequency === "weekly" && weekdaysForm.length === 0) {
                validation_faild = false
                setEmptyWeekDaysError(true)
            }
        }
        if (retentionForm.retention_count < 1) {
            validation_faild = false
            updatedRetentionFormOptions.count = {}
            updatedRetentionFormOptions.months["error"] = true
            updatedRetentionFormOptions.months["errorText"] = defaultTexts.invalidRetentionCountErrorText
        }
        setFormDataOptions(updatedDataFormOptions)
        setCustomCronFormOptions(updatedCustomCronFormOptions)
        setSchedulerFormOptions(updatedSchedulerFormOptions)
        setRetentionFormOptions(updatedRetentionFormOptions)
        return validation_faild
    }

    const handleFormDataFormatting = () => {
        let updated_data = {}
        updated_data["name"] = formData.name
        updated_data["enabled"] = formData.enabled
        updated_data["type"] = formData.custom_cron ? "custom" : "standard"
        updated_data["retention"] = {}
        updated_data["retention"]["type"] = retentionForm.retention_type
        updated_data["retention"]["count"] = retentionForm.retention_count
        if (retentionForm.retention_type === "age") {
            updated_data["retention"]["time"] = retentionForm.retention_time
        }
        if (!formData.custom_cron) {
            updated_data["params"] = {}
            updated_data["params"]["frequency"] = schedulerForm.frequency
            if (schedulerForm.frequency === "yearly") {
                updated_data["params"]["every"] = parseInt(schedulerForm.months)
                updated_data["params"]["monthday"] = schedulerForm.monthday
            } else if (schedulerForm.frequency === "monthly") {
                updated_data["params"]["every"] = parseInt(schedulerForm.dates)
            } else if (schedulerForm.frequency === "weekly") {
                updated_data["params"]["weekdays"] = weekdaysForm
            } else {
                if (schedulerForm.hours) {
                    updated_data["params"]["every"] = parseInt(schedulerForm.hours)
                }
            }
            updated_data["params"]["starting_at"] = schedulerForm.starting_at
        }
        updated_data["resource"] = {}
        updated_data["resource"]["type"] = formData.target_type
        updated_data["resource"]["policy_type"] = selectedOperator
        updated_data["resource"]["policy_rules"] = targetMatchingPolicies.filter(item =>
            item.value.length > 0 && (item.field === "metadata" ? item.key.length > 0 : true)
        ).map(item => {
            let new_item = {...item}
            if (new_item.field !== "metadata") {
                delete new_item.key
            }
            return new_item
        })
        if (formData.custom_cron) {
            updated_data["cron"] = customCronForm.cron
        } else {
            let cron_parts = ["0","*","*","*","*"]
            cron_parts[1] = schedulerForm.starting_at

            if (schedulerForm.frequency === "daily") {
                if (schedulerForm.hours) {
                    if (schedulerForm.starting_at !== "0") {
                        cron_parts[1] = `${schedulerForm.starting_at}-23/${schedulerForm.hours}`
                    } else {
                        cron_parts[1] = `*/${schedulerForm.hours}`
                    }
                    
                } else {
                    cron_parts[1] = schedulerForm.starting_at
                }
            } else if (schedulerForm.frequency === "weekly") {
                cron_parts[4] = weekdaysForm.join(",")
            } else if (schedulerForm.frequency === "monthly") {
                if (schedulerForm.dates === "32") {
                    cron_parts[2] = "L"
                } else {
                    cron_parts[2] = schedulerForm.dates
                }
                
            } else {
                cron_parts[3] = schedulerForm.months
                if (schedulerForm.monthday === "first") {
                    cron_parts[2] = 1
                } else {
                    cron_parts[2] = "L"
                }
            }
            updated_data["cron"] = cron_parts.join(" ")
        }
        return updated_data
    }

    const onAddBackupPolicy = async () => {
        const validateFormData = handleFormDataValidation()
        if (validateFormData) {
            const formatted_data = handleFormDataFormatting()
            const url = `${Constants.backup_service_domain}/${backupServiceURLs.backupPoliciesEP}`
            const method = "POST"
            
            const response = await backupRequest({
                url:url, 
                method:method, 
                data: formatted_data
            })
            if (response.status_code === 201) {
                handleAddBackupPolicyFormResetAll()
                handleDataFetch()
                setSuccessBackupPolicyAdd({
                    success_title: 'backupPolicyAddSuccessTitle', 
                    success_message: 'backupPolicyAddSuccessMessage'
                })
            } else {
                const errorObject = {
                    error_title: 'backupPolicyAddErrorTitle', 
                    error_message: 'backupPolicyAddErrorMessage',
                    error_details: response.error
                }
                setError(errorObject)
            }
        }

        return validateFormData
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessBackupPolicyAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddBackupPolicyFormResetAll = () => {
        setFormData({})
        setFormDataOptions({})
        setCustomCronForm({})
        setCustomCronFormOptions({})
        setSchedulerForm({})
        setSchedulerFormOptions({})
        setTargetMatchingPolicies([])
        setWeekdaysForm([])
        setRetentionForm({})
        setRetentionFormOptions({})
        setSelectedOperator("or")
        setEmptyWeekDaysError(false)
    }

    const handleFormDataChange = (event,field_key) => {
        setFormDataOptions({})
        let new_form_data = {...formData}
        if (backupPolicyDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (backupPolicyDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }

    const handleRetentionFormDataChange = (event,field_key) => {
        setRetentionFormOptions({})
        let new_form_data = {...retentionForm}
        if (backupPolicyRetentionForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (backupPolicyRetentionForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else if (backupPolicyRetentionForm.filter(
            item => item.field_key === field_key)[0].field_type === "integer") {
            new_form_data[field_key] = parseInt(event.target.value)
        } else {
            new_form_data[field_key] = event.target.value
        }
        setRetentionForm(new_form_data)
    }

    const handleSchedulerFormDataChange = (event,field_key) => {
        setSchedulerFormOptions({})
        let new_form_data = {...schedulerForm}
        if (backupPolicySchedulerForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (backupPolicySchedulerForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setSchedulerForm(new_form_data)
    }

    const handleCustomCronFormDataChange = (event,field_key) => {
        setCustomCronFormOptions({})
        let new_form_data = {...customCronForm}
        new_form_data[field_key] = event.target.value
        setCustomCronForm(new_form_data)
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successBackupPolicyAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in backupPolicyDataForm) {
                if (
                    backupPolicyDataForm[n].field_type === "string" || 
                    backupPolicyDataForm[n].field_type === "select" ||
                    backupPolicyDataForm[n].field_type === "list"
                    ) {
                    new_form_data[backupPolicyDataForm[n].field_key] = backupPolicyDataForm[n].default_value ? 
                    backupPolicyDataForm[n].default_value : ""
                } else if (backupPolicyDataForm[n].field_type === "bool") {
                    new_form_data[backupPolicyDataForm[n].field_key] = backupPolicyDataForm[n].default_value ? 
                    backupPolicyDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[formData]);

    useEffect(() => {
        if (Object.keys(retentionForm).length === 0) {
            let new_form_data = {}
            for (const n in backupPolicyRetentionForm) {
                if (
                    backupPolicyRetentionForm[n].field_type === "string" || 
                    backupPolicyRetentionForm[n].field_type === "select" ||
                    backupPolicyRetentionForm[n].field_type === "list"
                    ) {
                    new_form_data[backupPolicyRetentionForm[n].field_key] = backupPolicyRetentionForm[n].default_value ? 
                    backupPolicyRetentionForm[n].default_value : ""
                } else if (backupPolicyRetentionForm[n].field_type === "bool") {
                    new_form_data[backupPolicyRetentionForm[n].field_key] = backupPolicyRetentionForm[n].default_value ? 
                    backupPolicyRetentionForm[n].default_value : 
                    false
                } else if (backupPolicyRetentionForm[n].field_type === "integer") {
                    new_form_data[backupPolicyRetentionForm[n].field_key] = backupPolicyRetentionForm[n].default_value ? 
                    backupPolicyRetentionForm[n].default_value : 
                    1
                }
            }
            setRetentionForm(new_form_data)
        }
    },[retentionForm]);

    useEffect(() => {
        if (Object.keys(customCronForm).length === 0) {
            let new_form_data = {}
            for (const n in backupPolicyCustomCronForm) {
                if (
                    backupPolicyCustomCronForm[n].field_type === "string" || 
                    backupPolicyCustomCronForm[n].field_type === "select" ||
                    backupPolicyCustomCronForm[n].field_type === "list"
                    ) {
                    new_form_data[backupPolicyCustomCronForm[n].field_key] = backupPolicyCustomCronForm[n].default_value ? 
                    backupPolicyCustomCronForm[n].default_value : ""
                } else if (backupPolicyCustomCronForm[n].field_type === "bool") {
                    new_form_data[backupPolicyCustomCronForm[n].field_key] = backupPolicyCustomCronForm[n].default_value ? 
                    backupPolicyCustomCronForm[n].default_value : 
                    false
                }
            }
            setCustomCronForm(new_form_data)
        }
    },[customCronForm]);

    useEffect(() => {
        if (Object.keys(schedulerForm).length === 0) {
            let new_form_data = {}
            for (const n in backupPolicySchedulerForm) {
                if (
                    backupPolicySchedulerForm[n].field_type === "string" || 
                    backupPolicySchedulerForm[n].field_type === "select" ||
                    backupPolicySchedulerForm[n].field_type === "list"
                    ) {
                    new_form_data[backupPolicySchedulerForm[n].field_key] = backupPolicySchedulerForm[n].default_value ? 
                    backupPolicySchedulerForm[n].default_value : ""
                } else if (backupPolicySchedulerForm[n].field_type === "bool") {
                    new_form_data[backupPolicySchedulerForm[n].field_key] = backupPolicySchedulerForm[n].default_value ? 
                    backupPolicySchedulerForm[n].default_value : 
                    false
                }
            }
            setSchedulerForm(new_form_data)
        }
    },[schedulerForm]);

    const handleWeekdaysFormDataChange = (event,field_key) => {
        setEmptyWeekDaysError(false)
        let new_list = [...weekdaysForm]
        if (event.target.checked) {
            new_list.push(field_key)
        } else {
            new_list = new_list.filter(item => item !== field_key)
        }
        setWeekdaysForm(new_list.sort())
    }

    const handleTargetMatchingPolicyFormDataChange = (event,field_key,index) => {
            let new_form_data = [...targetMatchingPolicies]
            if (backupPolicyTargetForm.filter(
                item => item.field_key === field_key)[0].field_type === "bool") {
                new_form_data[index][field_key] = event.target.checked
            } else if (backupPolicyTargetForm.filter(
                item => item.field_key === field_key)[0].field_type === "select") {
                new_form_data[index][field_key] = event
            } else {
                new_form_data[index][field_key] = event.target.value
            }
            setTargetMatchingPolicies(new_form_data)
        }

        const handleTargetMatchingPolicyRemove = () => {
            let new_data = [...targetMatchingPolicies]
            new_data.pop()
            setTargetMatchingPolicies(new_data)
        }

        const handleTargetMatchingPolicyAdd = () => {
                let new_form_data = {}
                for (const n in backupPolicyTargetForm) {
                    if (
                        backupPolicyTargetForm[n].field_type === "string" || 
                        backupPolicyTargetForm[n].field_type === "select"
                        ) {
                        new_form_data[backupPolicyTargetForm[n].field_key] = backupPolicyTargetForm[n].default_value ? 
                        backupPolicyTargetForm[n].default_value : ""
                    } else if (backupPolicyTargetForm[n].field_type === "bool") {
                        new_form_data[backupPolicyTargetForm[n].field_key] = backupPolicyTargetForm[n].default_value ? 
                        backupPolicyTargetForm[n].default_value : 
                        false
                    }
                }
                const updated_data = [...targetMatchingPolicies]
                updated_data.push(new_form_data)
                setTargetMatchingPolicies(updated_data)
            }

    const getDataForm = () => {
        let scheduler_form = [...backupPolicySchedulerForm]
        if (schedulerForm.frequency !== "daily") {
            scheduler_form = scheduler_form.filter(item => item.field_key !== "hours")
        }
        if (schedulerForm.frequency !== "monthly") {
            scheduler_form = scheduler_form.filter(item => item.field_key !== "dates")
        }
        if (schedulerForm.frequency !== "yearly") {
            scheduler_form = scheduler_form.filter(item => item.field_key !== "months" && item.field_key !== "monthday")
        }
        let retention_form = [...backupPolicyRetentionForm]
        if (retentionForm.retention_type !== "age") {
            retention_form = retention_form.filter(item => item.field_key !== "retention_time")
        }
        return (
            <FormGroup>
                {backupPolicyDataForm.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    if (field.field_type === "select") {
                        form_field_options["items"] = [...field.items]
                        form_field_options["item_titles"] = defaultTexts
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {
                                sx: {m: 1, width: "90%"},
                                ...form_field_options
                            }
                        )
                    )
                })}
                {formData.custom_cron && 
                    backupPolicyCustomCronForm.map(field => {
                        let form_field_options = {...customCronFormOptions[field.field_key]}
                        return (
                            getFormFieldComponent(
                                field,
                                customCronForm,
                                handleCustomCronFormDataChange,
                                defaultTexts[field.label],
                                {
                                    sx: {m: 1, width: "90%"},
                                    ...form_field_options
                                }
                            )
                        )
                    })
                }
                {!formData.custom_cron && 
                    scheduler_form.map(field => {
                        let form_field_options = {...schedulerFormOptions[field.field_key], ...field}
                        delete form_field_options.label
                        form_field_options["item_titles"] = defaultTexts
                        return (
                            getFormFieldComponent(
                                field,
                                schedulerForm,
                                handleSchedulerFormDataChange,
                                defaultTexts[field.label],
                                {
                                    sx: {m: 1, width: "90%"},
                                    ...form_field_options
                                }
                            )
                        )
                    })
                }
                {!formData.custom_cron && schedulerForm.frequency === "weekly" &&
                    <Grid container spacing={0} sx={{ml: 1, my: 2}}>
                        {weekDays.map((day,index) => {
                            
                            return (
                                <Grid item key={index}>
                                    <CustomCheckboxField 
                                        currentValue={weekdaysForm.includes(index)} 
                                        setCurrentValue={handleWeekdaysFormDataChange}
                                        label={day}
                                        required={false}
                                        field_key={index}
                                    />
                                </Grid>
                            )
                        })}
                        {emptyWeekDaysError && <Grid item>
                            <CustomText sx={{m: 1, color: "red"}}>{defaultTexts.emptyWeekdaysListErrorText}</CustomText>
                        </Grid>}
                    </Grid>
                }
                <CustomText sx={{m: 1, color: "text.secondary"}}>{defaultTexts.retentionFormTitle}</CustomText>
                {retention_form.map(field => {
                    let form_field_options = {...retentionFormOptions[field.field_key], ...field}
                    delete form_field_options.label
                    form_field_options["item_titles"] = defaultTexts
                    return (
                        getFormFieldComponent(
                            field,
                            retentionForm,
                            handleRetentionFormDataChange,
                            defaultTexts[field.label],
                            {
                                sx: {m: 1, width: "90%"},
                                ...form_field_options
                            }
                        )
                    )
                })}
                <CustomText sx={{m: 1, color: "text.secondary"}}>{defaultTexts.targetMatchingRulesFormFieldLabel}</CustomText>
                {targetMatchingPolicies.length > 1 && 
                    backupPolicyOperatorForm.map(field => {
                        let form_field_options = {...field}
                        delete form_field_options.label
                        form_field_options["item_titles"] = defaultTexts
                        const data = {}
                        data[field.field_key] = selectedOperator
                        return (
                            getFormFieldComponent(
                                field,
                                data,
                                setSelectedOperator,
                                defaultTexts[field.label],
                                {
                                    sx: {m: 1, width: "90%"},
                                    ...form_field_options
                                }
                            )
                        )
                    })
                }
                {targetMatchingPolicies.map((pol,index) => {
                    let form = [...backupPolicyTargetForm]
                    if (pol.field !== "metadata") {
                        form = form.filter(item => item.field_key !== "key")
                    }
                    return (
                        <Box key={index} sx={{mt: 3}}>
                        <CustomText sx={{m:1}}>{defaultTexts.targetMatchingPolicyFormFieldLabel} {index + 1}</CustomText>
                            {form.map(item => {
                                let update_item = {...item}
                                delete update_item.label
                                if (item.field_key === "key") {
                                    update_item.helpText = defaultTexts[item.helpText]
                                    update_item["sx"] = {m: 1, width: "98%"}
                                }
                                return (
                                    getFormFieldComponent(
                                        item,
                                        pol,
                                        handleTargetMatchingPolicyFormDataChange,
                                        defaultTexts[item.label],
                                        {  
                                            index: index,
                                            sx: {m: 1, width: "90%"},
                                            ...update_item,
                                            item_titles: defaultTexts,
                                        }
                                    )
                                )
                            })}
                        </Box>
                    )
                    })}
                    {targetMatchingPolicies.length > 0 && <MinusButton 
                        style={{ transform: 'scale(0.7)' }}
                        sx={{height: 5, m: 1}}
                        onClick={handleTargetMatchingPolicyRemove}
                    />}
                    {<Stack 
                        direction="row" 
                        spacing={2} 
                        alignItems="center"
                        onClick={handleTargetMatchingPolicyAdd}
                        sx={{cursor: "pointer", m: 1}}
                    >
                        <PlusButton 
                            style={{ transform: 'scale(0.7)' }}
                            sx={{height: 5}}
                            onClick={handleTargetMatchingPolicyAdd}
                        />
                        <CustomText>
                            {defaultTexts.addTargetMatchingPolicyFormFieldLabel}
                        </CustomText>
                    </Stack>}
            </FormGroup>
        )
    }

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <AddButton 
                getDataForm={getDataForm}               
                onSubmit={onAddBackupPolicy}
                formReset={handleAddBackupPolicyFormResetAll}
                customTexts={{
                    title: defaultTexts.addBackupPolicyDialogTitle,
                    description: defaultTexts.addBackupPolicyDialogDescriptionText
                }}
            />
            <FilterButton 
                choice_mode={Constants.simple_filter_type}
                currentFilter={selectedFilter}
                setCurrentFilter={setSelectedFilter}
                filter_menu_titles={defaultTexts}
                filter_menu={filterMenu}
                currentFilterValue={selectedFilterValue}
                setCurrentFilterValue={setSelectedFilterValue}
                onFilterSubmit={handleFilteredSearch}
                onFilterReset={handleFilterReset}
            />
            {successBackupPolicyAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successBackupPolicyAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successBackupPolicyAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </WrapperBox>
    )
});

export default BackupPoliciesSubheader;