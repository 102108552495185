export const backupPolicyDataSchema = [
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "enabled",
        is_id_field: false,
        field_type: "bool", 
        label: "enabledFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo",
    },
    {
        field_key: "next_run",
        is_id_field: false,
        field_type: "datetime", 
        label: "nextRunFormFieldLabel"
    },
    {
        field_key: "resource_name",
        is_id_field: false,
        field_type: "string", 
        label: "resourceFormFieldLabel"
    },
    {
        field_key: "retention_type",
        is_id_field: false,
        field_type: "string", 
        label: "retentionTypeFormFieldLabel"
    },
    {
        field_key: "retention_count",
        is_id_field: false,
        field_type: "string", 
        label: "retentionCountFormFieldLabel"
    },
    {
        field_key: "frequency",
        is_id_field: false,
        field_type: "string", 
        label: "frequencyFormFieldLabel"
    },
    {
        field_key: "custom_cron",
        is_id_field: false,
        field_type: "bool", 
        label: "customCronStringFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo",
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const backupPolicyDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "policyNameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "target_type",
        field_type: "select", 
        required: true,
        label: "targetTypeFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        default_value: "volume",
        items: [
            {
                keyword: "volumeFormFieldLabel",
                value: "volume",
                default: true
            },
            {
                keyword: "serverFormFieldLabel",
                value: "instance",
                default: false
            }
        ]
    },
    {
        field_key: "enabled",
        field_type: "bool", 
        required: false,
        label: "enabledFormFieldLabel",
        default_value: true
    },
    {
        field_key: "custom_cron",
        field_type: "bool", 
        required: false,
        label: "customCronStringFormFieldLabel",
        default_value: false
    }
]

export const backupPolicyCustomCronForm = [
    {
        field_key: "cron",
        field_type: "string",
        required: true, 
        label: "cronStringFormFieldLabel",
        error_label: "requiredFormFieldError"
    }
]

export const backupPolicySchedulerForm = [
    {
        field_key: "frequency",
        field_type: "select", 
        required: true,
        label: "frequencyFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        default_value: "daily",
        items: [
            {
                keyword: "dailyFormChoiceLabel",
                value: "daily",
                default: true
            },
            {
                keyword: "weeklyFormChoiceLabel",
                value: "weekly",
                default: false
            },
            {
                keyword: "monthlyFormChoiceLabel",
                value: "monthly",
                default: false
            },
            {
                keyword: "yearlyFormChoiceLabel",
                value: "yearly",
                default: false
            }
        ]
    },
    {
        field_key: "hours",
        field_type: "select", 
        required: false,
        label: "everyFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        items: [...Array(23).keys().map(item => {
            const data = {
                keyword: `${item+1} Hours`,
                value: `${item+1}`,
                default: false
            }
            return data
        })
        ]
    },
    {
        field_key: "dates",
        field_type: "select", 
        required: true,
        label: "onDateFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError",
        items: [...Array(31).keys().map(item => {
            const data = {
                keyword: `${item+1}`,
                value: `${item+1}`,
                default: false
            }
            return data
        }),
        {
            keyword: "Last",
            value: "32",
            default: true
        }
        ]
    },
    {
        field_key: "months",
        field_type: "select", 
        required: true,
        label: "onMonthFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError",
        items: [
            {
                keyword: "Jan",
                value: "1",
                default: true
            },
            {
                keyword: "Feb",
                value: "2",
                default: false
            },
            {
                keyword: "Mar",
                value: "3",
                default: false
            },
            {
                keyword: "Apr",
                value: "4",
                default: false
            },
            {
                keyword: "May",
                value: "5",
                default: false
            },
            {
                keyword: "Jun",
                value: "6",
                default: false
            },
            {
                keyword: "Jul",
                value: "7",
                default: false
            },
            {
                keyword: "Aug",
                value: "8",
                default: false
            },
            {
                keyword: "Sep",
                value: "9",
                default: false
            },
            {
                keyword: "Oct",
                value: "10",
                default: false
            },
            {
                keyword: "Nov",
                value: "11",
                default: false
            },
            {
                keyword: "Dec",
                value: "12",
                default: false
            }
        ]
    },
    {
        field_key: "monthday",
        field_type: "select", 
        required: true,
        label: "atTheFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        default_value: "first",
        items: [
            {
                keyword: "firstDayFormChoiceLabel",
                value: "first",
                default: true
            },
            {
                keyword: "lastDayFormChoiceLabel",
                value: "last",
                default: false
            }
        ]
    },
    {
        field_key: "starting_at",
        field_type: "select", 
        required: false,
        label: "startingAtFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        default_value: "0",
        items: [...Array(23).keys().map(item => {
                const data = {
                    keyword: item < 10 ? `0${item}:00 UTC` : `${item}:00 UTC`,
                    value: `${item}`,
                    default: false
                }
                return data
            })
        ]
    }
]

export const backupPolicyRetentionForm = [
    {
        field_key: "retention_type",
        field_type: "select", 
        required: true,
        label: "retentionTypeFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        default_value: "count",
        items: [
            {
                keyword: "countFormFieldLabel",
                value: "count",
                default: true
            },
            {
                keyword: "ageFormFieldLabel",
                value: "age",
                default: false
            }
        ]
    },
    {
        field_key: "retention_count",
        field_type: "integer", 
        required: true, 
        label: "keepFormFieldLabel",
        error_label: "requiredFormFieldError",
        min: 1
    },
    {
        field_key: "retention_time",
        field_type: "select", 
        required: true,
        label: "retentionTimeFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        default_value: "day",
        items: [
            {
                keyword: "dayFormFieldLabel",
                value: "day",
                default: true
            },
            {
                keyword: "monthFormFieldLabel",
                value: "month",
                default: false
            },
            {
                keyword: "yearFormFieldLabel",
                value: "year",
                default: false
            }
        ]
    }
]

export const backupPolicyOperatorForm = [
    {
        field_key: "field",
        field_type: "select", 
        required: true,
        label: "policyOperatorFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        default_value: "or",
        items: [
            {
                keyword: "AND",
                value: "and",
                default: true
            },
            {
                keyword: "OR",
                value: "or",
                default: false
            }
        ]
    }
]

export const backupPolicyTargetForm = [
    {
        field_key: "field",
        field_type: "select", 
        required: false,
        label: "targetFieldFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        default_value: "name",
        items: [
            {
                keyword: "ID",
                value: "id",
                default: false
            },
            {
                keyword: "Name",
                value: "name",
                default: true
            },
            {
                keyword: "Metadata",
                value: "metadata",
                default: false
            }
        ]
    },
    {
        field_key: "rule",
        field_type: "select", 
        required: false,
        label: "matchingRuleFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        default_value: "equals",
        items: [
            {
                keyword: "equalsFormFieldLabel",
                value: "equals",
                default: true
            },
            {
                keyword: "containsFormFieldLabel",
                value: "contains",
                default: false
            },
            {
                keyword: "startsWithFormFieldLabel",
                value: "startswith",
                default: false
            },
            {
                keyword: "endsWithFormFieldLabel",
                value: "endswith",
                default: false
            },
            {
                keyword: "regexFormFieldLabel",
                value: "regex",
                default: false
            }
        ]
    },
    {
        field_key: "key",
        field_type: "string", 
        required: false, 
        label: "keyFormFieldLabel",
        withHelp: true,
        helpText: "policyFieldKeyHelpText"
    },
    {
        field_key: "value",
        field_type: "string", 
        required: false, 
        label: "valueFormFieldLabel"
    }
]

export const backupPoliciesFilterMenu = [
    {
        keyword: "nameFormFieldLabel", 
        value: "name", 
        type: "text"
    },
    {
        keyword: "resourceFormFieldLabel", 
        value: "resource_name", 
        type: "select",
        multiple: false,
        items: [
            {
                keyword: "volumeFormFieldLabel", 
                value: "Volume", 
                default: true
            },
            {
                keyword: "serverFormFieldLabel", 
                value: "Server", 
                default: false
            }
        ]
    },
    {
        keyword: "retentionTypeFormFieldLabel", 
        value: "retention_type", 
        type: "select",
        multiple: false,
        items: [
            {
                keyword: "countFormFieldLabel", 
                value: "COUNT", 
                default: true
            },
            {
                keyword: "ageFormFieldLabel", 
                value: "AGE", 
                default: false
            }
        ]
    },
    {
        keyword: "frequencyFormFieldLabel", 
        value: "frequency", 
        type: "select",
        multiple: false,
        items: [
            {
                keyword: "dailyFormChoiceLabel", 
                value: "DAILY", 
                default: true
            },
            {
                keyword: "weeklyFormChoiceLabel", 
                value: "WEEKLY", 
                default: false
            },
            {
                keyword: "monthlyFormChoiceLabel", 
                value: "MONTHLY", 
                default: false
            },
            {
                keyword: "yearlyFormChoiceLabel", 
                value: "YEARLY", 
                default: false
            }
        ]
    },
    {
        keyword: "customCronStringFormFieldLabel", 
        value: "custom_cron", 
        type: "select",
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    }
]

export const backupPolicyUpdateForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: false, 
        label: "policyNameFormFieldLabel"
    },
    {
        field_key: "target_type",
        field_type: "select",
        required: false,
        label: "targetTypeFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        default_value: "volume",
        items: [
            {
                keyword: "volumeFormFieldLabel",
                value: "volume",
                default: true
            },
            {
                keyword: "serverFormFieldLabel",
                value: "instance",
                default: false
            }
        ]
    },
    {
        field_key: "enabled",
        field_type: "bool", 
        required: false,
        label: "enabledFormFieldLabel",
        default_value: true
    },
    {
        field_key: "cron",
        field_type: "string",
        required: false, 
        label: "cronStringFormFieldLabel",
        error_label: "requiredFormFieldError"
    }
]

export const backupPolicyRetentionUpdateForm = [
    {
        field_key: "type",
        field_type: "select",
        required: false,
        label: "retentionTypeFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        items: [
            {
                keyword: "countFormFieldLabel",
                value: "count",
                default: true
            },
            {
                keyword: "ageFormFieldLabel",
                value: "age",
                default: false
            }
        ]
    },
    {
        field_key: "count",
        field_type: "integer",
        required: false, 
        label: "keepFormFieldLabel",
        error_label: "requiredFormFieldError",
        min: 1
    },
    {
        field_key: "time",
        field_type: "select",
        required: false,
        label: "retentionTimeFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        default_value: "day",
        items: [
            {
                keyword: "dayFormFieldLabel",
                value: "day",
                default: true
            },
            {
                keyword: "monthFormFieldLabel",
                value: "month",
                default: false
            },
            {
                keyword: "yearFormFieldLabel",
                value: "year",
                default: false
            }
        ]
    }
]


export const backupPolicySchedulerUpdateForm = [
    {
        field_key: "frequency",
        field_type: "select", 
        required: false,
        label: "frequencyFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        items: [
            {
                keyword: "dailyFormChoiceLabel",
                value: "daily",
                default: true
            },
            {
                keyword: "weeklyFormChoiceLabel",
                value: "weekly",
                default: false
            },
            {
                keyword: "monthlyFormChoiceLabel",
                value: "monthly",
                default: false
            },
            {
                keyword: "yearlyFormChoiceLabel",
                value: "yearly",
                default: false
            }
        ]
    },
    {
        field_key: "hours",
        field_type: "select", 
        required: false,
        label: "everyFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        items: [...Array(23).keys().map(item => {
            const data = {
                keyword: `${item+1} Hours`,
                value: `${item+1}`,
                default: false
            }
            return data
        })
        ]
    },
    {
        field_key: "dates",
        field_type: "select",
        required: false,
        label: "onDateFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        default_value: "1",
        error_label: "requiredFormFieldError",
        items: [...Array(31).keys().map(item => {
            const data = {
                keyword: `${item+1}`,
                value: `${item+1}`,
                default: false
            }
            return data
        }),
        {
            keyword: "Last",
            value: "32",
            default: true
        }
        ]
    },
    {
        field_key: "months",
        field_type: "select", 
        required: false,
        label: "onMonthFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        default_value: "1",
        error_label: "requiredFormFieldError",
        items: [
            {
                keyword: "Jan",
                value: "1",
                default: true
            },
            {
                keyword: "Feb",
                value: "2",
                default: false
            },
            {
                keyword: "Mar",
                value: "3",
                default: false
            },
            {
                keyword: "Apr",
                value: "4",
                default: false
            },
            {
                keyword: "May",
                value: "5",
                default: false
            },
            {
                keyword: "Jun",
                value: "6",
                default: false
            },
            {
                keyword: "Jul",
                value: "7",
                default: false
            },
            {
                keyword: "Aug",
                value: "8",
                default: false
            },
            {
                keyword: "Sep",
                value: "9",
                default: false
            },
            {
                keyword: "Oct",
                value: "10",
                default: false
            },
            {
                keyword: "Nov",
                value: "11",
                default: false
            },
            {
                keyword: "Dec",
                value: "12",
                default: false
            }
        ]
    },
    {
        field_key: "monthday",
        field_type: "select", 
        required: false,
        label: "atTheFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        default_value: "first",
        items: [
            {
                keyword: "firstDayFormChoiceLabel",
                value: "first",
                default: true
            },
            {
                keyword: "lastDayFormChoiceLabel",
                value: "last",
                default: false
            }
        ]
    },
    {
        field_key: "starting_at",
        field_type: "select", 
        required: false,
        label: "startingAtFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        default_value: "0",
        items: [...Array(23).keys().map(item => {
                const data = {
                    keyword: item < 10 ? `0${item}:00 UTC` : `${item}:00 UTC`,
                    value: `${item}`,
                    default: false
                }
                return data
            })
        ]
    }
]


export const notificationSettingsForm = [
    {
        field_key: "emails",
        field_type: "string", 
        required: true, 
        label: "emailsFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "enabled",
        field_type: "bool", 
        required: false,
        label: "enabledFormFieldLabel",
        default_value: true
    }
]