import { createSlice } from '@reduxjs/toolkit';
import { zoneDataSchema as  zoneDataSchemaV2 } from 
"../../_data/openstack/dns/zones/v2";
import { poolDataSchema as  poolDataSchemaV2 } from 
"../../_data/openstack/dns/zones/v2";
import { ptrRecordDataSchema as ptrRecordDataSchemaV2 } from
"../../_data/openstack/dns/reverse_dns/v2";
import { floatingIPSchema as floatingIPSchemaV2 } from
"../../_data/openstack/dns/reverse_dns/v2";
import { serviceStatusSchema as serviceStatusSchemaV2 } from
"../../_data/openstack/dns/zones/v2";
import { tldDataSchema as tldDataSchemaV2 } from 
"../../_data/openstack/dns/tlds/v2";
import { tsigkeyDataSchema as tsigkeyDataSchemaV2 } from 
"../../_data/openstack/dns/tsigkeys/v2";
import { blacklistDataSchema as blacklistDataSchemaV2 } from 
"../../_data/openstack/dns/blacklists/v2";

const initialState = {
    dnsDesignateApiUrls: [
      {
        api_version: "v2",
        max_api_version: null,
        urls: [
          {id: "", keyword: 'zonesUrl', url: "zones", api_group: 'dnsApiUrls'},
          {id: "", keyword: 'poolsUrl', url: "pools", api_group: 'dnsApiUrls'},
          {id: "", keyword: 'tldsUrl', url: "tlds", api_group: 'dnsApiUrls'},
          {id: "", keyword: 'tsigkeysUrl', url: "tsigkeys", api_group: 'dnsApiUrls'},
          {id: "", keyword: 'blacklistsUrl', url: "blacklists", api_group: 'dnsApiUrls'},
          {id: "", keyword: 'quotasUrl', url: "quotas", api_group: 'dnsApiUrls'},
          {id: "", keyword: 'serviceStatusesUrl', url: "service_statuses", api_group: 'dnsApiUrls'},
          {id: "", keyword: 'reverseFloatingIPsUrl', url: "reverse/floatingips", api_group: 'dnsApiUrls'},
        ]
      }
    ],
    dnsZonesTableConfig: [
      {
        api_version: "v2",
        rows_per_page: 10,
        columns: [
          ...zoneDataSchemaV2
        ]
      }
    ],
    dnsPTRsTableConfig: [
      {
        api_version: "v2",
        rows_per_page: 10,
        columns: [
          ...ptrRecordDataSchemaV2
        ]
      }
    ],
    dnsFloatingIPsTableConfig: [
      {
        api_version: "v2",
        rows_per_page: 10,
        columns: [
          ...floatingIPSchemaV2
        ]
      }
    ],
    dnsPoolsTableConfig: [
      {
        api_version: "v2",
        rows_per_page: 10,
        columns: [
          ...poolDataSchemaV2
        ]
      }
    ],
    dnsServiceStatusTableConfig: [
      {
        api_version: "v2",
        rows_per_page: 10,
        columns: [
          ...serviceStatusSchemaV2
        ]
      }
    ],
    dnsTLDsTableConfig: [
      {
        api_version: "v2",
        rows_per_page: 10,
        columns: [
          ...tldDataSchemaV2
        ]
      }
    ],
    dnsTsigkeysTableConfig: [
      {
        api_version: "v2",
        rows_per_page: 10,
        columns: [
          ...tsigkeyDataSchemaV2
        ]
      }
    ],
    dnsBlacklistsTableConfig: [
      {
        api_version: "v2",
        rows_per_page: 10,
        columns: [
          ...blacklistDataSchemaV2
        ]
      }
    ]
}
  
const dnsDesignateSlice = createSlice({
name: 'dnsDesignate',
initialState,
reducers: {
    setDNSDesignateApiUrls: (state, action) => {
        state.dnsDesignateApiUrls = action.payload.dnsDesignateApiUrls
    },
    setDNSZonesTableConfig: (state, action) => {
        state.dnsZonesTableConfig = action.payload.dnsZonesTableConfig
    },
    setDNSPTRsTableConfig: (state, action) => {
      state.dnsPTRsTableConfig = action.payload.dnsPTRsTableConfig
    },
    setDNSFloatingIPsTableConfig: (state, action) => {
      state.dnsFloatingIPsTableConfig = action.payload.dnsFloatingIPsTableConfig
    },
    setDNSPoolsTableConfig: (state, action) => {
      state.dnsPoolsTableConfig = action.payload.dnsPoolsTableConfig
    },
    setDNSServiceStatusTableConfig: (state, action) => {
      state.dnsServiceStatusTableConfig = action.payload.dnsServiceStatusTableConfig
    },
    setDNSTLDsTableConfig: (state, action) => {
      state.dnsTLDsTableConfig = action.payload.dnsTLDsTableConfig
    },
    setDNSTsigkeysTableConfig: (state, action) => {
      state.dnsTsigkeysTableConfig = action.payload.dnsTsigkeysTableConfig
    },
    setDNSBlacklistsTableConfig: (state, action) => {
      state.dnsBlacklistsTableConfig = action.payload.dnsBlacklistsTableConfig
    },
    dnsDesignateApiUrlsReset: () => {
        return {...initialState}
    }
}
})
  
export const { 
    setDNSDesignateApiUrls,
    setDNSZonesTableConfig,
    setDNSPTRsTableConfig,
    setDNSFloatingIPsTableConfig,
    setDNSPoolsTableConfig,
    setDNSServiceStatusTableConfig,
    setDNSTLDsTableConfig,
    setDNSTsigkeysTableConfig,
    setDNSBlacklistsTableConfig,
    dnsDesignateApiUrlsReset
} = dnsDesignateSlice.actions

export default dnsDesignateSlice.reducer