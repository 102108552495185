export const dnsDesignateTexts = {
    submenuZones: "Zones",
    submenuPools: "Pools",
    zoneCreateSuccessResponseMessage: "DNS Zone successfully created!",
    zoneUpdateSuccessResponseMessage: "DNS Zone successfully updated!",
    zoneDeleteSuccessResponseMessage: "DNS Zone successfully deleted!",
    actionInProgressFormFieldLabel: "Action in Progress",
    ttlFormFieldLabel: "TTL",
    formValuePrimary: "PRIMARY",
    formValueSecondary: "SECONDARY",
    formValueActive: "Active",
    addZoneDialogTitle: "Add New DNS Zone",
    addZoneDialogDescriptionText: "Provide new DNS zone name, which must be ended with '.'",
    addDNSMasterFormFieldLabel: "Add Masters",
    mastersFormFieldLabel: "Masters",
    masterFormFieldLabel: "Master",
    attributesFormFieldLabel: "Attributes",
    formValuePending: "Pending",
    formValueError: "Error",
    zoneDeleteActionLabel: "Delete Zones",
    zoneDeleteConfirmTitle: "Zone Delete Confirm",
    zoneDeleteConfirmText: "Are you sure, you want to delete selected zones? This action can not be reverted. Selected zones",
    submenuZoneAttributes: "Attributes",
    submenuZoneNameservers: "Nameservers",
    submenuZoneRecordset: "Recordsets",
    priorityFormFieldLabel: "Priority",
    serialNumberFormFieldLabel: "Serial Number",
    versionFormFieldLabel: "Version",
    transferedAtFormFieldLabel: "Transfered at",
    zoneUpdateActionTitle: "Update zone",
    updateZoneActionTitle: "Update Zone Details",
    noNameserversNoteText: "No Nameservers",
    noRecordsetNoteText: "No Recordset",
    zoneRecordsetsTitleText: "Zone Recordsets",
    recordFormFieldLabel: "Record",
    zoneRecordDeleteConfirmTitle: "Confirm Zone Recordset Delete",
    zoneRecordDeleteConfirmText: "Are you sure you want to delete selected zone recordset",
    updateZoneRecordActionTitle: "Update Zone Recordset",
    addZoneRecordFormFieldLabel: "Add Record",
    zoneRecordsetCreateActionTitle: "Create New Recordset",
    formValueRecordsetAType: "A - Address record",
    formValueRecordsetAAAAType: "AAAA - IPv6 address record",
    formValueRecordsetCNAMEType: "CNAME - Canonical name record",
    formValueRecordsetMXType: "MX - exchange record",
    formValueRecordsetNSType: "NS - Name server",
    formValueRecordsetPTRType: "PTR - Pointer record",
    formValueRecordsetSPFType: "SPF - Sender Policy Framework",
    formValueRecordsetSRVType: "SRV - Service location",
    formValueRecordsetSSHFPType: "SSHFP - SSH Public Key Fingerprint",
    formValueRecordsetTXTType: "TXT - Text record",
    formValueRecordsetCAAType: "CAA - Certificate Authority Authorization record",
    createZoneRecordsetActionTitle: "Create New Recordset",
    addZoneRecDialogDescriptionText: "Provide new zone recordset name, which must be ended with '.'",
    zoneExportActionTitle: "Export zone",
    successZoneExportTitle: "Zone Export Success!",
    successZoneExportMessage: "Selected zone successfully exported. Click on zone in table to see exported file location.",
    noExportsNoteText: "No Exports",
    submenuZoneExports: "Exports",
    locationFormFieldLabel: "Location",
    zoneExportDataDetailsTitle: "Zone Export Data Details",
    zoneExportDeleteConfirmTitle: "Confirm Zone Export Delete",
    zoneExportDeleteConfirmText: "Are you sure you want to delete selected zone export",
    importZoneButtonText: "Import Zone",
    zoneImportTitleText: "Import Zone",
    zoneImportMessageText: "Copy and paste zone content to import.",
    zoneImportContentFormFieldLable: "Zone Import Content",
    submenuPTRRecords: "PTR Records",
    submenuFloaringIPs: "Floating IPs",
    ptrUnsetActionLabel: "Unset PTR Record",
    ptrUnsetConfirmTitle: "PTR Record Unset",
    ptrUnsetConfirmText: "Are you sure you want to unset selected PTR records",
    ptrSetActionLabel: "Set PTR Record",
    nsRecordsFormFieldLabel: "NS Records",
    lastHeartbeatFormFieldLabel: "Last Heartbeat",
    submenuServiceStatus: "Service Status",
    serviceNameFormFieldLabel: "Service Name",
    capabilitiesFormFieldLabel: "Capabilities",
    statsFormFieldLabel: "Stats",
    tldCreateSuccessResponseMessage: "Top-Level Domain Created!",
    tldUpdateSuccessResponseMessage: "Top-Level Domain Updated!",
    tldDeleteSuccessResponseMessage: "Top-Level Domain Deleted!",
    tldDeleteActionLabel: "Delete TLD",
    addTLDDialogTitle: "Add New Top Level Domain",
    tldDeleteConfirmTitle: "Confirm Top Level Domain Delete",
    tldDeleteConfirmText: "Are you sure, you want to delete selected top level domain? This action can not be reverted. Selected top level domains",
    tldUpdateActionLabel: "Update TLD",
    updateTLDActionTitle: "Update Top Level Domain Details",
    tsigkeyCreateSuccessResponseMessage: "Transaction Signature Key Created!",
    tsigkeyUpdateSuccessResponseMessage: "Transaction Signature Key Updated!",
    tsigkeyDeleteSuccessResponseMessage: "Transaction Signature Key Deleted!",
    tsigkeyDeleteActionLabel: "Delete TSIG Key",
    tsigkeyUpdateActionLabel: "Update TSIG Key",
    tsigkeyDeleteConfirmTitle: "Confirm TSIG Key Delete",
    tsigkeyDeleteConfirmText: "Are you sure, you want to delete selected TSIG Key? This action can not be reverted. Selected TSIG keys",
    updateTsigkeyActionTitle: "Update TSIG Key",
    addTsigkeyDialogTitle: "Add New TSIG Key",
    submenuTsigkeys: "TSIG Keys",
    algorithmFormFieldLabel: "Algorithm",
    resourceormFieldLabel: "Resource",
    blacklistCreateSuccessResponseMessage: "Blacklist Created!",
    blacklistUpdateSuccessResponseMessage: "Blacklist Updated!",
    blacklistDeleteSuccessResponseMessage: "Blacklist Deleted!",
    blacklistDeleteActionLabel: "Delete blacklist",
    blacklistUpdateActionLabel: "Update blacklist",
    updateBlacklistActionTitle: "Update Blacklist Details",
    blacklistDeleteConfirmTitle: "Confirm Blacklist Delete",
    blacklistDeleteConfirmText: "Are you sure, you want to delete selected blacklist? This action can not be reverted. Selected blacklists",
    submenuBlacklists: "Blacklists",
    submenuTLDs: "Top Level Domains",
    patternFormFieldLabel: "Pattern",
    addBlacklistDialogTitle: "Add New DNS Blacklist",
    addBlacklistDialogMessage: "Blacklist pattern can be any regular expression matching desired domains/subdomains. F.e. ^([A-Za-z0-9_\\-]+\\.)*example\\.com\\.$",
    apiExportSizeFormFieldLabel: "API Export Size",
    recordsetRecordsFormFieldLabel: "Recordset Records",
    zoneRecordsFormFieldLabel: "Zone Records",
    zoneRecordsetsFormFieldLabel: "Zone Recordsets",
    zonesFormFieldLabel: "Zones",
    quotaLimitsFormFieldLabel: "Quota Limits",
    updateDNSLimitsActionText: "Please, provide DNS desired quota limits below and submit."
}