import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dnsDesignateRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { zonesUrl as zonesUrlResponses } from 
'../../../../../_api_responses/openstack/dns/zones/v2';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { dnsDesignateConstants } from 
'../../../../../config/openStackConstants';
import ServiceStatusTableV2 from './serviceStatusTableV2';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import Box from '@mui/material/Box';

const SERVICE_NAME = openStackServices.dnsService

const ServiceStatusWrapperV2 = (props) => {
    const [isLoading, setIsLoading ] = useState(true);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)

    const [serviceStatuses, setServiceStatuses] = useState([]);

    const dnsServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const dnsServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const serviceStatusesUrl = useSelector(
        state => state.dnsDesignate.dnsDesignateApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === dnsDesignateConstants.serviceStatusesUrl)[0].url)

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    useEffect(() => {
        (async () => {
            handleLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${dnsServiceDomain}/${dnsServiceVersion}/${serviceStatusesUrl}`
                const method = "GET"
                const response = await dnsDesignateRequest({url:url, method:method, token: project_token})
                if (response.status_code === zonesUrlResponses.get.success_response.status_code) {
                    setServiceStatuses(response.data.service_statuses)
                } 
            }
        })();
        setTimeout(()=>{handleLoading(false)},600)
    },[
        dnsServiceDomain, 
        dnsServiceVersion, 
        serviceStatusesUrl,
        defaultAdminProject
    ]);

    return (
        <Box>
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading && <ServiceStatusTableV2 
                serviceStatuses={serviceStatuses}
                setServiceStatuses={setServiceStatuses}
            />}
        </Box>
    )
}

export default ServiceStatusWrapperV2;