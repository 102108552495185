import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { 
    floatingIPSchema
 } from '../../../../../_data/openstack/dns/reverse_dns/v2';
import { setDNSFloatingIPsTableConfig } from 
'../../../../../store/reducers/dnsDesignateSlice';


const FloatingIPsTableV2 = (props) => {
    const { floatingIPs, setFloatingIPs } = props
    const { handleRowSelection } = props
    const { currentAction } = props
    const { setCurrentAction }  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const floatingIPsTableConfig = useSelector(
        state => state.dnsDesignate.dnsFloatingIPsTableConfig)
    const rowsPerPage = useSelector(
        state => state.dnsDesignate.dnsFloatingIPsTableConfig.filter(
        versions => versions.api_version === "v2")[0].rows_per_page)
    const floatingIPsTableColumns = useSelector(
        state => state.dnsDesignate.dnsFloatingIPsTableConfig.filter(
            versions => versions.api_version === "v2")[0].columns)
    
    const identityField = floatingIPsTableColumns.filter(
        item => item.is_id_field)[0].field_key

    const dispatch = useDispatch();

    const handleFloatingIPsTableConfig = (value_list) => {
        let newTableConfig = floatingIPSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = floatingIPsTableConfig.map(version => {
            if (version.api_version === "v2") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setDNSFloatingIPsTableConfig({
            dnsFloatingIPsTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = floatingIPsTableConfig.map(version => {
            if (version.api_version === "v2") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setDNSFloatingIPsTableConfig({
            dnsFloatingIPsTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                withCheckbox={true}
                withActions={true}
                withSorting={false}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={floatingIPsTableColumns}
                tableHeadersConfigHandler={handleFloatingIPsTableConfig}
                identityField={identityField}
                columns={floatingIPSchema}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={floatingIPs}
                setDataRows={setFloatingIPs}
                handleRowSelection={handleRowSelection}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
            />
        </React.Fragment>
    )
};

export default FloatingIPsTableV2;