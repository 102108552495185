export const ptrRecordDataSchema = [
    {
        field_key: "ptrdname",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "address",
        is_id_field: false,
        field_type: "string", 
        label: "addressFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "action",
        is_id_field: false,
        field_type: "string", 
        label: "actionInProgressFormFieldLabel"
    },
    {
        field_key: "ttl",
        is_id_field: false,
        field_type: "string", 
        label: "ttlFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const floatingIPSchema = [
    {
        field_key: "project_id",
        is_id_field: false,
        field_type: "string", 
        label: "projectFormFieldLabel"
    },
    {
        field_key: "floating_ip_address",
        is_id_field: false,
        field_type: "string", 
        label: "ipAddressFormFieldLabel"
    },
    {
        field_key: "fixed_ip_address",
        is_id_field: false,
        field_type: "string", 
        label: "mappedLocalIPFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const ptrAssignDataForm = [
    {
        field_key: "ptrdname",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false, 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "ttl",
        field_type: "integer", 
        required: false,
        label: "ttlFormFieldLabel",
        default_value: 3600,
        min: 0
    }
]