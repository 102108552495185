import { backupPolicyDataSchema }  from '../../_data/backup_automation/backup_data';
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  backupPoliciesTableConfig: {
      rows_per_page: 10,
      columns: [
        ...backupPolicyDataSchema
      ]
    }
}

const backupAutomationSlice = createSlice({
  name: 'backupAutomation',
  initialState,
  reducers: {
    setBackupPoliciesTableConfig: (state, action) => {
      state.backupPoliciesTableConfig = action.payload.backupPoliciesTableConfig
    },
    backupAutomationStateReset: () => {
      return {...initialState}
    }
  }
})

export const { 
    setBackupPoliciesTableConfig,
    backupAutomationStateReset
} = backupAutomationSlice.actions

export default backupAutomationSlice.reducer