export const volumesCinderTexts = {
    submenuVolumes: "Volumes",
    submenuVolumeTypes: "Volume Types",
    submenuGroups: "Groups",
    submenuGroupSnapshots: "Group Snapshots",
    submenuGroupTypes: "Group Types",
    submenuSnapshots: "Snapshots",
    submenuBackups: "Backups",
    submenuClusters: "Clusters",
    submenuLimits: "Limits",
    qosSpecsFormFieldLabel: "Associated QoS Specs",
    addVolumeTypeDialogTitle: "Add Volume Type",
    extraSpecsFormFieldLabel: "Extra Specs",
    addQoSSpecsDialogTitle: "Add QoS Specs",
    volumeTypesTabButtonText: "Volume Types",
    qosSpecsTabButtonText: "QoS Specs",
    specsFormFieldLabel: "Specs",
    consumerFormFieldLabel: "Consumer",
    qosSpecsConsumerFormFieldHelpText: `<p>When you create a QoS specification you must choose the required consumer. The consumer determines where you want to apply the QoS limits and determines which QoS property keys are available to define the QoS limits. The Block Storage service (cinder) supports the following consumers of QoS specifications:</p>
    <lu><li><strong>front-end</strong>: The Compute service (nova) applies the QoS limits when the volume is attached to an instance. The Compute service supports all the QoS property keys provided by the Block Storage service.</li>
    <li><strong>back-end</strong>: The back-end driver of the associated volume type applies the QoS limits. Each back-end driver supports their own set of QoS property keys. For more information on which QoS property keys the driver supports, see the back-end driver documentation.</li>
    <p>You would use the back-end consumer in cases where the front-end consumer is not supported. For instance, when attaching volumes to bare metal nodes through the Bare Metal Provisioning service (ironic).</p>
    <li><strong>both</strong>: Both consumers apply the QoS limits, where possible. This consumer type therefore supports the following QoS property keys:</li>
    <p>When a volume is attached to an instance, then you can use every QoS property key that both the Compute service and the back-end driver supports.</p>
    <p>When the volume is not attached to an instance, then you can only use the QoS property keys that the back-end driver supports.</p></lu>`,
    qosSpecsPropertiesHelpText: `<p>Refer to Cinder documentation for the supported front-end and back-end keys.</p>
    <p>Front-End Example:</p>
    <lu><li>Key: total_iops_sec and Value: 5000</li>
    <li>Key: total_bytes_sec_max and Value: 512000</li>
    <li>Key: size_iops_sec and Value: 16</li></lu>
    <p>Back-End Example:<p>
    <lu><li>Key: minIOPS and Value: 20 (number value less than maxIOPS)</li>
    <li>Key: maxIOPS and Value: 5000 (number value bigger than minIOPS)</li>
    <li>Key: burstIOPS and Value: 5000 (number value bigger than minIOPS)</li></lu>`,
    encryptionFormFieldLabel: "Encryption",
    encryptionDeleteActionTitle: "Delete encryption",
    encryptionUpdateActionTitle: "Update encryption",
    encryptionCreateActionTitle: "Create encryption",
    updateExtraSpecsActionTitle: "Update extra specs",
    updateVTAccessActionTitle: "Update access",
    updateVolumeTypeFormTitle: "Update Volume Type",
    noQoSSpecsAssociationNoteText: "No QoS Specs Associated",
    noExtraSpecsNoteText: "No Extra Specs",
    controlLocationFormFieldLabel: "Control Location",
    cipherFormFieldLabel: "Cipher",
    keySizeFormFieldLabel: "Key Size",
    submenuExtraSpecs: "Extra Specs",
    submenuQoSSpecs: "QoS Specs",
    submenuEncryption: "Encryption",
    noEncryptionNoteText: "No Encryption",
    providerFormFieldLabel: "Provider",
    submenuAccess: "Access",
    noProjectAccessNoteText: "No Project Access",
    luksFormFieldChoiceText: "luks - relies on Linux Unified Key Setup",
    plainFormFieldChoiceText: "plain - relies on dm-crypt",
    encryptionCreateDialogTitle: "Create Encryption",
    encryptionCreateDialogText: "Creating encryption for a volume type causes all volumes with that volume type to be encrypted. Encryption information cannot be added to a volume type if volumes are currently in use with that volume type.",
    volumeTypeDeleteActionTitle: "Delete volume type",
    volumeTypeDeleteConfirmTitle: "Delete Volume Type",
    volumeTypeDeleteConfirmText: "Are you sure, you want to delete selected volume types? This action can not be reverted. Selected volume types",
    encryptionUpdateDialogTitle: "Update Encryption",
    qosSpecsDeleteConfirmTitle: "Delete QoS Specs",
    qosSpecsDeleteConfirmText: "Are you sure, you want to delete selected qos specs? This action can not be reverted. Selected qos specs",
    qosSpecsDeleteActionTitle: "Delete qos specs",
    qosSpecsDisassociateAllActionTitle: "Disassociate all",
    qosSpecsDisassociateAllConfirmTitle: "Disassociate All Volume Types",
    qosSpecsDisassociateAllConfirmText: "Are you sure, you want to disassociate selected qos specs from all volume types? Selected qos specs",
    noQoSSpecsNoteText: "No QoS Specs",
    noQoSSpecAssociationsNoteText: "No Associations",
    deleteVTConfirmationDialogMessage: "Are you sure, you want to delete this volume type?",
    deleteQoSSpecConfirmationDialogMessage: "Are you sure, you want to delete this qos specs?",
    submenuQoSSpecsAssociations: "Associations",
    qosSpecAssociateToVTActionTitle: "Associate to volume type",
    qosSpecDisassociateFromVTActionTitle: "Disassociate from volume type",
    associateToVolumeTypeActionTitle: "Associate to Volume Type",
    associateToVolumeTypeActionDialogMessage: "Select volume type below to associate this qos specs to",
    volumeTypeFormFieldLabel: "Volume Type",
    disassociateVolumeTypeActionTitle: "Disassociate Volume Type",
    disassociateVolumeTypeActionDialogMessage: "Select volume type below, to disassociate this qos specs from",
    qosSpecsUpdateActionTitle: "Update qos specs",
    errorUpdateQoSSpecsTitle: "QoS Spec Update Error",
    errorUpdateQoSSpecsMessage: "Failed to update qos specs data",
    defaultVolumeTypesTabButtonText: "Default Volume Types",
    projectIDFormFieldLabel: "Project ID",
    projectNameFormFieldLabel: "Project Name",
    setDefaultVolumeTypeActionTitle: 'Set default volume type',
    unsetDefaultVolumeTypeActionTitle: "Unset default volume type",
    volumeTransfersTabButtonText: "Volume Transfers",
    volumesTabButtonText: "Volumes",
    addVolumeDialogTitle: "Add New Volume",
    bootableFormFieldLabel: "Bootable",
    encryptedFormFieldLabel: "Encrypted",
    attachedToFormFieldLabel: "Attached to",
    volumeStatusChoiceAvailable: "Available",
    volumeStatusChoiceInUse: "In Use",
    volumeStatusChoiceError: "Error",
    volumeStatusChoiceCreating: "Creating",
    volumeStatusChoiceExtending: "Extending",
    volumeDeleteActionTitle: "Delete volume",
    attachToServerActionTitle: "Attach to server",
    detachFromServerActionTitle: "Detach from server",
    updateBootableStatusActionTitle: "Update bootable status",
    changeVolumeTypeActionTitle: "Change type",
    bootableStatusFormFieldLabel: "Bootable status",
    enableFormFieldLabel: "Enable",
    disableFormFieldLabel: "Disable",
    updateVolumeTypeActionTitle: "Update Volume Type",
    migrationPolicyFormFieldLabel: "Migration policy",
    onDemandFormChoiceLabel: "On Demand",
    neverFormChoiceLabel: "Never",
    forceVolumeDeleteFormFieldLabel: "Force volume delete",
    withSnapshotsFormFieldLabel: "Delete snapshots",
    volumeDeleteConfirmText: "Are you sure, you want to delete selected volumes? This action can not be reverted and all data on volume will be deleted. Selected volumes",
    volumeDeleteConfirmTitle: "Confirm volume delete",
    submenuAttachments: 'Attachments',
    noSnapshotsNoteText: "No Snapshots",
    noBackupsNoteText: "No Backups",
    volumeSourceFormFieldLabel: "Volume Source",
    clusterFormFieldLabel: "Cluster",
    multiattachFormFieldLabel: "Multiattach",
    serverAttachmentTitleText: "Server Attachment(s)",
    volumeMetadataDeleteConfirmText: "Are you sure, you want to delete this metadata?",
    volumeMetadataDeleteConfirmTitle: "Delete Volume Metadata",
    volumeSnapshotsTitleText: "Volume Snapshots",
    volumeBackupsTitleText: "Volume Backups",
    isIncrementalFormFieldLabel: "Incremental",
    volumeUpdateActionTitle: "Update volume",
    volumeExtendActionTitle: "Extend volume",
    volumeAttachToServerActionTitle: "Attach to server",
    volumeDetachFromServerActionTitle: "Detach from server",
    volumeStatusUpdateActionTitle: "Update volume status",
    createSnapshotActionTitle: "Create snapshot",
    createBackupActionTitle: "Create backup",
    createTransferActionTitle: "Create transfer",
    uploadToImageActionTitle: "Upload to image",
    updateMetadataActionTitle: "Update metadata",
    volumeMigrateActionTitle: "Migrate volume",
    updateVolumeActionTitle: "Update Volume Details",
    availableStatusFormFieldLabel: "Available",
    inuseStatusFormFieldLabel: "In-use",
    errorStatusFormFieldLabel: "Error",
    creatingStatusFormFieldLabel: "Creating",
    attachingStatusFormFieldLabel: "Attaching",
    detachingStatusFormFieldLabel: "Detaching",
    deletingStatusFormFieldLabel: "Deleting",
    errorDeletingStatusFormFieldLabel: "Error deleting",
    maintenanceStatusFormFieldLabel: "Maintenance",
    reservedStatusFormFieldLabel: "Reserved",
    updateVolumeStatusActionTitle: "Update Volume Status",
    extendVolumeSizeActionTitle: "Extend Volume Size",
    invalidVolumeSizeFormFieldError: "Volume size must be greater than current size",
    attachVolumeToServerActionTitle: "Attach Volume to Server",
    serverFormFieldLabel: "Server",
    uploadVolumeToImageActionTitle: "Upload Volume to Image",
    volumeMetadataUpdateActionTitle: "Update Volume Metadata",
    createVolumeTransferActionTitle: "Create Volume Transfer",
    transferNameFormFieldLabel: "Transfer Name",
    sourceProjectFormFieldLabel: "Source",
    destinationProjectFormFieldLabel: "Destination",
    acceptedFormFieldLabel: "Accepted",
    volumeTransferDeleteActionTitle: "Delete transfer",
    volumeTransferDeleteConfirmText: "Are you sure, you want to delete selected volume transfers?",
    volumeTransferDeleteConfirmTitle: "Delete Volume Transfer",
    volumeTransferAuthKeyDetails: "Volume transfer must be accepted with the folowing auth key. Please, copy and save this key safely, because it is shown only once. Auth Key: ",
    volumeTransferSuccessDialogTitle: "Success Transfer",
    volumeTransferSuccessDialogMessage: "Volume transfer is created and is awaiting to be accepted.",
    volumeTransferAcceptActionTitle: "Accept transfer",
    acceptVolumeTransferActionTitle: "Accept Volume Transfer",
    authKeyFormFieldLabel: "Auth Key",
    snapshotStatusChoiceDeleting: "Deleting",
    volumeFormFieldLabel: "Volume",
    groupSnapshotFormFieldLabel: "Group Snapshot",
    snapshotFormFieldLabel: "Snapshot",
    increnemtalFormFieldLabel: "Incremental",
    volumeGroupTypeFormFieldLabel: "Group Type",
    volumeGroupFormFieldLabel: "Volume Group",
    snapshotDeleteConfirmTitle: "Confirm Snapshot Delete",
    snapshotDeleteConfirmText: "Are you sure, you want to delete selected snapshots? This action can not be reverted. Selected snapshots",
    sourceFormFieldLabel: "Source",
    blankVolSourceChoiceTitle: "No source, empty volume",
    volumeSourceChoiceTitle: "Volume",
    imageVolSourceChoiceTitle: "Image",
    snapshotVolSourceChoiceTitle: "Snapshot",
    backupVolSourceChoiceTitle: "Backup",
    backupFormFieldLabel: "Backup",
    nonAllowedActionExecutionWarning: "You are trying to execute action, which requires Project Scoped Authorization to project",
    notRequiredRole: "Currently, you are not assigned required role",
    getAdminRoleToGivenProject: "Click to obtain required role assignment",
    addSnapshotDialogTitle: "Add New Snapshot",
    snapshotDeleteActionTitle: "Delete snapshot",
    snapshotStatusUpdateActionTitle: "Update status",
    snapshotStatusUpdateTitle: "Update Snapshot Status",
    snapshotStatusFormFieldLabel: "Snapshot status",
    updateSnapshotFormTitle: "Update Snapshot",
    metadataFormFieldLabel: "Add Metadata",
    noMetadataNoteText: "No Metadata",
    incrementalFormFieldLabel: "Incremental",
    addBackupDialogTitle: "Add New Backup",
    backupDeleteConfirmTitle: "Delete Backup",
    backupDeleteConfirmText: "Are you sure, you want to delete selected backups? This action can not be reverted. Selected backups",
    backupStatusUpdateTitle: "Update Backup Status",
    backupStatusFormFieldLabel: "Backup Status",
    updateBackupFormTitle: "Update Backup",
    backupDeleteActionTitle: "Delete backup",
    backupStatusUpdateActionTitle: "Update status",
    encryptionKeyFormFieldLabel: "Encryption Key",
    hasDependendBackupFormFieldLabel: "Has Dependend Backups",
    objectCountFormFieldLabel: "Object Count",
    containerFormFieldLabel: "Container",
    dataTimestampFormFieldLabel: "Data Timestamp",
    failReasonFormFieldLabel: "Fail Reason",
    backupUpdateActionTitle: "Edit Backup",
    updateBackupActionTitle: "Edit Backup",
    restoreBackupActionTitle: "Restore Backup",
    volumeTypesListFormFieldLabel: "Volume Types List",
    groupDeleteActionTitle: "Delete group",
    groupDeleteConfirmTitle: "Delete Volume Group",
    groupDeleteConfirmText: "Are you sure, you want to delete selected groups? This action can not be reverted. Selected groups",
    deleteVolumesFormFieldLabel: "Delete volumes",
    updateVolumesListActionTitle: "Update volumes list",
    createGroupSnapshotActionTitle: "Create group snapshot",
    updateVolumesListDialogTitle: "Update Volumes in Group",
    noVolumesAddedToGroupNoteText: "Empty volume list",
    groupTypesTabButtonText: "Group Types",
    addGroupTypeDialogTitle: "Add New Group Type",
    groupTypeDeleteActionTitle: "Delete group type",
    groupTypeDeleteConfirmTitle: "Delete Group Type",
    groupTypeDeleteConfirmText: "Are you sure, you want to delete selected group types? This action can not be reverted. Selected group types",
    errorDeleteGroupTypeTitle: "Group Type Delete Failed",
    errorDeleteGroupTypeMessage: "Error occurred during group type delete.",
    errorDeleteRecordTitle: "Delete Failed",
    errorDeleteRecordMessage: "An error occurred during deleting one of selected records.",
    noVolumesNoteText: "No Volumes",
    volumesLimitTitleText: "Volumes",
    availableVolumesLegendText: "Available Volumes",
    totalUsedVolumesLegendText: "Total Used Volumes",
    storageLimitTitleText: "Storage (GB)",
    availableStorageGBLegendText: "Available Storage (GB)",
    totalUsedStorageGBLegendText: "Total Used Storage (GB)",
    snapshotsLimitTitleText: "Snapshots",
    availableSnapshotsLegendText: "Available Snapshots",
    totalUsedSnapshotsLegendText: "Total Used Snapshots",
    backupsLimitTitleText: "Backups",
    availableBackupsLegendText: "Available Backups",
    totalUsedBackupsLegendText: "Total Used Backups",
    backupStorageLimitTitleText: "Backup Storage (GB)",
    availableBackupsGBLegendText: "Available Backups (GB)",
    totalUsedBackupsGBLegendText: "Total Used Backups (GB)",
    volumeGroupsLimitTitleText: "Volume Groups",
    availableVolumeGroupsLegendText: "Available Groups",
    totalUsedVolumeGroupsLegendText: "Total Used Groups",
    submenuVolumeGroups: "Volume Groups",
    submenuVolumeTransfers: "Volume Transfers",
    submenuDefaultVolumeTypes: "Default Volume Types"
}