import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dnsDesignateRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { zonesUrl as zonesUrlResponses } from 
'../../../../../_api_responses/openstack/dns/zones/v2';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { dnsDesignateConstants } from 
'../../../../../config/openStackConstants';
import PoolsTableV2 from './poolsTableV2';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import Box from '@mui/material/Box';

const SERVICE_NAME = openStackServices.dnsService

const PoolsWrapperV2 = (props) => {
    const [isLoading, setIsLoading ] = useState(true);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)

    const [pools, setPools] = useState([]);

    const dnsServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const dnsServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const poolsUrl = useSelector(
        state => state.dnsDesignate.dnsDesignateApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === dnsDesignateConstants.poolsUrl)[0].url)

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    useEffect(() => {
        (async () => {
            handleLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${dnsServiceDomain}/${dnsServiceVersion}/${poolsUrl}`
                const method = "GET"
                const response = await dnsDesignateRequest({url:url, method:method, token: project_token})
                if (response.status_code === zonesUrlResponses.get.success_response.status_code) {
                    const formatted_data = response.data.pools.map(item => {
                        let new_item = {...item}
                        new_item.ns_records = item.ns_records.map(n => n.hostname)
                        return new_item
                    })
                    setPools(formatted_data)
                } 
            }
        })();
        setTimeout(()=>{handleLoading(false)},600)
    },[
        dnsServiceDomain, 
        dnsServiceVersion, 
        poolsUrl,
        defaultAdminProject
    ]);

    return (
        <Box>
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading && <PoolsTableV2 
                pools={pools}
                setPools={setPools}
            />}
        </Box>
    )
}

export default PoolsWrapperV2;