import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ServiceContentHeader from '../../../_common/ServiceContentHeader';
import Box from '@mui/material/Box';
import PTRRecordsWrapperV2 from './reverse_dns/ptrRecordsWrapperV2';
import FloatingIPsWrapperV2 from './reverse_dns/floatingIPsWrapperV2';
import PoolsWrapperV2 from './pools/poolsWrapperV2';
import ZonesWrapperV2 from './zones/zonesWrapperV2';
import ServiceStatusWrapperV2 from './service_status/serviceStatusWrapperV2';
import TLDsWrapperV2 from './tlds/tldsWrapperV2';
import TsigkeysWrapperV2 from './tsigkeys/tsigkeysWrapperV2';
import BlacklistsWrapperV2 from './blacklists/blacklistsWrapperV2';
import DesignateLimitsWrapperV2 from './limits/limitsWrapperV2';

const DNSDesignateContentV2 = (props) => {
    const { section } = props
    const { serviceTitle } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);

    const [zonesMenu, setZonesMenu] = useState([
        {keyword: "submenuZones", navigation: "/zones", is_active: true, component: ZonesWrapperV2}
    ])

    const [reverseDNSMenu, setReverseDNSMenu] = useState([
        {keyword: "submenuPTRRecords", navigation: "/ptr-records", is_active: true, component: PTRRecordsWrapperV2},
        {keyword: "submenuFloaringIPs", navigation: "/floating-ips", is_active: false, component: FloatingIPsWrapperV2}
    ])

    const [poolsMenu, setPoolsMenu] = useState([
        {keyword: "submenuPools", navigation: "/pools", is_active: true, component: PoolsWrapperV2}
    ])

    const [serviceStatusMenu, setServiceStatusMenu] = useState([
        {keyword: "submenuServiceStatus", navigation: "/service-status", is_active: true, component: ServiceStatusWrapperV2}
    ])

    const [tldsMenu, setTLDsMenu] = useState([
        {keyword: "submenuTLDs", navigation: "/tlds", is_active: true, component: TLDsWrapperV2}
    ])

    const [tsigkeysMenu, setTsigkeysMenu] = useState([
        {keyword: "submenuTsigkeys", navigation: "/tsigkeys", is_active: true, component: TsigkeysWrapperV2}
    ])

    const [blacklistsMenu, setBlacklistsMenu] = useState([
        {keyword: "submenuBlacklists", navigation: "/blacklists", is_active: true, component: BlacklistsWrapperV2}
    ])

    const [limitsMenu, setLimitsMenu] = useState([
        {keyword: "submenuLimits", navigation: "/limits", is_active: true, component: DesignateLimitsWrapperV2}
    ])

    const getCurrentTab = useCallback(() => {
        if (section === "/zones") {
            const currentItem = zonesMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/reverse-dns") {
            const currentItem = reverseDNSMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/pools") {
            const currentItem = poolsMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/service-status") {
            const currentItem = serviceStatusMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/tlds") {
            const currentItem = tldsMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/tsigkeys") {
            const currentItem = tsigkeysMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/blacklists") {
            const currentItem = blacklistsMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/limits") {
            const currentItem = limitsMenu.filter(item => item.is_active)[0]
            return currentItem
        }
    },[
        zonesMenu,
        reverseDNSMenu,
        poolsMenu,
        blacklistsMenu,
        tldsMenu,
        tsigkeysMenu,
        limitsMenu,
        serviceStatusMenu,
        section
    ])

    const [currentTab, setCurrentTab] = useState(getCurrentTab())

    const changeMenuActiveTab = (navigation) => {
        if (section === "/zones") {
            const updated_menu = zonesMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setZonesMenu(updated_menu)
        } else if (section === "/reverse-dns") {
            const updated_menu = reverseDNSMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setReverseDNSMenu(updated_menu)
        } else if (section === "/pools") {
            const updated_menu = poolsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setPoolsMenu(updated_menu)
        } else if (section === "/service-status") {
            const updated_menu = serviceStatusMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setServiceStatusMenu(updated_menu)
        } else if (section === "/tlds") {
            const updated_menu = tldsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setTLDsMenu(updated_menu)
        } else if (section === "/tsigkeys") {
            const updated_menu = tsigkeysMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setTsigkeysMenu(updated_menu)
        } else if (section === "/blacklists") {
            const updated_menu = blacklistsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setBlacklistsMenu(updated_menu)
        } else if (section === "/limits") {
            const updated_menu = limitsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setLimitsMenu(updated_menu)
        }
    }

    const getCurrentServiceMenu = () => {
        if (section === "/zones") {
            return zonesMenu
        } else if (section === "/reverse-dns") {
            return reverseDNSMenu
        } else if (section === "/pools") {
            return poolsMenu
        } else if (section === "/service-status") {
            return serviceStatusMenu
        } else if (section === "/tlds") {
            return tldsMenu
        } else if (section === "/tsigkeys") {
            return tsigkeysMenu
        } else if (section === "/blacklists") {
            return blacklistsMenu
        } else if (section === "/limits") {
            return limitsMenu
        }
    }

    useEffect(() => {
        setCurrentTab(getCurrentTab())
    },[
        section, getCurrentTab
    ]);

    return (
        <Box>
            <ServiceContentHeader 
                service_title={serviceTitle} 
                service_menu={getCurrentServiceMenu()}
                service_menu_titles={defaultTexts}
                onClick={changeMenuActiveTab}
            />
            <currentTab.component />
        </Box>
    )
};

export default DNSDesignateContentV2;