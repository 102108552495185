import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import PlusButton from '../../../../_common/PlusButton';
import MinusButton from '../../../../_common/MinusButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WrapperBox from '../../../../_common/WrapperBox';
import { Grid, IconButton }  from '@mui/material';
import { Stack } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import { 
    dnsDesignateRequest, 
    getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { zonesUrl as zoneUrlResponses } 
from '../../../../../_api_responses/openstack/dns/zones/v2';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { 
    dnsDesignateConstants
} from '../../../../../config/openStackConstants';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { zoneRecordUpdateDataForm, zoneRecordForm } 
from '../../../../../_data/openstack/dns/zones/v2';

const SERVICE_NAME = openStackServices.dnsService

const detailTabCardConfig = {
    border: 0,
    borderBottom: 0.3,
    borderStyle: "dashed",
    borderBottomColor: "text.secondary",
    height: 100,
    minWidth: 150,
    alignItems: "start",
    pl: 3
}

const ZoneRecordsetV2 = (props) => {
    const { zoneRecordset, selectedRow } = props
    const { handleDataFetch, handleRecordsetFetch } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [selectedRecordsList, setSelectedRecordsList] = useState([])
    const [recDeleteConfirmDialogOpen, setRecDeleteConfirmDialogOpen] = useState(false)
    const [updateZoneRecDialogOpen, setUpdateZoneRecDialogOpen] = useState(false);
    const [zoneRecordUpdateData, setZoneRecordUpdateData] = useState({})

    const theme = useTheme();

    const dnsServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const dnsServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const zonesUrl = useSelector(
        state => state.dnsDesignate.dnsDesignateApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === dnsDesignateConstants.zonesUrl)[0].url)
   

    const common_url = `${dnsServiceDomain}/${dnsServiceVersion}/${zonesUrl}/${selectedRow.id}/recordsets`

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleRecConfirmDeleteDialogOpen = (rec) => {
        setSelectedRecord(rec)
        setRecDeleteConfirmDialogOpen(true)
    }

    const handleRecDeleteConfirmDialogClose = () => {
        setRecDeleteConfirmDialogOpen(false)
    }

    const handleZoneRecordAdd = () => {
        let new_form_data = {}
        new_form_data["record"] = ""
        const updated_data = [...selectedRecordsList]
        updated_data.push(new_form_data)
        setSelectedRecordsList(updated_data)
    }

    const handleZoneRecordRemove = () => {
        let new_data = [...selectedRecordsList]
        new_data.pop()
        setSelectedRecordsList(new_data)
    }

    const getDataForm = (form,form_options,data,onDataChange) => {
        return (
            <FormGroup>
                {form.map(field => {
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {...form_options[field.field_key]}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleZoneRecordUpdateDataChange = (event,field_key) => {
        let new_form_data = {...zoneRecordUpdateData}
        if (zoneRecordUpdateDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (zoneRecordUpdateDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setZoneRecordUpdateData(new_form_data)
    }

    const handleZoneRecordEdit = (event, field_key, index) => {
        let new_form_data = [...selectedRecordsList]
        new_form_data[index][field_key] = event.target.value.trim()
        setSelectedRecordsList(new_form_data)
    }

    const handleUpdateZoneRecDialogOpen = (rec) => {
        setSelectedRecord(rec)
        let new_form_data = {}
        for (const n in zoneRecordUpdateDataForm) {
            if (zoneRecordUpdateDataForm[n].field_key === "ttl" && !rec[zoneRecordUpdateDataForm[n].field_key]) {
                new_form_data[zoneRecordUpdateDataForm[n].field_key] = 0
            } else {
                new_form_data[zoneRecordUpdateDataForm[n].field_key] = rec[zoneRecordUpdateDataForm[n].field_key]
            }
        }
        setZoneRecordUpdateData(new_form_data)
        const rec_list = rec.records.map(item => {
            return {record: item}
        })
        setSelectedRecordsList(rec_list)
        setUpdateZoneRecDialogOpen(true)
    }

    const handleUpdateZoneRecDialogClose = () => {
        setSelectedRecord(null)
        setZoneRecordUpdateData({})
        setSelectedRecordsList([])
        setUpdateZoneRecDialogOpen(false)
    }

    const onZoneRecordUpdate = async () => {
        let updated_data = {...zoneRecordUpdateData}
        if (updated_data.ttl === 0) {
            delete updated_data.ttl
        }
        updated_data["records"] = selectedRecordsList.filter(item => item.record.trim().length > 0).map(item => item.record)
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            handleUpdateZoneRecDialogClose()
            const method = "PUT"
            const zone_response = await dnsDesignateRequest({
                url: `${common_url}/${selectedRecord.id}`, 
                method: method, 
                data: updated_data,
                token: project_token
            })
            if (zone_response.status_code === zoneUrlResponses.put.success_response.status_code) {
                setSelectedRecord(null)
                handleDataFetch()
                handleRecordsetFetch()
            } else {
                setError(zone_response.error)
            }
        }
    }

    const onZoneRecordsetDelete = async () => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "DELETE"
            const zone_response = await dnsDesignateRequest({
                url: `${common_url}/${selectedRecord.id}`, 
                method: method, 
                token: project_token
            })
            if (zone_response.status_code === zoneUrlResponses.delete.success_response.status_code) {
                setSelectedRecord(null)
                handleRecDeleteConfirmDialogClose()
                handleRecordsetFetch()
            } else {
                setError(zone_response.error)
            }
        }
    }

    return (
        <React.Fragment>
            <WrapperBox
                sx={detailTabCardConfig}
            >
                <Stack
                    direction="row" 
                    alignItems="center" 
                    justifyContent="center"
                    spacing={2}
                > 
                    <CustomText 
                        size="p" 
                        sx={{fontSize: {xs: 16, lg: 18}}}
                    >
                        {defaultTexts.zoneRecordsetsTitleText}
                    </CustomText>
                    <CustomText 
                        size="h5" 
                        sx={{
                            color: theme.palette.dashboardPink
                        }}
                    >
                        {zoneRecordset.length}
                    </CustomText>
                </Stack>
            </WrapperBox>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <TableContainer >
                        <Table sx={{ width: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">{defaultTexts.nameFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.typeFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.recordFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.statusFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.ttlFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.versionFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.createTimeHeaderLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.updatedAtFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.actionFormFieldLabel}</TableCell>
                                    <TableCell align="right">{defaultTexts.descriptionFormFieldLabel}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {zoneRecordset.map((row) => (
                                <TableRow
                                    key={row.id}
                                >
                                    <TableCell 
                                        align="left"
                                    >
                                        {row.type !== "SOA" && row.type !== "NS" && <IconButton
                                            onClick={() => handleUpdateZoneRecDialogOpen(row)}
                                        >
                                            <EditIcon 
                                                color="primary"
                                            />
                                        </IconButton>}
                                        {row.type !== "SOA" && row.type !== "NS" && <IconButton
                                            onClick={() => handleRecConfirmDeleteDialogOpen(row)}
                                        >
                                            <DeleteIcon 
                                                color="error"
                                            />
                                        </IconButton>}
                                    </TableCell>
                                    <TableCell 
                                        component="th"
                                        align="left"
                                        scope="row"
                                    >
                                        {row.name}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {row.type}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {row.records.map(rec => 
                                            <Box 
                                                key={rec} 
                                                sx={{
                                                    width: 200, 
                                                    wordWrap: "break-word"
                                                }}>{rec}
                                            </Box>)}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {row.status}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {row.ttl ? row.ttl : "-"}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {row.version}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {new Date(row.created_at).toLocaleString()}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {row.updated_at ? new Date(row.updated_at).toLocaleString() : "-"}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {row.action}
                                    </TableCell>
                                    <TableCell 
                                        align="right"
                                    >
                                        {row.description ? row.description : "-"}
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </Grid>
        </Grid>
        <CustomDialog
            open={updateZoneRecDialogOpen}
            onClose={handleUpdateZoneRecDialogClose}
            dialogTitle={{
                title: defaultTexts.updateZoneRecordActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onZoneRecordUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm(
                zoneRecordUpdateDataForm,
                {},
                zoneRecordUpdateData,
                handleZoneRecordUpdateDataChange
            )}
            {selectedRecordsList.map((rec,index) => {
                return (
                    <div key={index}>
                    <CustomText>{defaultTexts.recordFormFieldLabel} {index + 1}</CustomText>
                        {zoneRecordForm.map(item => {
                            return (
                                getFormFieldComponent(
                                    item,
                                    rec,
                                    handleZoneRecordEdit,
                                    defaultTexts[item.label],
                                    {index: index}
                                )
                            )
                        })}
                    </div>
                )
            })}
            {selectedRecordsList.length > 1 && <MinusButton 
                style={{ transform: 'scale(0.7)' }}
                sx={{height: 5}}
                onClick={handleZoneRecordRemove}
            />}
            {<Stack 
                direction="row" 
                spacing={2} 
                alignItems="center"
                onClick={handleZoneRecordAdd}
                sx={{cursor: "pointer"}}
            >
                <PlusButton 
                    style={{ transform: 'scale(0.7)' }}
                    sx={{height: 5}}
                    onClick={handleZoneRecordAdd}
                />
                <CustomText>
                    {defaultTexts.addZoneRecordFormFieldLabel}
                </CustomText>
            </Stack>}
        </CustomDialog>
        <CustomDialog
                open={recDeleteConfirmDialogOpen}
                onClose={handleRecDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.zoneRecordDeleteConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.zoneRecordDeleteConfirmText}: [${selectedRecord && selectedRecord.name}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: onZoneRecordsetDelete,
                    sx: {color: 'primary.main'}}]}
            />
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts.failedActionErrorDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
        </React.Fragment>
    )
};

export default ZoneRecordsetV2;