export const identityKeystoneTexts = {
    submenuDomains: "Domains",
    submenuRegions: "Regions",
    submenuProjects: "Projects",
    submenuUsers: "Users",
    submenuGroups: "Groups",
    submenuRoles: "Roles",
    submenuEndpoints: "Endpoints",
    submenuCredentials: "Credentials",
    submenuUnifiedLimits: "Unified Limits",
    submenuServices: "Services Catalog",
    submenuDetails: "Details",
    submenuCustomParams: "Custom Parameters",
    submenuAccessRules: "Access Rules",
    submenuApplicationCredentials: "App Credentials",
    deleteConfirmationDialogTitle: "Confirm Delete!",
    deleteDomainConfirmationDialogMessage: "Are you sure, you want to delete this domain?",
    confirmButtonText: "Confirm",
    domainCreateSuccessResponseMessage: "New domain was successfully created.",
    domainUpdateSuccessResponseMessage: "Domain was successfully updated.",
    domainDeleteSuccessResponseMessage: "Domain was successfully deleted.",
    addDomainDialogTitle: "Add New Domain",
    addDomainDialogDescriptionText: "A domain is a collection of users, groups, and projects. Each group and project is owned by exactly one domain.",
    updateDomainFormTitle: "Update Domain Details",
    regionCreateSuccessResponseMessage: "New region was successfully created.",
    regionUpdateSuccessResponseMessage: "Region was successfully updated.",
    regionDeleteSuccessResponseMessage: "Region was successfully deleted.",
    addRegionDialogTitle: "Add New Region",
    addRegionDialogDescriptionText: "A region is a general division of an OpenStack deployment. You can associate zero or more sub-regions with a region to create a tree- like structured hierarchy.",
    updateRegionFormTitle: "Update Region Details",
    parentRegionFormFieldLabel: "Parent Region",
    deleteRegionConfirmationDialogMessage: "Are you sure, you want to delete this region?",
    deleteProjectConfirmationDialogMessage: "Are you sure, you want to delete this project?",
    projectCreateSuccessResponseMessage: "New project was successfully created.",
    projectUpdateSuccessResponseMessage: "Project was successfully updated.",
    projectDeleteSuccessResponseMessage: "Project was successfully deleted.",
    addProjectDialogTitle: "Add New Project",
    addProjectDialogDescriptionText: "New project may act as a domain, if specified option 'Is Domain', otherwise it will be regular project. As a domain, the project provides a name space in which you can create users, groups, and other projects.",
    updateProjectFormTitle: "Update Project Details",
    noCustomParamsNoteText: "No Custom Parameters",
    noGroupsNoteText: "No Groups",
    noRolesNoteText: "No Roles",
    noProjectsNoteText: "No Projects",
    noAccessRulesNoteText: "No Access Rules",
    noApplicationCredentialsNoteText: "No Application Credentials",
    deleteUserConfirmationDialogMessage: "Are you sure, you want to delete this user?",
    userCreateSuccessResponseMessage: "New user was successfully created.",
    userUpdateSuccessResponseMessage: "User was successfully updated.",
    userDeleteSuccessResponseMessage: "User was successfully deleted.",
    addUserDialogTitle: "Add New User",
    updateUserFormTitle: "Update User Details",
    userDisableActionTitle: "Disable User",
    userEnableActionTitle: "Enable User",
    userAddParametersActionTitle: "Add Parameter(s)",
    userChangeParametersActionTitle: "Edit Parameter(s)",
    userChangePasswordActionTitle: "Change Password",
    userAddAppCredentialsActionTitle: "Add App Credential",
    userAddAppCredentialsDialogTitle: "Add New Application Credential",
    userPasswordChangeSuccessTitle: "Success!",
    userPasswordChangeSuccessText: "User's password has been changed!",
    userPasswordExpireAtActionTitle: "Password Expire",
    appCredentialAddSuccessMessage: "Application credential was successfully created. Please, copy and store credential secret key safely. It is not stored nor ever shown again. If the secret is lost, a new application credential must be created.",
    appCredentialRoleFormFieldHelpText: "Select optionally one or more roles. The list may only contain roles that the user has assigned on the project. If not provided, the roles assigned to the application credential will be the same as the roles in selected project.",
    appCredentialUnrestrectedFieldHelpText: "An optional flag to restrict whether the application credential may be used for the creation or destruction of other application credentials or trusts. Defaults to false.",
    accessRulePathFieldHelpText: "The 'path' attribute of application credential access rules uses a wildcard syntax to make it more flexible. For example, to create an application credential that is constricted to listing server IP addresses, you could use either of the following access rules: '/v2.1/servers/*/ips', '/v2.1/servers/{server_id}/ips'",
    appCredentialSecretFormFieldHelpText: "Provide secret that the application credential will be created with. If not provided, one will be generated.",
    appCredentialFormDescriptionText: "Application credentials provide a way to delegate a user’s authorization to an application without sharing the user’s password authentication. This is a useful security measure, especially for situations where the user’s identification is provided by an external source, such as LDAP or a single-sign-on service. Instead of storing user passwords in config files, a user creates an application credential for a specific project, with all or a subset of the role assignments they have on that project, and then stores the application credential identifier and secret in the config file.",
    noUsersNoteText: "No Users",
    deleteGroupConfirmationDialogMessage: "Are you sure, you want to delete this group?",
    groupCreateSuccessResponseMessage: "New group was successfully created.",
    groupUpdateSuccessResponseMessage: "Group was successfully updated.",
    groupDeleteSuccessResponseMessage: "Group was successfully deleted.",
    addGroupDialogTitle: "Add New Group",
    updateGroupFormTitle: "Update Group Details",
    groupAddUserActionTitle: "Add user to group",
    groupRemoveUserActionTitle: "Remove user from group",
    addUserToGroupDialogTitle: "Add User to Group",
    removeUserToGroupDialogTitle: "Remove User from Group",
    addRemoveUserToGroupDialogText: "Select user from list below",
    userFormFieldLabel: "User",
    noRoleAssignmentsNoteText: "No Role Assignments",
    deleteRoleConfirmationDialogMessage: "Are you sure, you want to delete this role?",
    roleCreateSuccessResponseMessage: "New role was successfully created.",
    roleUpdateSuccessResponseMessage: "Role was successfully updated.",
    roleDeleteSuccessResponseMessage: "Role was successfully deleted.",
    addRoleDialogTitle: "Add New Role",
    addRoleDialogDescriptionText: "If you don't select domain, the role will be system wide.",
    updateRoleFormTitle: "Update Role Details",
    roleAssignToUserActionTitle: "Assign to user",
    roleAssignToGroupActionTitle: "Assign to group",
    roleUnassignFromUserActionTitle: "Unassign from user",
    roleUnassignFromGroupActionTitle: "Unassign from group",
    assignRoleToGroupDialogTitle: "Assign Role to Group",
    unassignRoleFromGroupDialogTitle: "Unassign Role from Group",
    assignRoleToUserDialogTitle: "Assign Role to User",
    unassignRoleFromUserDialogTitle: "Unassign Role from User",
    assignUnassignRoleToUserDialogText: "Select scope and user from list below",
    assignUnassignRoleToGroupDialogText: "Select scope and group from list below",
    groupFormFieldLabel: "Group",
    systemScopeFormFieldLabel: "System",
    domainScopeFormFieldLabel: "Domain",
    projectScopeFormFieldLabel: "Project",
    scopeFormFieldLabel: "Scope",
    projectFormFieldLabel: "Project",
    roleAssignmentsForTitle: "Role Assignments for",
    roleAssignmentsTitle: "Role Assignments",
    showButtonText: "Show",
    roleSystemScopeTitle: "System Scope",
    roleDomainScopeTitle: "Domain Scope",
    roleProjectScopeTitle: "Project Scope",
    deleteRoleAssignmentDialogTitle: "Delete Role Assignment",
    deleteRoleAssignmentDialogMessage: "Are you sure, you want to delete this role assignment?",
    deleteButtonText: "Delete",
    serviceCreateSuccessResponseMessage: "New service was successfully created.",
    serviceUpdateSuccessResponseMessage: "Service was successfully updated.",
    serviceDeleteSuccessResponseMessage: "Service was successfully deleted.",
    endpointCreateSuccessResponseMessage: "New endpoint was successfully created.",
    endpointUpdateSuccessResponseMessage: "Endpoint was successfully updated.",
    endpointDeleteSuccessResponseMessage: "Endpoint was successfully deleted.",
    noServiceEndpointsNoteText: "No Service Endpoints",
    addServiceDialogTitle: "Add New Service",
    updateServiceFormTitle: "Update Service Details",
    deleteServiceConfirmationDialogMessage: "Are you sure, you want to delete this service?",
    deleteEndpointConfirmationDialogMessage: "Are you sure, you want to delete this endpoint?",
    serviceAddEndpointActionTitle: "Add New Endpoint",
    publicInterfaceFormFieldLabel: "Public",
    internalInterfaceFormFieldLabel: "Internal",
    adminInterfaceFormFieldLabel: "Admin",
    serviceEditEndpointTitle: "Edit Endpoint Details",
    //New Texts
    userAddBillingAppCredentialsActionTitle: "Add Credentials for Billing",
    appBillingCredentialFormDescriptionText: "Application credentials provide a way to delegate a user’s authorization to an application without sharing the user’s password authentication. This is a useful security measure, especially for situations where the user’s identification is provided by an external source, such as LDAP or a single-sign-on service. Instead of storing user passwords in config files, a user creates an application credential for a specific project, with all or a subset of the role assignments they have on that project, and then stores the application credential identifier and secret in the config file. Billing service require specific endpoints to be allowed in app credentials access rules, which are prefetched in given form below.",
    
}

