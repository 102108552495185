import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { 
    tsigkeyDataSchema,
    tsigkeysSortFields
 } from '../../../../../_data/openstack/dns/tsigkeys/v2';
import { setDNSTsigkeysTableConfig } from 
'../../../../../store/reducers/dnsDesignateSlice';


const TsigkeysTableV2 = (props) => {
    const { tsigkeysData, setTsigkeysData } = props
    const { handleRowSelection } = props
    const { currentAction } = props
    const { setCurrentAction }  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const { selfSorting, sortHandler } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const tsigkeysTableConfig = useSelector(
        state => state.dnsDesignate.dnsTsigkeysTableConfig)
    const rowsPerPage = useSelector(
        state => state.dnsDesignate.dnsTsigkeysTableConfig.filter(
        versions => versions.api_version === "v2")[0].rows_per_page)
    const tsigkeysTableColumns = useSelector(
        state => state.dnsDesignate.dnsTsigkeysTableConfig.filter(
            versions => versions.api_version === "v2")[0].columns)
    
    const identityField = tsigkeysTableColumns.filter(
        item => item.is_id_field)[0].field_key

    const dispatch = useDispatch();

    const handleTsigkeyTableConfig = (value_list) => {
        let newTableConfig = tsigkeyDataSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = tsigkeysTableConfig.map(version => {
            if (version.api_version === "v2") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setDNSTsigkeysTableConfig({
            dnsTsigkeysTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = tsigkeysTableConfig.map(version => {
            if (version.api_version === "v2") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setDNSTsigkeysTableConfig({
            dnsTsigkeysTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                withCheckbox={true}
                withActions={true}
                withSorting={true}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={tsigkeysTableColumns}
                tableHeadersConfigHandler={handleTsigkeyTableConfig}
                identityField={identityField}
                columns={tsigkeyDataSchema}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={tsigkeysData}
                setDataRows={setTsigkeysData}
                handleRowSelection={handleRowSelection}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
                sortFields={tsigkeysSortFields}
                selfSorting={selfSorting}
                sortHandler={sortHandler}
                handleCellClick={()=>{}}
            />
        </React.Fragment>
    )
};

export default TsigkeysTableV2;