import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { 
    poolDataSchema
 } from '../../../../../_data/openstack/dns/zones/v2';
import { setDNSPoolsTableConfig } from 
'../../../../../store/reducers/dnsDesignateSlice';

const PoolsTableV2 = (props) => {
    const { pools, setPools } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const poolTableConfig = useSelector(
        state => state.dnsDesignate.dnsPoolsTableConfig)
    const rowsPerPage = useSelector(
        state => state.dnsDesignate.dnsPoolsTableConfig.filter(
        versions => versions.api_version === "v2")[0].rows_per_page)
    const poolsTableColumns = useSelector(
        state => state.dnsDesignate.dnsPoolsTableConfig.filter(
            versions => versions.api_version === "v2")[0].columns)
    
    const identityField = poolsTableColumns.filter(
        item => item.is_id_field)[0].field_key

    const dispatch = useDispatch();

    const handlePoolTableConfig = (value_list) => {
        let newTableConfig = poolDataSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = poolTableConfig.map(version => {
            if (version.api_version === "v2") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setDNSPoolsTableConfig({
            dnsPoolsTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = poolTableConfig.map(version => {
            if (version.api_version === "v2") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setDNSPoolsTableConfig({
            dnsPoolsTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                withCheckbox={false}
                withActions={false}
                withSorting={false}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={poolsTableColumns}
                tableHeadersConfigHandler={handlePoolTableConfig}
                identityField={identityField}
                columns={poolDataSchema}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={pools}
                setDataRows={setPools}
                handleRowSelection={()=>{}}
            />
        </React.Fragment>
    )
};

export default PoolsTableV2;