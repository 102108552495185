import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Constants from '../../../../../config/constants';
import AddButton from '../../../../_common/AddButton';
import FilterButton from '../../../../_common/FilterButton';
import WrapperBox from '../../../../_common/WrapperBox';
import { FormGroup } from '@mui/material';
import { tsigkeyDataForm } from 
'../../../../../_data/openstack/dns/tsigkeys/v2';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { dnsDesignateConstants } from 
'../../../../../config/openStackConstants';
import { dnsDesignateRequest } from 
'../../../../../_network/openstack_request';
import { tsigkeysUrl as tsigkeysUrlResponses } from 
'../../../../../_api_responses/openstack/dns/tsigkeys/v2';
import CustomDialog from '../../../../_common/CustomDialog';
import { getXAuthTokenProjectScope } 
from '../../../../../_network/openstack_request';

const SERVICE_NAME = openStackServices.dnsService

const TsigkeysSubheaderV2 = (props) => {
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [error, setError] = useState()
    const [successTsigkeyAdd, setSuccessTsigkeyAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleFetchData } = props
    const { filterMenu, defaultTexts } = props
    const { zones, pools } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});

    const dnsServiceDomain = useSelector(state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const dnsServiceVersion = useSelector(state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const tsigkeysUrl = useSelector(
        state => state.dnsDesignate.dnsDesignateApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === dnsDesignateConstants.tsigkeysUrl)[0].url)


    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in tsigkeyDataForm) {
            if (tsigkeyDataForm[n].required && !formData[tsigkeyDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[tsigkeyDataForm[n].field_key] = {}
                updatedDataFormOptions[tsigkeyDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[tsigkeyDataForm[n].field_key]["errorText"] = defaultTexts[tsigkeyDataForm[n].error_label]
            }
        }
        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const onAddTsigkey = async () => {
        const validateFormData = handleFormDataValidation()
        if (validateFormData) {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${dnsServiceDomain}/${dnsServiceVersion}/${tsigkeysUrl}`
                const method = "POST"
                const response = await dnsDesignateRequest({
                    url:url, 
                    method:method,
                    data: formData, 
                    token: project_token
                })
                if (response.status_code === tsigkeysUrlResponses.post.success_response.status_code) {
                    handleAddTsigkeyFormReset()
                    handleFetchData()
                    setSuccessTsigkeyAdd({
                        success_title: tsigkeysUrlResponses.post.success_response.response_title, 
                        success_message: tsigkeysUrlResponses.post.success_response.response_message
                    })
                } else {
                    const error_response = tsigkeysUrlResponses.post.error_response.filter(
                        error_item => error_item.status_code === response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = tsigkeysUrlResponses.post.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: response.error
                        }
                        setError(errorObject)
                    }
                }
            }
            
        }
        return validateFormData
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessTsigkeyAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddTsigkeyFormReset = () => {
        setFormDataOptions({})
        let new_form_data = {}
        for (const n in tsigkeyDataForm) {
            if (
                tsigkeyDataForm[n].field_type === "string" || 
                tsigkeyDataForm[n].field_type === "select"
                ) {
                    new_form_data[tsigkeyDataForm[n].field_key] = tsigkeyDataForm[n].default_value ? 
                    tsigkeyDataForm[n].default_value : 
                    ""
            }
        }
        setFormData(new_form_data)
    }

    const handleFormDataChange = (event,field_key) => {

        let new_form_data = {...formData}
        if (tsigkeyDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (tsigkeyDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormDataOptions({})
        setFormData(new_form_data)
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successTsigkeyAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in tsigkeyDataForm) {
                if (
                    tsigkeyDataForm[n].field_type === "string" || 
                    tsigkeyDataForm[n].field_type === "select"
                    ) {
                        new_form_data[tsigkeyDataForm[n].field_key] = tsigkeyDataForm[n].default_value ? 
                        tsigkeyDataForm[n].default_value : 
                        ""
                }
            }
            setFormData(new_form_data)
        }
    },[formData]);

    const getDataForm = () => {
        let form = [...tsigkeyDataForm]
        let new_form_data = {...formData}
        if (new_form_data.scope === "POOL") {
            form = form.map(f => {
                if (f.field_key === "resource_id") {
                    const pools_data = pools.map(item => {
                        return {keyword: item.name, value: item.id, default: false}
                    })
                    f.items = pools_data
                }
                return f
            })
        } else if (new_form_data.scope === "ZONE") {
            form = form.map(f => {
                if (f.field_key === "resource_id") {
                    const pools_data = zones.map(item => {
                        return {keyword: item.name, value: item.id, default: false}
                    })
                    f.items = pools_data
                }
                return f
            })
        } else {
            form = form.map(f => {
                if (f.field_key === "resource_id") {
                    f.items = []
                }
                return f
            })
        }
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    let new_field = {...field}
                    delete new_field.label
                    return (
                        getFormFieldComponent(
                            field,
                            new_form_data,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {   ...form_field_options,
                                ...new_field,
                                item_titles: defaultTexts
                            }
                        )
                    )
                })}
            </FormGroup>
        )
    }

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <AddButton 
                getDataForm={getDataForm}               
                onSubmit={onAddTsigkey}
                formReset={handleAddTsigkeyFormReset}
                customTexts={{
                    title: defaultTexts.addTsigkeyDialogTitle,
                    description: ""
                }}
            />
            <FilterButton 
                choice_mode={Constants.simple_filter_type}
                currentFilter={selectedFilter}
                setCurrentFilter={setSelectedFilter}
                filter_menu_titles={defaultTexts}
                filter_menu={filterMenu}
                currentFilterValue={selectedFilterValue}
                setCurrentFilterValue={setSelectedFilterValue}
                onFilterSubmit={handleFilteredSearch}
                onFilterReset={handleFilterReset}
            />
            {successTsigkeyAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successTsigkeyAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successTsigkeyAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </WrapperBox>
    )
}

export default TsigkeysSubheaderV2;