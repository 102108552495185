import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dnsDesignateRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { blacklistsUrl as blacklistsUrlResponses } from 
'../../../../../_api_responses/openstack/dns/blacklists/v2';
import { blacklistsFilterMenu, blacklistDataForm } from 
'../../../../../_data/openstack/dns/blacklists/v2';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { dnsDesignateConstants } from 
'../../../../../config/openStackConstants';
import BlacklistsSubheaderV2 from './blacklistsSubheaderV2';
import BlacklistsTableV2 from './blacklistsTableV2';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';

const SERVICE_NAME = openStackServices.dnsService

const BlacklistsWrapperV2 = (props) => {
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState()
    const [success, setSuccess] = useState()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)

    const filterMenu = blacklistsFilterMenu
    const [blacklistsData, setBlacklistsData] = useState([]);
    const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [filterQueryParams, setFilterQueryParams] = useState("")
    const [sortQueryParams, setSortQueryParams] = useState("");
    const [currentAction, setCurrentAction] = useState("");
    const [selectedBlacklists, setSelectedBlacklists] = useState([])
    const [blacklistDeleteConfirmDialogOpen, setBlacklistDeleteConfirmDialogOpen] = useState(false);

    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [fetchDataRequired, setFetchDataRequired] = useState(true);
    const [updateBlacklistDialogOpen, setUpdateBlacklistDialogOpen] = useState(false)
    const [blacklistUpdateData, setBlacklistUpdateData] = useState({})

    const dnsServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const dnsServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const blacklistsUrl = useSelector(
        state => state.dnsDesignate.dnsDesignateApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === dnsDesignateConstants.blacklistsUrl)[0].url)


    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    const handleFilteredSearch = () => {
        if (selectedFilter && selectedFilterValue) {
            setFilterQueryParams(`${selectedFilter}=${selectedFilterValue}`)
        } else {
            setFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleBlacklistSorting = (field, direction) => {
        let new_query = `&sort_key=${field}&sort_dir=${direction}`
        setSortQueryParams(new_query)
        handleDataFetch()
    }

    const handleFilterReset = () => {
        setSelectedFilter(filterMenu[0].value)
        setSelectedFilterValue("")
        setFilterQueryParams("")
        handleDataFetch()
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccess(null);
        setSuccessDialogOpen(false);
    }

    const onBlacklistDeleteConfirm = (n_list) => {
        const selected_n_list = blacklistsData.filter(n => 
            n_list.includes(n.id))
        setSelectedBlacklists([...selected_n_list])
        setBlacklistDeleteConfirmDialogOpen(true)
    }

    const handleBlacklistDeleteConfirmDialogClose = () => {
        setBlacklistDeleteConfirmDialogOpen(false)
    }

    const handleBlacklistUpdateDataChange = (event,field_key) => {
        let new_form_data = {...blacklistUpdateData}
        if (blacklistDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (blacklistDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setBlacklistUpdateData(new_form_data)
    }

    const handleUpdateBlacklistDialogOpen = (n_list) => {
        const selected_n_list = blacklistsData.filter(n => 
            n_list.includes(n.id))
        setSelectedBlacklists([...selected_n_list])
        setUpdateBlacklistDialogOpen(true)
    }

    const handleUpdateBlacklistDialogClose = () => {
        setUpdateBlacklistDialogOpen(false)
    }

    const getDataForm = (form,form_options,data,onDataChange) => {
        return (
            <FormGroup>
                {form.map(field => {
                    let new_field = {...field}
                    delete new_field.label
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {
                                ...form_options[field.field_key],
                                ...new_field,
                                item_titles: defaultTexts
                            }
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleUpdateBlacklistAction = async (blacklist_id,token) => {
        const url = `${dnsServiceDomain}/${dnsServiceVersion}/${blacklistsUrl}/${blacklist_id}`
        const method = "PATCH"
        const response = await dnsDesignateRequest({url:url, method:method, data: blacklistUpdateData, token: token})
        if (response.status_code === blacklistsUrlResponses.patch.success_response.status_code) {
            return null
        } else {
            return response.error
        }
    }

    const onBlacklistUpdate = async (blacklists) => {
        handleUpdateBlacklistDialogClose()
        let err = []
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let n in blacklists) {
                const resp = await handleUpdateBlacklistAction(blacklists[n].id,project_token)
                if (resp !== null) {
                    err = [...err, resp]
                }
            }
        }
        setCurrentAction("")
        setBlacklistUpdateData({})
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleDeleteBlacklistAction = async (blacklist_id,token) => {
        const url = `${dnsServiceDomain}/${dnsServiceVersion}/${blacklistsUrl}/${blacklist_id}`
        const method = "DELETE"
        const response = await dnsDesignateRequest({url:url, method:method, token: token})
        if (response.status_code === blacklistsUrlResponses.delete.success_response.status_code) {
            return null
        } else {
            return response.error
        }
    }

    const onBlacklistDelete = async (blacklist_list) => {
        handleBlacklistDeleteConfirmDialogClose()
        let err = []
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let n in blacklist_list) {
                const resp = await handleDeleteBlacklistAction(blacklist_list[n].id,project_token)
                if (resp !== null) {
                    err = [...err, resp]
                }
            }
        }
        setCurrentAction("")
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const getBlacklistsActionsList = () => {
        let blacklist_actions = []
        let new_action = {}

        new_action["value"] = "blacklist_delete"
        new_action["action"] = onBlacklistDeleteConfirm
        new_action["keyword"] = "blacklistDeleteActionLabel"
        new_action["button_text"] = "applyButtonTitleText"
        blacklist_actions.push({...new_action})

        new_action = {}

        new_action["value"] = "blacklist_update"
        new_action["action"] = handleUpdateBlacklistDialogOpen
        new_action["keyword"] = "blacklistUpdateActionLabel"
        new_action["button_text"] = "applyButtonTitleText"
        blacklist_actions.push({...new_action})
        
        return blacklist_actions
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    useEffect(() => {
        setFetchDataRequired(true)
    },[filterQueryParams,sortQueryParams]);

    useEffect(() => {
        if (fetchDataRequired) {
        (async () => {
            handleLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${dnsServiceDomain}/${dnsServiceVersion}/${blacklistsUrl}/?${filterQueryParams}${sortQueryParams}`
                const method = "GET"
                const response = await dnsDesignateRequest({url:url, method:method, token: project_token})
                if (response.status_code === blacklistsUrlResponses.get.success_response.status_code) {
                    setBlacklistsData(response.data.blacklists)
                } 
            }
        })();
        }
        setFetchDataRequired(false)
        setTimeout(()=>{handleLoading(false)},1000)
    },[
        dnsServiceDomain, 
        dnsServiceVersion, 
        blacklistsUrl,
        filterQueryParams,
        fetchDataRequired,
        sortQueryParams,
        defaultAdminProject
    ]);

    return (
        <Box>
            <BlacklistsSubheaderV2 
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                selectedFilterValue={selectedFilterValue}
                setSelectedFilterValue={setSelectedFilterValue}
                filterMenu={filterMenu}
                handleFilteredSearch={handleFilteredSearch}
                handleFilterReset={handleFilterReset}
                handleFetchData={handleDataFetch}
                defaultTexts={defaultTexts}
            />
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading && <BlacklistsTableV2 
                blacklistsData={blacklistsData}
                setBlacklistsData={setBlacklistsData}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getBlacklistsActionsList()}
                selfSorting={true}
                sortHandler={handleBlacklistSorting}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {success && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[success.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[success.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            <CustomDialog
                open={blacklistDeleteConfirmDialogOpen}
                onClose={handleBlacklistDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.blacklistDeleteConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.blacklistDeleteConfirmText}: [${selectedBlacklists.map(v => v.pattern).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: () => onBlacklistDelete(selectedBlacklists), 
                    sx: {color: 'primary.main'}}]}
            />
            <CustomDialog
                open={updateBlacklistDialogOpen}
                onClose={handleUpdateBlacklistDialogClose}
                dialogTitle={{
                    title: defaultTexts.updateBlacklistActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: () => onBlacklistUpdate(selectedBlacklists),
                    sx: {color: 'primary.main'}}]}
            >
                {getDataForm(
                    blacklistDataForm,
                    {},
                    blacklistUpdateData,
                    handleBlacklistUpdateDataChange
                )}
            </CustomDialog>
        </Box>
    )
}

export default BlacklistsWrapperV2;