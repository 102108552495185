import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
//import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Logout from '@mui/icons-material/Logout';
import UIModeButton from './UIModeButton';
import LangSwitcher from './LangSwitcher';
import AdminProjectSelector from './AdminProjectSelector';
import RegionSelector from './RegionSelector';
import { profileReset } from '../../store/reducers/profileSlice';
//import { settingsReset } from '../../store/reducers/settingsSlice';
import { drawerStateReset } from '../../store/reducers/drawerSlice';
import { openStackConfigReset } from '../../store/reducers/openStackSlice';
import { identityKeystoneApiUrlsReset } from '../../store/reducers/identityKeystoneSlice';
import { computeNovaApiUrlsReset } from '../../store/reducers/computeNovaSlice';
import { networkNeutronApiUrlsReset } from '../../store/reducers/networkNeutronSlice';
import { blockStorageCinderApiUrlsReset } from '../../store/reducers/blockStorageCinderSlice';
import { imageGlanceApiUrlsReset } from '../../store/reducers/imageGlanceSlice';
import { dnsDesignateApiUrlsReset } from '../../store/reducers/dnsDesignateSlice';
import { FaHome } from "react-icons/fa";
import useWindowDimensions from './WindowDimensions';
import Dimensions from '../../config/dimensions';
import { Stack } from '@mui/material';
import Constants from '../../config/constants';
import { setCurrentLang } from '../../store/reducers/settingsSlice';
import { setLangTexts } from '../../store/reducers/textsSlice';
import { getTranslatedText } from '../../lang/translator';
import { initialState, textsReset } from '../../store/reducers/textsSlice';
//import { backupAutomationStateReset } from '../../store/reducers/backupAutomationSlice';


const NavBar = (props) => {
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const adminProjects = useSelector(state => state.profile.adminProjects)
    const supportedRegions = useSelector(state => state.openstack.supportedRegions)
    const defaultLang = useSelector(state => state.settings.defaultLang);
    const currentLang = useSelector(state => state.settings.currentLang);
    const defaultTexts = initialState.langTexts
    const logo = useSelector(state => state.settings.logo)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const logo_width = width < Dimensions.tablet_mini.width ? 40 : undefined
    const logo_height = width < Dimensions.tablet_mini.width ? undefined : 40

    const handleLangChange = (value) => {
        dispatch(textsReset())
        if (defaultLang !== value) {
            getTranslatedText(value).then((translatedItems) => {
                let new_data = {...defaultTexts, ...translatedItems}
                dispatch(setLangTexts({langTexts: new_data}))
                dispatch(setCurrentLang({currentLang: value}));
            })
        } else {
            dispatch(setCurrentLang({currentLang: value}));
        }
    };

    const handleHomeNavigation = () => {
        //handleLangChange(currentLang)
        //dispatch(textsReset())
        //dispatch(settingsReset())
        //(computeNovaApiUrlsReset())
        //(networkNeutronApiUrlsReset())
        //dispatch(blockStorageCinderApiUrlsReset())
        //dispatch(backupAutomationStateReset())
        navigate('/')
    }

    const logoutHandler = () => {
        dispatch(profileReset())
        dispatch(drawerStateReset())
        dispatch(openStackConfigReset())
        handleLangChange(currentLang)
        //dispatch(textsReset())
        dispatch(computeNovaApiUrlsReset())
        dispatch(networkNeutronApiUrlsReset())
        dispatch(blockStorageCinderApiUrlsReset())
        dispatch(identityKeystoneApiUrlsReset())
        dispatch(imageGlanceApiUrlsReset())
        dispatch(dnsDesignateApiUrlsReset())
        navigate('/')
    }

    return (
        <Container maxWidth="false">
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between'}}
            >
                {logo && width > Dimensions.phone.width && <Button 
                    onClick={handleHomeNavigation} 
                >
                    <img alt="LOGO" src={`${Constants.s3_url}/${Constants.s3_bucket}${logo}`} height={logo_height} width={logo_width}/>
                </Button>}
                {!logo && <IconButton 
                    onClick={handleHomeNavigation}
                    sx={{ color: "white", marginLeft: -5 }}
                >
                    <FaHome />
                </IconButton>}
                <Stack direction="row">
                {isAuthenticated && supportedRegions.length > 0 && <RegionSelector />}
                {isAuthenticated && adminProjects.length > 0 && <AdminProjectSelector />}
                    <LangSwitcher />
                    <UIModeButton />
                    {isAuthenticated && 
                        <IconButton 
                            onClick={logoutHandler}
                            sx={{ color: "white", marginRight: -3 }}
                        >
                            <Logout fontSize="medium" />
                        </IconButton>
                    }
                </Stack>
            </Box>
        </Container>
    );
}

export default NavBar;