import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import BackupPoliciesSubheader from 
'./backupPoliciesSubheader';
import Box from '@mui/material/Box';
import BackupPoliciesTable from './backupPoliciesTable';
import backupRequest from '../../../_network/backup_request'
import { backupPoliciesFilterMenu, notificationSettingsForm } from 
'../../../_data/backup_automation/backup_data';
import CustomDialog from '../../_common/CustomDialog';
import CustomSideDrawer from '../../_common/CustomSideDrawer';
import BackupPolicyDetail from './backupPolicyDetail';
import useWindowDimensions from '../../_common/WindowDimensions';
import Dimensions from '../../../config/dimensions';
import { backupServiceURLs } from '../../../_network/apiUrls';
import Constants from '../../../config/constants';
import BackupAutomationLoading from '../backupAutomationLoading';
import BigButton from '../../_common/BigButton';
import Grid from '@mui/material/Grid';
import { getFormFieldComponent } from 
'../../_common/_form_fields/form_helpers';
import { FormGroup } from '@mui/material';

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.8
    } else {
        return 0.8
    }
}

const BackupPoliciesWrapper = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [backupPoliciesData, setBackupPoliciesData] = useState([])
    const [backupPoliciesFormattedData, setBackupPoliciesFormattedData] = useState([])
    const filterMenu = backupPoliciesFilterMenu
    const [error, setError] = useState()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = getWidthWeight(width)
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedPolicy, setSelectedPolicy] = useState(null)

    const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const defaultTexts = useSelector(state => state.texts.langTexts);

    const [fetchDataRequired, setFetchDataRequired] = useState(true);
    const [selectedBackupPolicies, setSelectedBackupPolicies] = useState([])
    const [currentAction, setCurrentAction] = useState("");
    const [policyDeleteConfirmDialogOpen, setPolicyDeleteConfirmDialogOpen] = useState(false)
    const [updateNotificationSettingsDialogOpen, setUpdateNotificationSettingsDialogOpen] = useState(false)
    const [notificationSettingsData, setNotificationSettingsData] = useState({})
    const [notificationSettingsFormData, setNotificationSettingsFormData] = useState({})
    const [notificationSettingsDataOptions, setNotificationSettingsDataOptions] = useState({})

    const handleBackupPoliciesDataFormatting = (data) => {
        let updated_data = data.map(item => {
            let new_item = {...item}
            new_item["resource_name"] = item.resource.type === "volume" ? "Volume" : "Server"
            new_item["retention_type"] = item.retention.type.toUpperCase()
            new_item["retention_count"] = item.retention.type === "age" ? 
            `${item.retention.count} ${item.retention.time}` : item.retention.count
            if (item.type === "standard") {
                new_item["frequency"] = item.params.frequency.toUpperCase()
            }
            new_item["custom_cron"] = item.type === "custom" ? true : false
            return new_item
        })

        return updated_data
    }
    
    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleFilteredSearch = () => {
        if (selectedFilter && selectedFilterValue) {
            let new_list = handleBackupPoliciesDataFormatting(backupPoliciesData)
            new_list = new_list.filter(item => `${item[selectedFilter]}` === `${selectedFilterValue}`)
            setBackupPoliciesFormattedData(new_list)
        } else {
            let new_list = handleBackupPoliciesDataFormatting(backupPoliciesData)
            setBackupPoliciesFormattedData(new_list)
        }
    }

    const handleFilterReset = () => {
        setSelectedFilter(filterMenu[0].value)
        setSelectedFilterValue("")
        let new_list = handleBackupPoliciesDataFormatting(backupPoliciesData)
        setBackupPoliciesFormattedData(new_list)
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedRow(backupPoliciesData[index])
        setSelectedPolicy(backupPoliciesFormattedData.filter(item => 
            item.id === backupPoliciesData[index].id)[0])
        setTimeout(() => setDetailCardOpen(true),100)
    },[backupPoliciesData, backupPoliciesFormattedData]);

    const handleDetailCardClose = useCallback(() => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
    },[]);

    const handlePolicyDeleteConfirmDialogClose = () => {
        setPolicyDeleteConfirmDialogOpen(false)
    }

    const handlePolicyDeleteConfirmDialogOpen = (policy_list) => {
        let selected_list = backupPoliciesData.filter(item => policy_list.includes(item.id))
        setSelectedBackupPolicies(selected_list)
        setPolicyDeleteConfirmDialogOpen(true)
    }

    const getBackupPoliciesActionsList = () => {
        let policy_actions = []
        let new_action = {}
        new_action["value"] = "policy_delete"
        new_action["action"] = handlePolicyDeleteConfirmDialogOpen
        new_action["keyword"] = "backupPolicyDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        policy_actions.push({...new_action})
        
        return policy_actions
    }

    const handlePolicyDelete = async (policy) => {
        const url = `${Constants.backup_service_domain}/${backupServiceURLs.backupPoliciesEP}/${policy}`
        const method = "DELETE"
        const response = await backupRequest({
            url:url, 
            method:method
        })
        if (response.status_code === 204) {
            return true
        } else {
            return false
        }
    }

    const onPolicyDelete = async () => {
        handlePolicyDeleteConfirmDialogClose()
        handleDetailCardClose()
        setIsLoading(true)
        for (let i in selectedBackupPolicies) {
            await handlePolicyDelete(selectedBackupPolicies[i].id)
        }
        setIsLoading(false)
        handleDataFetch()
    }

    const handleUpdateNotificationSettingsDialogOpen = () => {
        setUpdateNotificationSettingsDialogOpen(true)
    }

    const handleUpdateNotificationSettingsDialogClose = () => {
        setUpdateNotificationSettingsDialogOpen(false)
        setNotificationSettingsFormData(notificationSettingsData)
        setNotificationSettingsDataOptions({})
    }

    const handleNotificationSettingsFormChange = (event, field_key) => {
        setNotificationSettingsDataOptions({})
        let new_form_data = {...notificationSettingsFormData}
        if (notificationSettingsForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else {
            new_form_data[field_key] = event.target.value
        }
        setNotificationSettingsFormData(new_form_data)
    }

    const getNotificationSettingsUpdateForm = () => {
        return (
            <FormGroup sx={{mt: 2}}>
                {notificationSettingsForm.map(field => {
                    let form_field_options = {...notificationSettingsDataOptions[field.field_key]}
                    let new_field = {...field}
                    delete new_field.label
                    new_field["item_titles"] = defaultTexts
                    return (
                        getFormFieldComponent(
                            field,
                            notificationSettingsFormData,
                            handleNotificationSettingsFormChange,
                            defaultTexts[field.label],
                            {
                                sx: {m: 1, width: "90%"},
                                ...form_field_options,
                                ...new_field
                            }
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const onNotificationSettingsUpdate = async () => {
        let is_valid = true
        let updated_options = {...notificationSettingsDataOptions}
        if (notificationSettingsFormData.emails.length === 0) {
            is_valid = false
            updated_options["emails"] = {}
            updated_options["emails"]["error"] = true
            updated_options["emails"]["errorText"] = defaultTexts.requiredFormFieldError
        } else {
            console.log(notificationSettingsFormData)
            let new_data = {}
            new_data["enabled"] = notificationSettingsFormData.enabled
            new_data["emails"] = typeof notificationSettingsFormData.emails === "string" ? notificationSettingsFormData.emails.split(",") : notificationSettingsFormData.emails
            for (let e in new_data["emails"]) {
                const validEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(new_data["emails"][e].trim())
                if (!validEmail) {
                    is_valid = false
                    updated_options["emails"] = {}
                    updated_options["emails"]["error"] = true
                    updated_options["emails"]["errorText"] = defaultTexts.invalidEmailFormFieldError
                }
            }
            if (is_valid) {
                handleUpdateNotificationSettingsDialogClose()
                if (Object.keys(notificationSettingsData).includes("id")) {
                    const url = `${Constants.backup_service_domain}/${backupServiceURLs.notificationSettingsEP}/${notificationSettingsData.id}`
                    const method = "PUT"
                    const response = await backupRequest({url:url, method:method, data: new_data})
                    if (response.status_code === 200) {
                        setNotificationSettingsData(response.data)
                        setNotificationSettingsFormData(response.data)
                    }
                } else {
                    const url = `${Constants.backup_service_domain}/${backupServiceURLs.notificationSettingsEP}`
                    const method = "POST"
                    const response = await backupRequest({url:url, method:method, data: new_data})
                    if (response.status_code === 201) {
                        setNotificationSettingsData(response.data)
                        setNotificationSettingsFormData(response.data)
                    }
                }
            }
        }
        setNotificationSettingsDataOptions(updated_options)
    }

    useEffect(() => {
        if (fetchDataRequired) {
        (async () => {
            handleLoading(true)
            const url = `${Constants.backup_service_domain}/${backupServiceURLs.backupPoliciesEP}`
            const method = "GET"
            
            const policies_response = await backupRequest({url:url, method:method})
            if (policies_response.status_code === 200) {
                if (selectedRow) {
                    setSelectedPolicy(policies_response.data.filter(image => image.id === selectedRow.id)[0])
                }
                setBackupPoliciesData(policies_response.data)
                const data_to_update = handleBackupPoliciesDataFormatting(policies_response.data)
            
        
                setBackupPoliciesFormattedData(data_to_update)
            }
            })();
        setFetchDataRequired(false)
        }
        setTimeout(()=>{handleLoading(false)},800)
    },[
        fetchDataRequired,
        selectedRow
    ]);

    useEffect(() => {
        (async () => {
            const url = `${Constants.backup_service_domain}/${backupServiceURLs.notificationSettingsEP}`
            const method = "GET"
            
            const response = await backupRequest({url:url, method:method})
            if (response.status_code === 200) {
                setNotificationSettingsData(response.data)
                setNotificationSettingsFormData(response.data)
            }
            })();
    },[]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {setSelectedPolicy(selectedRow)},[selectedRow])

    return (
        <Box>
            <BackupPoliciesSubheader 
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                selectedFilterValue={selectedFilterValue}
                setSelectedFilterValue={setSelectedFilterValue}
                filterMenu={filterMenu}
                handleFilteredSearch={handleFilteredSearch}
                handleFilterReset={handleFilterReset}
                handleDataFetch={handleDataFetch}
            />
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <BigButton 
                        btnTitleText={defaultTexts.backupAutomationNotificationSettingsTitle}
                        onClickHandler={handleUpdateNotificationSettingsDialogOpen}
                        size="small"
                    />
                </Grid>
            </Grid>
            {isLoading && <BackupAutomationLoading />}
            {!isLoading && <BackupPoliciesTable 
                policiesData={backupPoliciesFormattedData}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getBackupPoliciesActionsList()}
            />}
            {selectedRow !== null && <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <BackupPolicyDetail 
                    selectedRow={selectedRow}
                    selectedPolicy={selectedPolicy}
                    widthWeight={WIDTH_WEIGHT}
                    handleDataFetch={handleDataFetch}
                    handleDrawerClose={handleDetailCardClose}
                    policiesData={backupPoliciesData}
                    handleDelete={handlePolicyDeleteConfirmDialogOpen}
                />     
            </CustomSideDrawer>}
            <CustomDialog
                open={updateNotificationSettingsDialogOpen}
                onClose={handleUpdateNotificationSettingsDialogClose}
                dialogTitle={{
                    title: defaultTexts.updateNotificationSettingsActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.updateNotificationSettingsActionMessage,
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onNotificationSettingsUpdate, 
                    sx: {color: 'primary.main'}}]}
            >
                {getNotificationSettingsUpdateForm()}
            </CustomDialog>
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            <CustomDialog
                open={policyDeleteConfirmDialogOpen}
                onClose={handlePolicyDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.backupPolicyDeleteConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.backupPolicyDeleteConfirmText}: [${selectedBackupPolicies.map(s => s.name).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: onPolicyDelete, 
                    sx: {color: 'primary.main'}}]}
            />
        </Box>
    )
};

export default BackupPoliciesWrapper;