import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import CustomCard from '../_common/CustomCard';
import CustomText from "../_common/CustomText";
import BigButton from "../_common/BigButton";
import Stack from "@mui/material/Stack";

const BackupAutomationStart = (props) => {
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const { enableBackupAutomationService } = props
    
    const navigate = useNavigate();
    const theme = useTheme();

    if (!isAuthenticated) {
        navigate('/');
    }

    return (
        <CustomCard
            cardWidth={'100%'} 
            cardHeight={'70vh'}
            card_sx={{
                mt: 3,
                border: theme.palette.mode === "dark" ? 0 : 1, 
                boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                backgroundColor: 
                    theme.palette.mode === "dark" ? 
                    undefined :
                    "vLightGray"
            }}
            box_sx={{pr: 2}}
        >
            <Stack spacing={5} sx={{mx: {xs: 2, md: 10}, mt: 5}}>
                <BigButton 
                    btnTitleText={defaultTexts.backupServiceEnableButtonText}
                    onClickHandler={enableBackupAutomationService}
                />
                <CustomText sx={{fontSize: {xs: 16, md: 18}}}>
                    {defaultTexts.backupServiceEnableDescriptionText}
                </CustomText>
            </Stack>
        </CustomCard>
    )
};

export default BackupAutomationStart;